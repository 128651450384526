import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@mui/material";
import { FinePrint, RacwaDetailedDialog } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { useState } from "react";
import { WhatsIsIncludedContent } from "../../../../shared/components/WhatIsIncluded";

const buttonId = `whatsIncludedTooltip-Button`;
const title = `Cover summary`;
const dialogTitleId = `${title}-cover-dialogue`;

export const WhatsIncludedDialog = () => {
  const [open, setOpen] = useState(false);

  const DialogSubheading = () => (
    <Typography
      variant="body1"
      component="h2"
      align="center"
      sx={{
        root: { marginBottom: "18px" },
      }}
      gutterBottom
    >
      Relax with local caravan insurance you can rely on
    </Typography>
  );
  const DialogContent = () => (
    <FinePrint>
      <WhatsIsIncludedContent displayAsTooltip />
    </FinePrint>
  );

  const closeAndResetDialogToDefault = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        data-testid={buttonId}
        id={buttonId}
        size="medium"
        arial-label="anchor"
        onClick={() => setOpen(!open)}
        sx={{ root: { padding: "0", position: "relative" } }}
      >
        <FontAwesomeIcon style={{ color: colors.dieselDeeper, fontSize: "20px" }} icon={faQuestionCircle} aria-hidden />
      </IconButton>
      <RacwaDetailedDialog
        open={open}
        onClose={() => {
          closeAndResetDialogToDefault();
        }}
        onClickClose={() => {
          closeAndResetDialogToDefault();
        }}
        title={title}
        titleId={dialogTitleId}
        customChildren={[
          {
            dialogueContent: false,
            node: <DialogSubheading />,
          },
          {
            dialogueContent: true,
            node: <DialogContent />,
          },
        ]}
      />
    </>
  );
};

export default WhatsIncludedDialog;
