import { FormControl, FormHelperText, FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../constants/HelperText";

const button = "button";
const annualLabel = "Annual";
const monthlyLabel = "Monthly";
const annualButtonId = "input-toggle-payment-frequency-annual";
const monthlyButtonId = "input-toggle-payment-frequency-monthly";
const label = "Payment frequency";
const inputId = "input-payment-frequency";
const inputName = "frequency";

export interface PaymentFrequencyToggleButtonProps {
  trackingName: string;
  displayLabel?: boolean | null;
  disabled?: boolean;
}

export const PaymentFrequencyToggleButton: React.FC<PaymentFrequencyToggleButtonProps> = ({
  displayLabel = false,
  disabled,
  trackingName,
}) => {
  const { control } = useFormContext();
  const renderLabel = displayLabel ? <FormLabel id="paymentFrequencyLabel">Payment frequency</FormLabel> : undefined;

  return (
    <Controller
      control={control}
      name={inputName}
      rules={{
        required: { value: true, message: RequiredSelection },
      }}
      render={({ field: { onBlur, onChange, ...props }, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} fullWidth={true}>
          {renderLabel}
          <ToggleButtonGroup
            {...props}
            onChange={(_, value) => {
              if (value === annualLabel || value === monthlyLabel) {
                gtm(event(value));
                window.formotiv &&
                  window.formotiv.inputActivity(window.formotivConfig, trackingName, "buttongroup", value);
                onChange(value);
              }
            }}
            onBlur={() => {
              gtm(fieldTouched(label));
              onBlur();
            }}
            exclusive
            id={inputId}
            data-testid={inputId}
            aria-label={label}
            sx={{width: "100%"}}
          >
            <ToggleButton
              type={button}
              role={button}
              value={annualLabel}
              id={annualButtonId}
              data-testid={annualButtonId}
              sx={{minWidth: "92px"}}
              disabled={disabled}
            >
              {annualLabel}
            </ToggleButton>
            <ToggleButton
              type={button}
              role={button}
              value={monthlyLabel}
              id={monthlyButtonId}
              data-testid={monthlyButtonId}
              sx={{minWidth: "92px"}}
              disabled={disabled}
            >
              {monthlyLabel}
            </ToggleButton>
          </ToggleButtonGroup>
          {error?.message ? <FormHelperText>{error.message}</FormHelperText> : null}
        </FormControl>
      )}
    />
  );
};

export default PaymentFrequencyToggleButton;
