import { Button, ButtonProps } from "@mui/material";
import { CoverType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
export interface GetPolicyButtonProps extends ButtonProps {
  coverType: CoverType;
}

export const GetPolicyButton: React.FC<GetPolicyButtonProps> = ({ coverType, ...props }) => {
  const coverTypeId = coverType.toLowerCase();
  const getQuoteButtonId = `button-${coverTypeId}-get-quote`;
  return (
    <Button
      type="submit"
      id={getQuoteButtonId}
      data-testid={getQuoteButtonId}
      color="primary"
      variant="contained"
      fullWidth
      {...props}
    >
      Get policy
    </Button>
  );
};
export default GetPolicyButton;
