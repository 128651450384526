import YourQuoteForm from "./Form";
import useYourQuote from "./hooks/useYourQuote";

export const YourQuote: React.FC<any> = () => {
  const props = useYourQuote();

  return <YourQuoteForm {...props} />;
};

export default YourQuote;
