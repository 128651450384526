import { Button } from "@mui/material";
import { BodyCopy, RacwaDetailedDialog } from "@racwa/react-components";
import { useStyles } from "./styles";

const FormotivMessageContent = () => {
  return (
    <BodyCopy gutterBottom>
      <strong>We&apos;ll cover your caravan or camper trailer throughout Australia.</strong>
    </BodyCopy>
  );
};

export interface FormotivDialogProps {
  open: boolean;
  onCloseFormotivDialog?: () => void;
}

export const FormotivDialog: React.FC<FormotivDialogProps> = ({ open, onCloseFormotivDialog }) => {
  const classes = useStyles;
  return open ? (
    <>
      <RacwaDetailedDialog
        open={open}
        onClickClose={onCloseFormotivDialog}
        title="Did you know?"
        titleId="formotiv-popup-dialog"
        maxWidth="xs"
        customChildren={[
          {
            dialogueContent: false,
            node: <FormotivMessageContent />,
          },
          {
            dialogueContent: false,
            node: (
              <Button
                id="btn-close"
                data-testid="btn-close"
                name="btn-close"
                sx={classes.button}
                onClick={onCloseFormotivDialog}
                color="primary"
              >
                Close
              </Button>
            ),
          },
        ]}
      />
    </>
  ) : null;
};

export default FormotivDialog;
