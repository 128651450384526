import { Grid, Typography } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { QUOTE_ABOUT_YOUR_CARAVAN_PAGE_URL, QuoteRoutes } from "../../../../../../shared/routing/routes.config";
import { Values as AboutYourCaravanValues } from "../../../../../AboutYourCaravan/types";
import SummaryItem from "../SummaryItem";

const componentTestId = "policySummary-caravanUsage";

const getOvernightParking = (overnightParking: string) => {
  const parking = overnightParking.toLowerCase();

  if (parking === "") {
    return null;
  } else if (parking === "on-site") {
    return `Parked ${parking}`;
  } else if (parking === "street or verge") {
    return `Parked on ${parking}`;
  } else {
    return `Parked in ${parking}`;
  }
};

const CaravanUsageContent: React.FC = () => {
  const aboutYourCaravanState = useGetSessionState<AboutYourCaravanValues>(QuoteRoutes.AboutYourCaravan);
  const overnightParking = getOvernightParking(aboutYourCaravanState.overnightParking ?? "");

  return (
    <>
      {aboutYourCaravanState.registrationNumber && (
        <Typography variant="body2" data-testid={`${componentTestId}-registration`}>
          Registration: {aboutYourCaravanState.registrationNumber}
        </Typography>
      )}
      <Typography variant="body2" data-testid={`${componentTestId}-noBusinessUse`}>
        No business or commercial use
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-keptIn`}>
        Kept in {aboutYourCaravanState.suburb?.name} {aboutYourCaravanState.suburb?.postcode}
      </Typography>
      {overnightParking && (
        <Typography variant="body2" data-testid={`${componentTestId}-overnightParking`}>
          {overnightParking}
        </Typography>
      )}
    </>
  );
};

export const CaravanUsageSummaryItem: React.FC = () => {
  const navigate = useNavigate();
  const redirectToAboutYourCaravanPage = () => {
    navigate(QUOTE_ABOUT_YOUR_CARAVAN_PAGE_URL);
  };

  return (
    <Grid container alignItems="center" data-testid="caravanUsageItem">
      <SummaryItem title="Caravan usage" name="AboutYourCaravan" onClick={redirectToAboutYourCaravanPage}>
        <CaravanUsageContent />
      </SummaryItem>
    </Grid>
  );
};

export default CaravanUsageSummaryItem;
