import { FormControl, FormHelperText, FormLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { pleaseEnterAValidMessage } from "../../../../shared/constants/HelperText";
import { PatternFormat } from "react-number-format";

const id = "accountNumber";
const label = "Account number";
const InputMinLength = 3;
const InputMaxLength = 9;
const MinValue = 1;
const InputFormat = "### ### ###";

const NumericOutlinedInput: React.FC<OutlinedInputProps> = (props) => (
  <OutlinedInput {...props} inputProps={{ type: "text", inputMode: "numeric" }} />
);

export const AccountNumber: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      aria-label={id}
      name={id}
      rules={{
        required: {
          value: true,
          message: pleaseEnterAValidMessage("account number"),
        },
        validate: (value: string) => {
          if (value.length >= InputMinLength && value.length <= InputMaxLength && Number(value) >= MinValue) {
            return true;
          }
          return pleaseEnterAValidMessage("account number");
        },
      }}
      render={({ field: { ref, onChange: onComponentChange, ...inputProps }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth>
          <FormLabel>{label}</FormLabel>

          <PatternFormat
            {...inputProps}
            data-testid={id}
            format={InputFormat}
            color="primary"
            placeholder="eg. 123 456 789"
            customInput={NumericOutlinedInput}
            onValueChange={async (newValue) => {
              onComponentChange(newValue?.value);
            }}
          />
          {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default AccountNumber;
