import { Typography } from "@mui/material";
import ExcessTooltip from "../Tooltips/ExcessTooltip";
import useStyles from "./styles";

export const ExcessDisclaimer = () => {
  const styles = useStyles;

  return (
    <div style={styles.excessDisclaimerContainer} id="excess-disclaimer" data-testid="excess-disclaimer">
      <Typography variant="body2">
        <br />
        Please note additional excesses apply to individuals under 26
      </Typography>
      <ExcessTooltip />
    </div>
  );
};

export default ExcessDisclaimer;
