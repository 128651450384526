import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete, TooltipProps } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import React, { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { pleaseEnterAValidMessage } from "../../constants/HelperText";
import { AddressSummary } from "../../hooks/useApiClient/ClientProxy.generated";
import { tagIfJointPolicyHolder } from "../../utils/routeHelper";
import { colors } from "@racwa/styles";

export const inputId = "mailingAddress-input";
export const inputContainerId = `${inputId}-container`;
const inputFieldName = "mailingAddress";
const tooltipId = `${inputId}-tooltip`;
const tooltipButtonId = `${tooltipId}-button`;

export interface MailingAddressTemplateProps {
  data: AddressSummary[];
  label?: string | null;
  isError?: boolean | false;
  isLoading?: boolean | false;
  onSelection: (address: AddressSummary | null) => void;
  onInputChange: (value: string) => void;
  tooltipProps?: TooltipProps;
  required?: boolean;
}

const maxInputLength: number = 150;

/** TODO - SPK-4533 - Replace MailingAddress component with shared RRL AddressInput component */
const MailingAddressTemplate: React.FC<MailingAddressTemplateProps> = ({
  data = [],
  label,
  isLoading,
  onSelection,
  onInputChange,
  tooltipProps,
  required = true,
}) => {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);

  const tooltipMessage = (
    <Typography>
      Please enter the address where you prefer to receive mail. If it doesn't appear, try entering fewer details (e.g.
      remove the unit number). If you can't find your address please call us on{" "}
      <InsurancePhoneNumberLink id="call us link" /> with your quote number.
    </Typography>
  );

  const RacIconButton = useMemo(
    () => (
      <IconButton
        data-testid={tooltipButtonId}
        size="small"
        aria-label="show tooltip"
        arial-label="anchor"
        sx={{ padding: "0 0 0 3px", width: "20px" }}
      >
        <FontAwesomeIcon
          role="presentation"
          style={{ color: colors.dieselDeeper, fontSize: "20px" }}
          icon={faQuestionCircle}
          aria-hidden
        />
      </IconButton>
    ),
    [],
  );

  const defaultTooltip: TooltipProps = {
    id: tooltipId,
    open: open,
    title: "Mailing address",
    message: tooltipMessage,
    onClickClose: () => setOpen(false),
    onClick: () => setOpen(!open),
    children: RacIconButton,
  };

  const toolTip = tooltipProps ?? defaultTooltip;

  return (
    <Controller
      control={control}
      name={inputFieldName}
      rules={{
        required: {
          value: required,
          message: pleaseEnterAValidMessage("address"),
        },
        validate: {
          isValid: (newValue) => {
            if (!required || newValue?.moniker) {
              return true;
            }
            return pleaseEnterAValidMessage("address");
          },
        },
      }}
      render={({ field: { value, ref, ...props }, fieldState: { error } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label ?? "Mailing address"}
          placeholder="e.g. 832 Wellington St, West Perth, WA, 6..."
          options={data}
          getOptionLabel={(option) => (option.displayAddress ? option.displayAddress : "")}
          isOptionEqualToValue={(option, value) =>
            option.displayAddress === value.displayAddress || option.displayAddress === ""
          }
          freeSolo={!data.length}
          fullWidth
          tooltipProps={toolTip}
          filterOptions={(o) => o}
          loading={isLoading}
          autoComplete
          autoHighlight
          clearOnEscape
          value={value || null}
          textFieldProps={{
            name: inputId,
            "data-testid": inputId,
            inputProps: {
              maxLength: maxInputLength,
              autoComplete: "street-address",
            },
          }}
          error={!!error}
          helperText={error?.message}
          onChange={(_, value) => {
            const mailingAddressSuffix =
              tagIfJointPolicyHolder(inputFieldName) === inputFieldName ? "ph" : "JointPolicyholder";

            window.formotiv &&
              window.formotiv.inputActivity(
                window.formotivConfig,
                `${inputFieldName}_${mailingAddressSuffix}`,
                "autocomplete",
                value,
              );
            onSelection && onSelection(value as AddressSummary);
            props.onChange(value);
          }}
          onInputChange={(_, value) => {
            onInputChange && onInputChange(value);
          }}
          onBlur={() => {
            props.onBlur && props.onBlur();
            gtm(fieldTouched("Mailing address"));
          }}
          data-hj-suppress
          inputRef={ref}
        />
      )}
    />
  );
};

export default MailingAddressTemplate;
