import { useGetSessionState } from "raci-react-library";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { PremiumState, premiumState } from "../../../atoms";
import { Values as YourQuoteValues } from "../../../views/YourQuote/types";
import { QuoteRoutes } from "../../routing/routes.config";

export const useHandlePremiumChanges = (): ((premiumDetails: Omit<PremiumState, "open">) => void) => {
  const navigate = useNavigate();
  const yourQuoteState = useGetSessionState<YourQuoteValues>(QuoteRoutes.YourQuote);
  const setPremiumChangeState = useSetRecoilState(premiumState);
  const handlePremiumChanges = useCallback(
    (premiumDetails: Omit<PremiumState, "open">) => {
      const hasChanged =
        yourQuoteState.quote.covers[0].premiumDetails.annual.total - premiumDetails.premiumDetails.annual.total;
      if (hasChanged) {
        setPremiumChangeState({ ...premiumDetails, open: true });
      } else if (premiumDetails.nextPage) {
        navigate(premiumDetails.nextPage);
      }
    },
    [yourQuoteState.quote.covers, setPremiumChangeState, navigate],
  );

  return handlePremiumChanges;
};
