import { theme } from "@racwa/react-components";

export const useStyles = {
  toggleButtonGroup: {
    width: "100%",
  },
  button: {
    minWidth: "60px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
};

export default useStyles;
