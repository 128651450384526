import { MenuItem } from "@mui/material";
import { RacwaSelect, RacwaSelectProps } from "@racwa/react-components";
import { isMobileOnly } from "react-device-detect";

const simpleSelectThreshold = 20;

export interface ResponsiveSelectProps extends Omit<RacwaSelectProps, "native"> {
  options?: any[];
  getValue?: (item: any) => string | number | undefined | null;
  getDisplayValue?: (item: any) => string | undefined | null;
}

export const ResponsiveSelect: React.FC<ResponsiveSelectProps> = ({ options, getValue, getDisplayValue, ...props }) => {
  const useNative = (options?.length || 0) < simpleSelectThreshold && isMobileOnly;
  const optionElements = options?.map((item, index) => {
    const optionProps = {
      key: index,
      value: (getValue && getValue(item)) || item,
    };
    const display = (getDisplayValue && getDisplayValue(item)) || item;
    return useNative ? <option {...optionProps}>{display}</option> : <MenuItem {...optionProps}>{display}</MenuItem>;
  });

  return (
    <RacwaSelect {...props} native={useNative}>
      {optionElements}
    </RacwaSelect>
  );
};

export default ResponsiveSelect;
