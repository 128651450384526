import { HTTP_STATUS_CODE_TIMEOUT, useSessionIdStorageKey } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UTILITY_SESSION_TIMEOUT_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";
import { ApiException } from "../useApiClient/ClientProxy.generated";

export const EXPIRY_CHECK_INTERVAL = 30000;

export const useSessionExpiry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const apiClient = useApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const sessionId = sessionStorage.getItem(sessionIdStorageKey) ?? "";
  const [expireInSeconds, setExpireInSeconds] = useState<number | null>(null);
  const [wait, setWait] = useState(EXPIRY_CHECK_INTERVAL);
  const [isError, setIsError] = useState(false);

  const shouldSkipExpiryCheck = !sessionId.trim() || location.pathname.endsWith(UTILITY_SESSION_TIMEOUT_PAGE_URL);
  const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL);

  useEffect(() => {
    let isCancelled = false;
    const checkSessionExpiry = async () => {
      try {
        setIsError(false);
        const response = await apiClient.getsessionexpiry();
        if (!isCancelled) {
          const expiry = parseInt(response.result);
          setWait(getNextDelay(expiry));
          setExpireInSeconds(expiry);
        }
      } catch (ex) {
        const response = ex as ApiException;
        if (response?.status === HTTP_STATUS_CODE_TIMEOUT) {
          setWait(getNextDelay(-1));
          setExpireInSeconds(-1);
          navigate(UTILITY_SESSION_TIMEOUT_PAGE_URL, {
            replace: true,
          });
        } else {
          setIsError(true);
        }
      }
    };

    if (!shouldSkipExpiryCheck) {
      const handle = setInterval(async () => await checkSessionExpiry(), wait);
      return () => {
        isCancelled = true;
        clearTimeout(handle);
      };
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wait, shouldSkipExpiryCheck]);

  return {
    isError,
    expireInSeconds,
  };
};

export default useSessionExpiry;
