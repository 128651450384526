import { atom, useRecoilState } from "recoil";

const backdropMessageState = atom<string | undefined>({
  key: "BackdropMessageState",
  default: undefined,
});

export const useBackdropMessage = () => {
  return useRecoilState(backdropMessageState);
};

export const useSetBackdropMessage: () => (message?: string) => void = () => {
  const [, setIsOpen] = useBackdropMessage();
  return setIsOpen;
};

export default useSetBackdropMessage;
