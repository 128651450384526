import { HTTP_STATUS_CODE_NO_CONTENT, HTTP_STATUS_CODE_OK } from "raci-react-library";
import { useEffect, useState } from "react";
import useRoutes from "../../routing/useRoutes";
import useApiClient from "../useApiClient";

const useCheckHealth = () => {
  const client = useApiClient();
  const { isQuoteOrPurchaseFlow, isRetrieveQuote } = useRoutes();
  const [isHealthy, setIsHealthy] = useState<boolean>();

  const shouldCheckHealthStatus = isQuoteOrPurchaseFlow || isRetrieveQuote;

  useEffect(() => {
    let isCancelled = false;

    const checkHealthStatus = async () => {
      try {
        const response = await client.gethealthstatus();
        if (!isCancelled) {
          setIsHealthy(response.status === HTTP_STATUS_CODE_OK || response.status === HTTP_STATUS_CODE_NO_CONTENT);
        }
      } catch (ex) {
        if (!isCancelled) {
          setIsHealthy(false);
        }
      }
    };

    shouldCheckHealthStatus && checkHealthStatus();

    return () => {
      isCancelled = true;
    };
  }, [shouldCheckHealthStatus, client]);

  return isHealthy;
};

export default useCheckHealth;
