export const NON_BREAKING_SPACE = "\u00a0";
export const HTTP_STATUS_CODE_OK = 200;
export const HTTP_STATUS_CODE_NO_CONTENT = 204;
export const HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE = 299;
export const HTTP_STATUS_CODE_INTERNAL_SERVER = 500;
export const MAX_ALLOWED_POSTCODE = 6699;
export const ON_SITE_PARKING_OPTION = "On-Site";
export const MIN_CUSTOM_MARKET_VALUE = 1000;
export const MAX_CUSTOM_MARKET_VALUE = 150000;
export const DATE_FORMAT_DD_MM_YYYY = "dd/MM/yyyy";
export const DATE_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";
export const ONLINE_DISCOUNT_TYPE = "1";
export const OTHER_DISCOUNT_TYPE = "2";
export const CONFIRM_TEXT = "Confirm";
export const NEXT_TEXT = "Next";
export const NO = "no";
export const YES = "yes";
export const SKIP = "skip";
export const REMOVE_EXCESS = true;

// <------------------>
//  Session State Keys
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_CO_";

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = "AtomSessionId";
export const ATOM_QUOTE_NUMBER = "AtomQuoteNumber";
