import { Grid, Link } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import React from "react";
import { useFormContext } from "react-hook-form";
import { SKIP } from "../../../../shared/constants";
import { Values } from "../../types";

const action = SKIP;
const name = action;
const id = `link-${name}`;

export interface SkipLinkProps {
  show?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const SkipLink: React.FC<SkipLinkProps> = ({ show, disabled, onClick }) => {
  const { setValue } = useFormContext<Values>();
  return show ? (
    <Grid container item xs={12} justifyContent="center" alignItems="center">
      <Link
        id={id}
        name={name}
        data-testid={id}
        component="button"
        type="button"
        role="button"
        variant="body2"
        color="primary"
        onClick={async (e: React.SyntheticEvent<Element, Event>) => {
          e.preventDefault();
          gtm(fieldTouched("Skip Link"));
          setValue("areYouAMember", action);
          onClick && onClick();
        }}
        disabled={disabled}
      >
        Skip {">"}
      </Link>
    </Grid>
  ) : null;
};

export default SkipLink;
