import { OpenBackdrop } from "raci-react-library";
import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import useStepperPage from "../shared/hooks/useStepperPage";
import useStandardPage from "../shared/hooks/useStandardPage";
import useSessionExpiry from "../shared/hooks/useSessionExpiry";
import RouteGuard from "../shared/routing/RouteGuard";
import { QUOTE_MEMBER_DETAILS_PAGE_URL, utilityRoutes } from "../shared/routing/routes.config";
import { TitleGuard } from "../shared/routing/TitleGuard";
import useRoutes from "../shared/routing/useRoutes";


const RacwaStepperPageTemplate = lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStepperTemplate }))
);
const RacwaStandardPageTemplate = lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStandardPageTemplate }))
);

export const Views: React.FC = () => {
  const { formRoutes } = useRoutes();
  const stepperPageProps = useStepperPage();
  const standardPageProps = useStandardPage();
  useSessionExpiry();

  return (
    <>
      <TitleGuard />
      <RouteGuard disableChecks={utilityRoutes.map((item) => item.path)} />
      <Routes>
        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStepperPageTemplate {...stepperPageProps}>
                <Outlet />
              </RacwaStepperPageTemplate>
            </Suspense>
          }
        >
          <Route index element={<Navigate to={QUOTE_MEMBER_DETAILS_PAGE_URL} replace />} />
          {formRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>

        <Route
          path={process.env.PUBLIC_URL}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {utilityRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
        </Route>
        <Route path="*" element={<Navigate to={QUOTE_MEMBER_DETAILS_PAGE_URL} replace />} />
        <Route path="/" element={<Navigate to={QUOTE_MEMBER_DETAILS_PAGE_URL} replace />} />
      </Routes>
    </>
  );
};

export default Views;
