import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { theme } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { useGetSessionState, useSessionState } from "raci-react-library";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PremiumState, premiumState } from "../../../atoms";
import { Values as MemberDetailsValues } from "../../../views/MemberDetails/types";
import { Values as PersonalInformationValues } from "../../../views/PersonalInformation/types";
import { Values as YourQuoteValues } from "../../../views/YourQuote/types";
import { CoverType, PaymentFrequency, PremiumDetails } from "../../hooks/useApiClient/ClientProxy.generated";
import {
  PolicyRoutes,
  POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL,
  POLICY_JOINT_POLICYHOLDER_PAGE_URL,
  POLICY_MEMBERSHIP_LEVEL_PAGE_URL,
  POLICY_PERSONAL_INFORMATION_PAGE_URL,
  QuoteRoutes,
} from "../../routing/routes.config";
import format from "../CurrencyInput/format";
import PremiumBreakdown from "../PremiumBreakdown";

export const useStyles = {
  "display-block": {
    display: "block",
  },
  "margin-bottom-5px": {
    marginBottom: "5px",
  },
  "margin-top-15px": {
    marginTop: "15px",
  },
  "rac-border-top": {
    borderTop: `4px solid ${theme.palette.primary.main}`,
  },
  informationContainer: {
    marginBottom: "19px",
  },
  savingsContainer: {
    color: colors.linkBlue,
    marginTop: "34px",
    marginBottom: "7px",
  },
  dialogActions: {
    padding: "8px 24px",
    marginBottom: "40px",
  },
  card: {
    marginBottom: "30px",
    background: theme.palette.grey["100"],
  },
};

const getTitle = (name?: string) => {
  return (
    <>
      {name && name.length <= 12 ? (
        <>
          Good news <span style={{ whiteSpace: "nowrap" }}>{name}</span>
        </>
      ) : (
        "Good news"
      )}
    </>
  );
};

const defaultPremium: PremiumDetails = {
  annual: { basic: 0, governmentCharges: 0, gst: 0, total: 0 },
  monthly: { total: 0, gst: 0, governmentCharges: 0, basic: 0, instalment: 0 },
};
const newAnnualPriceText = "New annual price";
const newMonthlyPriceText = "New monthly price";
const contactPages = [
  POLICY_PERSONAL_INFORMATION_PAGE_URL,
  POLICY_MEMBERSHIP_LEVEL_PAGE_URL,
  POLICY_JOINT_POLICYHOLDER_PAGE_URL,
  POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL,
];

export const PremiumChangedDialog: React.FC<any> = () => {
  const classes = useStyles;
  const navigate = useNavigate();
  const location = useLocation();
  const [premium, setPremiumState] = useRecoilState<PremiumState>(premiumState);
  const memberDetails = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const personalInformation = useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);
  const [yourQuote, setYourQuote] = useSessionState<YourQuoteValues>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  const coverType = yourQuote?.cover?.type || CoverType.Caravan;
  const frequency = yourQuote?.cover?.frequency;
  const isPayAnnual = frequency === PaymentFrequency.Annual;

  const currentPremium = yourQuote?.quote?.covers[0]?.premiumDetails || defaultPremium;
  const currentSelectedPremium = isPayAnnual ? currentPremium.annual.total : currentPremium.monthly.total;

  const newPremium = premium.premiumDetails;
  const newSelectedPremium = isPayAnnual ? newPremium.annual.total : newPremium.monthly.total;

  const savings = currentSelectedPremium - newSelectedPremium;
  const hasReduced = savings > 0;

  const isOnContactDetailsPage =
    contactPages.filter((page) => location.pathname.indexOf(page) > -1 || page.indexOf(location.pathname) > -1).length >
    0;

  const memberName = isOnContactDetailsPage ? memberDetails.firstName || personalInformation.firstName : undefined;
  const titleText =
    memberName && hasReduced && isOnContactDetailsPage ? getTitle(memberName) : "We've updated your quote";

  const informationText = isOnContactDetailsPage
    ? hasReduced
      ? "A member discount has been applied."
      : "Based on the details that changed, we've updated your quote."
    : "We've updated some things since your quote was created and your price has changed.";

  const onClose = () => {
    setPremiumState({ ...premium, open: false });
    const excess = premium.excess || yourQuote.quote.covers[0].excess;
    setYourQuote({
      ...yourQuote,
      quote: {
        ...yourQuote.quote,
        covers: [
          {
            ...yourQuote.quote.covers[0],
            premiumDetails: { ...premium.premiumDetails },
            excess,
            discounts: premium.discounts,
          },
        ],
      },
    });
    if (premium.nextPage) {
      navigate(premium.nextPage);
    }
  };

  const showMonthlyInstallmentSecondaryLineItem = isOnContactDetailsPage && frequency === PaymentFrequency.Annual;

  return premium.open ? (
    <Dialog
      open={premium.open || false}
      aria-labelledby="premium-changed-title"
      aria-describedby="premium-changed-content-text"
      PaperProps={{ sx: classes["rac-border-top"] }}
      id="premium-changed-pop-up"
      data-testid="premium-changed-pop-up"
      fullWidth
    >
      <DialogTitle id="premium-changed-title" data-testid="premium-changed-title">
        <Typography variant="h1">{titleText}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="premium-changed-content-text" sx={classes.informationContainer} variant="body1">
          <strong>{informationText}</strong>
        </DialogContentText>
        {hasReduced && isOnContactDetailsPage ? (
          <DialogContentText id="premium-changed-savings-text" sx={classes.savingsContainer} variant="body2">
            <strong>You're saving {format(savings, { withDecimals: true, stripZeroCents: true })} this year</strong>
          </DialogContentText>
        ) : null}
        <Card variant="outlined" sx={classes.card}>
          <CardContent>
            <Grid container sx={classes["margin-bottom-5px"]} data-testid="main-cost-container">
              <Grid item xs={8}>
                <Typography variant="h4" align="left">
                  {isPayAnnual ? newAnnualPriceText : newMonthlyPriceText}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="h4"
                  id={`premium-changed-${isPayAnnual ? "annual" : "monthly"}-cost`}
                  align="right"
                >
                  <strong>
                    {format(
                      (isPayAnnual ? premium.premiumDetails.annual.total : premium.premiumDetails.monthly.instalment) ||
                        0,
                      {
                        withDecimals: true,
                      },
                    )}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            {!isPayAnnual && (
              <Grid container data-testid="secondary-cost-container">
                <Grid item xs={8}>
                  <Typography variant="body2" align="left">
                    Total price
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    align="right"
                    id="premium-changed-monthly-annualised-cost"
                    sx={classes["display-block"]}
                  >
                    {format(premium.premiumDetails.monthly.total || 0, {
                      withDecimals: true,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {showMonthlyInstallmentSecondaryLineItem && (
              <Grid container data-testid="secondary-cost-container">
                <Grid item xs={8}>
                  <Typography variant="body2" align="left">
                    {newMonthlyPriceText}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    align="right"
                    id="premium-changed-monthly-cost"
                    sx={classes["display-block"]}
                  >
                    {format(premium.premiumDetails.monthly.instalment || 0, {
                      withDecimals: true,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container sx={classes["margin-top-15px"]} item xs={12}>
              <PremiumBreakdown
                coverType={coverType}
                frequency={frequency || PaymentFrequency.Annual}
                {...premium.premiumDetails}
              />
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          id="button-premium-changed-next"
          data-testid="button-premium-changed-next"
          type="button"
          role="button"
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default PremiumChangedDialog;
