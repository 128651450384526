import { Divider, Grid, Typography } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { format } from "../../../../../../shared/components/CurrencyInput/format";
import { QuoteRoutes, QUOTE_YOUR_QUOTE_PAGE_URL, QUOTE_YOUR_CARAVAN_PAGE_URL } from "../../../../../../shared/routing/routes.config";
import { Values as YourCaravanValues } from "../../../../../YourCaravan/types";
import { Values as YourQuoteValues } from "../../../../../YourQuote/types";
import SummaryItem from "../SummaryItem";

const componentTestId = "policySummary-caravanDetails";

const CaravanDetails: React.FC = () => {
  const aboutYourCaravanState = useGetSessionState<YourCaravanValues>(QuoteRoutes.YourCaravan);

  return (
    <>
      <Typography variant="body2" data-testid={`${componentTestId}-caravanInformation`}>
        {aboutYourCaravanState.caravan?.year} {aboutYourCaravanState.caravan?.manufacturer.toLocaleUpperCase()}{" "}
        {aboutYourCaravanState.caravan?.description.toLocaleUpperCase()}
      </Typography>
    </>
  );
};

const CaravanCoverSummary: React.FC = () => {
  const yourQuoteState = useGetSessionState<YourQuoteValues>(QuoteRoutes.YourQuote);

  return (
    <>
      <Typography variant="body2" data-testid={`${componentTestId}-agreedValue`}>
        Agreed Value: {format(yourQuoteState.cover?.sumInsured ?? 0, { withDecimals: false })}
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-basicExcess`}>
        Basic Excess: {format(yourQuoteState.cover?.excess ?? 0, { withDecimals: false })}
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-contentsCover`}>
        Contents cover: {format(yourQuoteState.cover?.contents ?? 0, { withDecimals: false })}
      </Typography>
    </>
  );
};

export const YourCaravanSummaryItem: React.FC = () => {
  const navigate = useNavigate();
  const redirectToCaravanPage = () => {
    navigate(QUOTE_YOUR_CARAVAN_PAGE_URL);
  };

  const redirectToYourQuotePage = () => {
    navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
  };

  return (
    <>
      <Grid container alignItems="center" data-testid="yourCaravanItem">
        <Grid item xs={12}>
          <SummaryItem title="Your Caravan" name="YourCaravan" onClick={redirectToCaravanPage}>
            <CaravanDetails />
          </SummaryItem>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <SummaryItem name="YourQuote" onClick={redirectToYourQuotePage}>
            <CaravanCoverSummary />
          </SummaryItem>
        </Grid>
      </Grid>
    </>
  );
};

export default YourCaravanSummaryItem;
