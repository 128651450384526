import NotFoundCard, { CardMessage } from "../../../../shared/components/NotFoundCard";

export const defaultMessage: CardMessage = {
  title: "We couldn't find your details",
  firstParagraph: "Please check that you've entered your personal information correctly.",
  secondParagraph: "Alternatively, if you're known by different names try your legal or preferred name.",
  thirdParagraph: "If we still can't find your details select skip to continue and we'll try again later.",
};

export const attemptsExhaustedMessage: CardMessage = {
  title: "We still couldn't find your details",
  firstParagraph: "Select Skip to continue.",
};

export interface CouldNotFindDetailsCardProps {
  isContactMatchAttemptsExhausted?: boolean | false;
}

const CouldNotFindDetailsCard: React.FC<CouldNotFindDetailsCardProps> = ({ isContactMatchAttemptsExhausted }) => (
  <NotFoundCard titleAndMessage={isContactMatchAttemptsExhausted ? attemptsExhaustedMessage : defaultMessage} />
);

export default CouldNotFindDetailsCard;
