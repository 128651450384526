import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { PaymentFrequency } from "../../hooks/useApiClient/ClientProxy.generated";
import format from "../CurrencyInput/format";

export interface SavingsInformationLabelProps {
  monthlyTotal: number;
  annualTotal: number;
}

const iconId = "icon-payment-frequency-piggy-bank";

export const SavingsInformationLabel: React.FC<SavingsInformationLabelProps> = ({ monthlyTotal, annualTotal }) => {
  const { watch } = useFormContext();
  const isAnnual = watch("frequency") === PaymentFrequency.Annual;
  const savings = monthlyTotal - annualTotal;
  const amount = format(savings, {
    withDecimals: true,
    stripZeroCents: true,
  });
  const monthlyTotalDisplay = format(monthlyTotal, {
    withDecimals: true,
    stripZeroCents: true,
  });
  let showIcon = true;
  let label = "";
  if (savings > 0) {
    label = isAnnual
      ? `Paying annually is ${amount} cheaper`
      : `Total ${monthlyTotalDisplay}, pay annually to save ${amount}`;
  } else if (!isAnnual) {
    showIcon = false;
    label = `Total ${monthlyTotalDisplay}`;
  }

  return label ? (
    <Grid container sx={{ marginBottom: "12px"}}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {showIcon && (
            <FontAwesomeIcon
              icon={faPiggyBank}
              aria-hidden
              style={{ marginRight: "5px" }}
              size="lg"
              id={iconId}
              data-testid={iconId}
            />
          )}
          {label}
        </Typography>
      </Grid>
    </Grid>
  ) : null;
};

export default SavingsInformationLabel;
