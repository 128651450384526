import { OutlinedInput, OutlinedInputProps } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { thousandSeparatorThreshold } from "./format";

const NumericOutlinedInput: React.FC<OutlinedInputProps> = (props) => (
  <OutlinedInput {...props} inputProps={{ type: "text", inputMode: "numeric" }} />
);

export interface CurrencyInputProps extends NumericFormatProps<OutlinedInputProps> {
  withDecimals?: boolean | null;
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({ withDecimals, value, ...props }) => {
  return (
    <NumericFormat
      value={value}
      thousandSeparator={(value as number) >= thousandSeparatorThreshold ? true : undefined}
      decimalScale={withDecimals ? 2 : 0}
      fixedDecimalScale={!!withDecimals}
      prefix="$"
      customInput={NumericOutlinedInput}
      {...props}
    />
  );
};

export default CurrencyInput;
