import { theme } from "@racwa/react-components";

export const useStyles = {
  button: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "135px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "8px",
    },
  },
};
