import { useGetSessionState, useLogger, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHandlePremiumChanges } from "../../../../shared/components/PremiumChangedDialog/useHandlePremiumChanges";
import { NO } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  Gender,
  MembershipTier,
  PolicyholderType,
  Title,
  UpdateContactDetailsRequest,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import {
  PolicyRoutes,
  POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL,
  QuoteRoutes,
  POLICY_PAYMENT_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { getGenderOrDefault } from "../../../../shared/utils";
import { Values as AboutYouValues } from "../../../AboutYou/types";
import { Values as JointPolicyholderInformationValues } from "../../../JointPolicyHolderInformation/types";
import { Values as PersonalInformationValues } from "../../../PersonalInformation/types";
import { FormProps, Values } from "../../types";

export const useJointPolicyholderInformation = (): FormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const setPremiumState = useHandlePremiumChanges();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [sessionState, setSessionState] = useSessionState<JointPolicyholderInformationValues>();
  const { membershipLevel } = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const personalInformationState = useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);
  const [jointMembershipLevelState, setJointMembershipLevelState] = useSessionState({
    skipPageTrackingRecalculation: true,
    specificKey: PolicyRoutes.JointMembershipLevel,
  });

  const skipJointMembershipLevelPage = () =>
    setJointMembershipLevelState({ ...jointMembershipLevelState, isCompleted: true });
  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const createRequest = ({
    mailAddressSameAsPrimary,
    mailingAddress,
    title,
    gender,
    lastName,
    firstName,
    email,
    contactNumber,
  }: Values): UpdateContactDetailsRequest => {
    const titleCasted = title as Title | undefined;
    const actualGender = getGenderOrDefault(titleCasted, gender as Gender | undefined);
    const actualMailingAddress =
      mailAddressSameAsPrimary === NO
        ? mailingAddress?.moniker || ""
        : personalInformationState.mailingAddress?.moniker || "";
    return {
      mailingAddress: actualMailingAddress,
      policyholderType: PolicyholderType.Additional1,
      title: titleCasted,
      gender: actualGender,
      surname: lastName,
      firstName,
      email,
      contactNumber,
    };
  };

  const onSubmit = async (values: Values) => {
    try {
      setBackdrop(true);
      const request = createRequest(values);
      const { result } = await apiClient.updatecontactdetails(request);
      const requiresJointPolicyholderMembershipCheck =
        (result.askMembershipTier && membershipLevel !== MembershipTier.Gold) ?? false;
      setSessionState({
        ...values,
        captureMembershipLevel: requiresJointPolicyholderMembershipCheck,
        isCompleted: true,
      });

      if (!result.askMembershipTier) {
        skipJointMembershipLevelPage();
      }

      const nextPage = result.askMembershipTier ? POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL : POLICY_PAYMENT_PAGE_URL;
      setPremiumState({ discounts: result.discounts, premiumDetails: { ...result }, nextPage });
    } catch (ex) {
      logException({
        location: "useJointPolicyholderInformation.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  return {
    data: {},
    form,
    onSubmit,
  };
};

export default useJointPolicyholderInformation;
