import { FormControl, FormHelperText, FormLabel, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CurrencyInput from "../../../../shared/components/CurrencyInput";
import format from "../../../../shared/components/CurrencyInput/format";
import MarketValueOverThresholdNotification from "../../../../shared/components/MarketValueOverThresholdNotification";
import { CoverType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

const defaultMin = 1000;
const defaultMax = 150000;
const fieldName = "marketValue";
const label = "Market Value";
const inputId = "input-marketValue";
const errorId = `${inputId}-error-message`;

export interface MarketValueInputProps {
  min?: number;
  max?: number;
  isCustomMarketValueRequired?: boolean;
  onChange?: () => void;
}

export const MarketValueInput: React.FC<MarketValueInputProps> = ({
  min = defaultMin,
  max = defaultMax,
  isCustomMarketValueRequired = false,
  onChange,
}) => {
  const { control, setValue, clearErrors, getValues } = useFormContext();
  const errorMessage = `Please enter a value between ${format(min)} and ${format(max)}`;

  useEffect(() => {
    if (!isCustomMarketValueRequired && getValues(fieldName)) {
      setValue(fieldName, undefined, { shouldDirty: true, shouldValidate: true });
      clearErrors(fieldName);
    }
  });

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: { value: isCustomMarketValueRequired, message: errorMessage },
        min: { value: min, message: errorMessage },
        max: { value: max, message: "" },
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => {
        const isMaxError = error?.type === "max";
        return (
          <FormControl component="fieldset" error={!!error && !isMaxError} fullWidth={true}>
            <FormLabel component="legend">
              Value of caravan
              <Typography variant="body2" sx={{marginTop: "5px", marginBottom: "5px", fontSize: "1rem"}}>
                Consider what your caravan, including accessories, would sell for in today's market.
              </Typography>
            </FormLabel>
            <CurrencyInput
              {...props}
              id={inputId}
              data-testid={inputId}
              allowNegative={false}
              color="primary"
              placeholder="e.g. $5000"
              fullWidth
              inputRef={ref}
              onValueChange={(newValue) => {
                onChange && onChange();
                onComponentChange(newValue.floatValue);
              }}
              onBlur={() => {
                gtm(fieldTouched(label));
                onBlur && onBlur();
              }}
              error={!!error}
            />
            {error?.message ? (
              <FormHelperText id={errorId} data-testid={errorId}>
                {error.message}
              </FormHelperText>
            ) : null}
            {isMaxError ? (
              <MarketValueOverThresholdNotification
                coverType={CoverType.Caravan}
                data-testid={errorId}
                threshold={max}
              />
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default MarketValueInput;
