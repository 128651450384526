import { RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import usePage from "../usePage";
import { useNavigate } from "react-router-dom";
import useRoutes from "../../routing/useRoutes";

export const sidebarTitle = process.env.REACT_APP_NAME ?? "";
export const sidebarContent = (
  <SidebarContent
    pdsUrl={process.env.REACT_APP_RAC_PDS_URL || EMPTY_URL}
    feedbackUrl={process.env.REACT_APP_RAC_FEEDBACK_URL || EMPTY_URL}
  />
);

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const navigate = useNavigate();
  const commonPageProps = usePage();
  const { steps, activeStepIndex, navigateToPreviousStep } = useRoutes();

  const mobileStepperProps: RacwaStepperTemplateProps["mobileStepperProps"] = {
    hideBack: !navigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  const onStepClick: RacwaStepperTemplateProps["onStepClick"] = (_, stepIndex) => {
    if (activeStepIndex !== stepIndex) {
      navigate(steps[stepIndex].path);
    }
  };

  return {
    sidebarTitle,
    sidebarContent,
    steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
