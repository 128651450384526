import { DateOfBirthInput } from "raci-react-library";
import React from "react";

const label = "Great, what's their date of birth?";
const id = "additionalPolicyholderDateOfBirth";
const errorMessage = "Joint policyholders must be aged between 16 and 100";

const AdditionalPolicyholderDoB: React.FC = () => (
  <DateOfBirthInput label={label} id={id} name={id} errorMessage={errorMessage} requiredMessage={errorMessage} />
);

export default AdditionalPolicyholderDoB;
