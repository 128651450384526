import { FieldValues, SubmitHandler } from "react-hook-form";

export const useFormotiv = <TFieldValues extends FieldValues>(
  onSubmit: SubmitHandler<TFieldValues>,
  complete: boolean = false
) => {
  return async (data: TFieldValues, event?: React.BaseSyntheticEvent) => {
    if (window.formotiv) {
      if (complete) {
        window.formotiv.submitFinal(window.formotivConfig);
      } else {
        window.formotiv.submit(window.formotivConfig);
      }
    }

    return onSubmit(data, event);
  };
};

export const trackCustomFormotivInput = (inputName: string, inputType: string, value: any) => {
  window.formotiv && window.formotiv.inputActivity(window.formotivConfig, inputName, inputType, value);
};

export default useFormotiv;
