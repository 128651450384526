export const RequiredSelection = "Please select an option";
export const RequiredYesNoMessage = "Please select Yes or No";

export const InvalidDobMessage = "You must be aged between 16 and 100";

export const pleaseSelectAMessage = (fieldName: string) => `Please select a ${fieldName}`;

export const pleaseEnterAMessage = (fieldName: string) => `Please enter a ${fieldName}`;

export const pleaseEnterAValidMessage = (fieldName: string) => `Please enter a valid ${fieldName}`;

export const SelectPolicyStartDateMessage = "Please select a policy start date";

export interface ScoreBoundary {
  minBoundary: number;
  maxBoundary: number;
}
export interface QuantileScoreBoundaries {
  group1: ScoreBoundary;
  group2: ScoreBoundary;
  group3: ScoreBoundary;
  group4: ScoreBoundary;
  group5: ScoreBoundary;
}

export const FormotivScores: QuantileScoreBoundaries = {
  group1: {
    minBoundary: 0.0433,
    maxBoundary: 0.19,
  },
  group2: {
    minBoundary: 0.19,
    maxBoundary: 0.247,
  },
  group3: {
    minBoundary: 0.247,
    maxBoundary: 0.296,
  },
  group4: {
    minBoundary: 0.296,
    maxBoundary: 0.35,
  },
  group5: {
    minBoundary: 0.35,
    maxBoundary: 0.789,
  },
};
