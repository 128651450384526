import { Link, Typography } from "@mui/material";
import { InsurancePhoneNumberLink } from "raci-react-library";
import RoadblockNotificationCard from "../../../../shared/components/RoadblockCardNotification";

const CaravanInvalidPurposeCard = () => {

  const message = (
    <div data-testid="caravanInvalidPurposeCard">
      <Typography variant="body2">
        We're unable to insure caravans used for business or commercial purposes. If you'd like to discuss this with us,
        including why we made this decision, you can call us on <InsurancePhoneNumberLink id="call us link" />.
      </Typography>
      <br></br>
      <Typography variant="body2">
        To help you find appropriate cover,{" "}
        <Link
          variant="inherit"
          color="inherit"
          rel="noopener noreferrer"
          target="_blank"
          href={process.env.REACT_APP_FIND_AN_INSURER}
        >
          Find an Insurer
        </Link>{" "}
        (from the Insurance Council of Australia) has a list of insurance providers offering general insurance.
      </Typography>
    </div>
  );

  return <RoadblockNotificationCard message={message} />;
};

export default CaravanInvalidPurposeCard;
