import { LinkProps } from "@mui/material";
import { RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL } from "raci-react-library";
import getPageTemplateBannerAndButtons from "./getPageTemplateBannersAndButtons";

export type CommonPageProps = Pick<
  RacwaStepperTemplateProps,
  | "breadcrumbs"
  | "responsiveHeaderProps"
  | "headerEndAction"
  | "notificationBannerContent"
  | "showNotificationBanner"
  | "footerProps"
>;

const getLinkProps = (link?: string): Partial<LinkProps> => ({
  href: link ?? EMPTY_URL,
});

export const breadcrumbs: CommonPageProps["breadcrumbs"] = {
  links: [
    { name: "Car & Motoring", href: process.env.REACT_APP_RAC_CAR_AND_MOTORING_PAGE ?? EMPTY_URL },
    { name: "Caravan Insurance", href: process.env.REACT_APP_RAC_CARAVAN_INSURANCE_PAGE ?? EMPTY_URL },
  ],
  currentPage: { name: "Quote" },
};

export const usePage = (): CommonPageProps => {
  const responsiveHeaderProps: CommonPageProps["responsiveHeaderProps"] = {
    NavBreadcrumbsProps: {
      homeLink: process.env.REACT_APP_RAC_HOME_PAGE ?? EMPTY_URL,
    },
  };

  const { headerEndAction, notificationBannerContent } = 
  getPageTemplateBannerAndButtons();

  const footerProps: CommonPageProps["footerProps"] = {
    variant: "sidebar",
    privacyLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE),
    securityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_SECURITY_PAGE),
    disclaimerLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_DISCLAIMER_PAGE),
    accessibilityLinkProps: getLinkProps(process.env.REACT_APP_RAC_ABOUT_ACCESSIBILITY_PAGE),
  };

  return {
    breadcrumbs,
    responsiveHeaderProps,
    headerEndAction,
    notificationBannerContent,
    showNotificationBanner: !!notificationBannerContent,
    footerProps,
  };
};

export default usePage;
