import { getInsurancePhoneButton } from "raci-react-library";
import { CommonPageProps } from "..";

export const getPageTemplateBannerAndButtons = (): Pick<
  CommonPageProps,
  "headerEndAction" | "notificationBannerContent"
> => {
  const phoneButton = getInsurancePhoneButton();
  let headerEndAction = phoneButton;

  let notificationBannerContent;

  /*
   ** WARNING:
   ** KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO TO THE BELOW IF STATEMENT
   ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET ENVIRONMENT
   ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE AND SHIP CODE
   ** (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED JAVASCRIPT ARTIFACTS)
   */
  if (
    process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
    process.env.REACT_APP_ENVIRONMENT === "DEV" ||
    process.env.REACT_APP_ENVIRONMENT === "SIT" ||
    process.env.REACT_APP_ENVIRONMENT === "UAT"
  ) {
    const { HeaderButtons, TestBanner } = require("raci-react-library-npe");

    notificationBannerContent = <TestBanner />;

    headerEndAction = (
      <HeaderButtons phoneButton={phoneButton} npeButtons={[]} isUat={process.env.REACT_APP_ENVIRONMENT === "UAT"} />
    );
  }

  return {
    headerEndAction,
    notificationBannerContent,
  };
};

export default getPageTemplateBannerAndButtons;
