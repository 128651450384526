import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../../../../shared/constants";
import { PolicyRoutes, POLICY_START_DATE_PAGE_URL } from "../../../../../../shared/routing/routes.config";
import { Values as StartDateValues } from "../../../../../FurtherDetails/types";
import SummaryItem from "../SummaryItem";

const componentTestId = "policySummary-policyStartDate";

const PolicyStartDateContent: React.FC = () => {
  const startDatePage = useGetSessionState<StartDateValues>(PolicyRoutes.StartDate);
  const startDate = new Date(startDatePage.policyStartDate ?? Date.now());

  return (
    <Typography variant="body2" data-testid={`${componentTestId}-date`}>
      Policy start date {format(startDate, DATE_FORMAT_DD_MM_YYYY)}
    </Typography>
  );
};

export const PolicyStartDateSummaryItem: React.FC = () => {
  const navigate = useNavigate();
  const redirectToStartDatePage = () => {
    navigate(POLICY_START_DATE_PAGE_URL);
  };

  return (
    <>
      <Grid container alignItems="center" data-testid="policyStartDateItem">
        <SummaryItem title="Policy start date" name="StartDate" onClick={redirectToStartDatePage}>
          <PolicyStartDateContent />
        </SummaryItem>
      </Grid>
    </>
  );
};

export default PolicyStartDateSummaryItem;
