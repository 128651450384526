import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotFoundCard from "../../../../shared/components/NotFoundCard";
import { quoteRoutesArray } from "../../../../shared/routing/routes.config";

export const CantFindQuoteCard: React.FC = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(quoteRoutesArray[0].path);
  };

  const getNewQuoteMessage = (
    <Typography component={"span"} variant="body2">
      Please check your details and try again or{" "}
      <Link
        id="get-quote-link"
        data-testid="get-quote-link"
        href={quoteRoutesArray[0].path}
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}
      >
        get a new quote.
      </Link>
    </Typography>
  );

  return (
    <NotFoundCard
      titleAndMessage={{
        title: "We couldn't find your quote",
        firstParagraph: getNewQuoteMessage,
      }}
    />
  );
};

export default CantFindQuoteCard;
