import { event, gtm } from "@racwa/analytics";
import { useGetSessionState, useLogger, useSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MAX_ALLOWED_POSTCODE, ON_SITE_PARKING_OPTION, YES } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { QUOTE_ABOUT_YOU_PAGE_URL, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { Values as YourCaravanValues } from "../../../YourCaravan/types";
import { FormProps, Values } from "../../types";

export const useAboutYourCaravan = (): FormProps => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { suburbs, overnightParkingOptions } = useReferenceData();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [sessionState, setSessionState] = useSessionState<Values>();
  const caravanState = useGetSessionState<YourCaravanValues>(QuoteRoutes.YourCaravan);

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const { clearErrors, control, setError } = form;

  const watchSuburb = useWatch({ control: control, name: "suburb" });
  const isOnsiteParking = useWatch({ control: control, name: "overnightParking" }) === ON_SITE_PARKING_OPTION;
  const isBusinessOrCommercialUsage =
    useWatch({
      control: control,
      name: "isBusinessOrCommercialUsage",
    }) === YES;

  const [isAboveAllowedPostcode, setIsAboveAllowedPostcode] = useState(false);
  const [hasParkingSelectionAndSuburbValidationFailed, setHasParkingSelectionAndSuburbValidationFailed] =
    useState(false);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (watchSuburb && watchSuburb.postcode && parseInt(watchSuburb.postcode) > MAX_ALLOWED_POSTCODE) {
        setIsAboveAllowedPostcode(true);
      } else {
        setIsAboveAllowedPostcode(false);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [watchSuburb]);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (isOnsiteParking && isAboveAllowedPostcode) {
        setHasParkingSelectionAndSuburbValidationFailed(true);
        setError("suburb", { message: "" });
        setError("overnightParking", { message: "" });
      } else {
        setHasParkingSelectionAndSuburbValidationFailed(false);
        clearErrors("suburb");
        clearErrors("overnightParking");
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [isAboveAllowedPostcode, isOnsiteParking, setError, clearErrors]);

  useEffect(() => {
    if (isBusinessOrCommercialUsage) {
      gtm(event("business or commercial purpose"));
    }
  }, [isBusinessOrCommercialUsage]);

  const onSubmit = async (values: Values) => {
    apiClient.getnext().catch((ex) => {
      logException({
        location: "useAboutYourCaravan.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
    });
    setSessionState({ ...values, isCompleted: true });
    navigate(QUOTE_ABOUT_YOU_PAGE_URL);
  };

  return {
    form,
    data: {
      overnightLocations: overnightParkingOptions,
      suburbs,
      caravan: caravanState.caravan,
      hasParkingSelectionAndSuburbValidationFailed,
      isBusinessOrCommercialUsage,
    },
    onSubmit,
  };
};

export default useAboutYourCaravan;
