import {
  HTTP_STATUS_CODE_INTERNAL_SERVER,
  useGetSessionState,
  useLogger,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, PaymentRequest } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import { PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { Values as MemberDetailsValues } from "../../../../views/MemberDetails/types";
import { PaymentData } from "../../types";

export interface OnSubmitBankAccountEvent extends PaymentRequest {}

export interface UseBankAccountResults {
  isError: boolean;
  onSubmit: (event: OnSubmitBankAccountEvent) => void | Promise<void>;
}

export const BankPayment = (): UseBankAccountResults => {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [isError, setIsError] = useState(false);
  const [paymentData, setPaymentData] = useSessionState<PaymentData>({
    specificKey: PolicyRoutes.Payment,
    skipPageTrackingRecalculation: true,
  });
  const memberDetailsState = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const sanitizeAccountName = (accountName: string | undefined): string => {
    if (accountName) {
      accountName = accountName.replace(/—/g, "-");
      accountName = accountName.replace(/’/g, "'");
      accountName = removeTrailingApostrophe(accountName);
      return accountName.trim();
    }
    return "";
  };

  const removeTrailingApostrophe = (accountName: string): string => {
    while (accountName.substr(accountName.length - 1) === "'") {
      accountName = accountName.substring(0, accountName.length - 1);
    }
    return accountName;
  };

  const onSubmitBankAccount = async (event: OnSubmitBankAccountEvent): Promise<void> => {
    await payWithBankAccount({
      bsb: event.bsb?.trim(),
      accountNumber: event.accountNumber?.trim(),
      accountName: event.accountName?.trim(),
      frequency: event.frequency,
      method: event.method,
    } as PaymentRequest);

    if (!paymentData.policyNumber) {
      setIsError(true);
    }
  };

  const payWithBankAccount = async (paymentRequest: PaymentRequest) => {
    setBackdrop(true);
    setIsError(false);

    try {
      paymentRequest.accountName = sanitizeAccountName(paymentRequest.accountName);

      const { result } = await apiClient.payment(paymentRequest);

      if (result.policyNumber) {
        setPaymentData({
          policyNumber: result.policyNumber,
          receiptNumber: result.receiptNumber,
          firstName: memberDetailsState.firstName,
          isSuccessful: true,
          isCompleted: true,
          lockdownPreviousPage: true,
        });
      }
      setIsError(true);
    } catch (ex) {
      logException({
        location: "BankPayment.payWithBankAccount",
        message: ex,
        customProperties: customLogProperties,
      });
      const errorResponse = ex as ApiException;
      if (errorResponse.status >= HTTP_STATUS_CODE_INTERNAL_SERVER) {
        setIsError(true);
      }
    } finally {
      setBackdrop(false);
    }
  };

  return {
    isError,
    onSubmit: onSubmitBankAccount,
  };
};

export default BankPayment;
