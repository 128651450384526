import { atom } from "recoil";
import { ATOM_QUOTE_NUMBER, ATOM_SESSION_ID, SESSION_KEY_PREFIX } from "../shared/constants";
import { CoverSummary, Discount, NumberOptions } from "../shared/hooks/useApiClient/ClientProxy.generated";

export interface HappyBirthdayState {
  hasBeenPreviouslyDisplayed: boolean;
}

export interface FormotivQuoteNumber {
  hasBeenPushed: boolean;
}

export interface FormotivPopupState {
  hasBeenPreviouslyDisplayed: boolean;
}

export interface PremiumState extends Omit<CoverSummary, "type" | "excess"> {
  open?: boolean;
  excess?: NumberOptions;
  nextPage?: string | null;
  discounts?: Discount[];
}

export const happyBirthdayState = atom<HappyBirthdayState>({
  key: `${SESSION_KEY_PREFIX}happyBirthdayState`,
  default: { hasBeenPreviouslyDisplayed: false },
});

export const premiumState = atom<PremiumState>({
  key: `${SESSION_KEY_PREFIX}premiumDetailsStateState`,
  default: {
    premiumDetails: {
      annual: { basic: 0, governmentCharges: 0, gst: 0, total: 0 },
      monthly: { basic: 0, governmentCharges: 0, gst: 0, total: 0, instalment: 0 },
    },
  },
});

export const formotivQuoteNumber = atom<FormotivQuoteNumber>({
  key: `${SESSION_KEY_PREFIX}formotiveQuoteNumber`,
  default: { hasBeenPushed: false },
});

export const formotivPopupState = atom<FormotivPopupState>({
  key: `${SESSION_KEY_PREFIX}formotivPopup`,
  default: { hasBeenPreviouslyDisplayed: false },
});

export const sessionIdAtom = atom<string>({
  key: ATOM_SESSION_ID,
  default: "",
});

export const quoteNumberAtom = atom<string>({
  key: ATOM_QUOTE_NUMBER,
  default: "",
});
