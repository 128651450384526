import { FormHelperText, FormLabel, OutlinedInput, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { Controller, useFormContext } from "react-hook-form";
import { pleaseEnterAValidMessage } from "../../../../shared/constants/HelperText";

const inputId = "quoteNumberInput";

export interface QuoteNumberInputProps {
  quoteNumber?: string | null;
}

export const QuoteNumberInput: React.FC<QuoteNumberInputProps> = () => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name="quoteNumber"
      control={control}
      rules={{
        validate: {
          isValid: (newValue) =>
            /^qte\d{3,15}$/i.test((newValue || "").replace(/\s/g, "")) || pleaseEnterAValidMessage("quote number"),
        },
      }}
      render={({ field: { ref, onBlur, ...props }, fieldState: { error } }) => (
        <>
          <FormLabel>Quote number</FormLabel>
          <Typography variant="body1" sx={{ fontSize: "16px", lineHeight: "23px", paddingBottom: "10px" }}>
            You'll find this on your quote summary email.
          </Typography>
          <OutlinedInput
            {...props}
            id={inputId}
            data-testid={inputId}
            placeholder="e.g. QTE00123456"
            onBlur={() => {
              gtm(fieldTouched("Quote Number Input"));
              setValue("quoteNumber", (props.value || "").replace(/\s/g, ""), {
                shouldValidate: true,
                shouldDirty: true,
              });
              onBlur();
            }}
            inputRef={ref}
            error={!!error}
            fullWidth
          />
          {error?.message ? <FormHelperText error>{error.message}</FormHelperText> : null}
        </>
      )}
    />
  );
};

export default QuoteNumberInput;
