import { Typography } from "@mui/material";
import { InsurancePhoneNumberLink } from "raci-react-library";
import RoadblockNotificationCard from "../../../../shared/components/RoadblockCardNotification";

const RetrieveAttemptsExhaustedCard = () => {

  const message = (
    <Typography variant="body2">
      We can't retrieve your quote right now. Please try again later or call us on <InsurancePhoneNumberLink id="call us link" /> with your quote number.
    </Typography>
  );

  return <RoadblockNotificationCard message={message} />;
};

export default RetrieveAttemptsExhaustedCard;
