import { event, gtm } from "@racwa/analytics";
import { format } from "date-fns";
import { useGetSessionState, useLogger, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { quoteNumberAtom } from "../../../../atoms";
import { DATE_FORMAT_YYYY_MM_DD, NO, YES } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  KeptLocation,
  MembershipTier,
  Policyholder,
  QuoteRequest,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import useQuery from "../../../../shared/hooks/useQuery";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  PolicyRoutes,
  QUOTE_YOUR_QUOTE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { Values as AboutYourCaravanValues } from "../../../AboutYourCaravan/types";
import { Values as MemberDetailsValues } from "../../../MemberDetails/types";
import { Values as PersonalInformationValues } from "../../../PersonalInformation/types";
import { Values as YourCaravanValues } from "../../../YourCaravan/types";
import { Values as YourQuoteValues } from "../../../YourQuote/types";
import { FormProps, Values } from "../../types";

const toDateString = (inDate: Date | null | undefined | string): Date =>
  //@ts-ignore - We require date to be in this particular format
  format((inDate && new Date(inDate)) || new Date(), DATE_FORMAT_YYYY_MM_DD);

const canSpecifyMembershipTier = (state: MemberDetailsValues): boolean => {
  return state.areYouAMember === YES && state.isMultiMatch === true;
};

export const createSaveQuoteRequest = (
  value: Values,
  promotion: string | undefined | null,
  memberDetailsState: MemberDetailsValues,
  yourCaravanState: YourCaravanValues,
  aboutYourCaravanState: AboutYourCaravanValues,
): QuoteRequest => {
  const request: QuoteRequest = {
    asset: {
      registrationNumber: aboutYourCaravanState.registrationNumber,
      vehicleId: yourCaravanState.caravan?.id || -1,
      suburb: aboutYourCaravanState.suburb?.name || "",
      postcode: parseInt(aboutYourCaravanState.suburb?.postcode || "-1"),
      keptLocation: aboutYourCaravanState.overnightParking as KeptLocation,
      marketValue: yourCaravanState.marketValue || undefined,
    },
    policyholders: [],
  };

  const policyholder: Policyholder = {
    dateOfBirth: toDateString(
      memberDetailsState.areYouAMember === YES ? memberDetailsState.dateOfBirth : value.yourDateOfBirth,
    ),
  };
  if (canSpecifyMembershipTier(memberDetailsState) && value.membershipLevel) {
    policyholder.membershipTier = value.membershipLevel as MembershipTier;
  }

  request.policyholders.push(policyholder);

  if (value.addAdditionalPolicyholder === YES) {
    request.policyholders.push({
      dateOfBirth: toDateString(value.additionalPolicyholderDateOfBirth),
    });
  }

  if (promotion) {
    request.promotion = promotion;
  }

  return request;
};

export const useAboutYou = (): FormProps => {
  const navigate = useNavigate();
  const query = useQuery();
  const setBackdrop = useSetBackdrop();
  const apiClient = useApiClient();
  const { membershipLevels } = useReferenceData();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const setQuoteNumber = useSetRecoilState(quoteNumberAtom);
  const [state, setSessionState] = useSessionState<Values>();
  const [quoteSessionState, setQuoteSessionState] = useSessionState<YourQuoteValues>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });
  const memberDetailsState = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const yourCaravanState = useGetSessionState<YourCaravanValues>(QuoteRoutes.YourCaravan);
  const aboutYourCaravanState = useGetSessionState<AboutYourCaravanValues>(QuoteRoutes.AboutYourCaravan);

  const [personalInformationSessionState, setPersonalInformationSessionState] =
    useSessionState<PersonalInformationValues>({
      specificKey: PolicyRoutes.PersonalInformation,
      skipPageTrackingRecalculation: true,
    });
  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };
  const redirectToQuotePage = () => {
    navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
  };

  const initialState = {
    ...state,
    yourDateOfBirth: (state.yourDateOfBirth && new Date(state.yourDateOfBirth)) || null,
    additionalPolicyholderDateOfBirth:
      (state.additionalPolicyholderDateOfBirth && new Date(state.additionalPolicyholderDateOfBirth)) || null,
    membershipLevel: canSpecifyMembershipTier(memberDetailsState) ? state.membershipLevel : null,
  };

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: initialState,
  });
  const { setValue, clearErrors, setError, control } = form;

  const hasSelectedNoForAdditionalPolicyholder =
    useWatch({
      control: control,
      name: "addAdditionalPolicyholder",
    }) === NO;

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (hasSelectedNoForAdditionalPolicyholder) {
        setValue("additionalPolicyholderDateOfBirth", null);
        clearErrors("additionalPolicyholderDateOfBirth");
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [hasSelectedNoForAdditionalPolicyholder, setValue, clearErrors]);

  const watchAnyHadAccident = useWatch({
    control: control,
    name: "anyAccident",
  });

  const watchHadLicenceCancelled = useWatch({
    control: control,
    name: "hadLicenceCancelled",
  });

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (watchAnyHadAccident === YES) {
        setError("anyAccident", { message: "" });
        gtm(event("duty of disclosure accident"));
      } else if (watchAnyHadAccident === NO) {
        clearErrors("anyAccident");
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [watchAnyHadAccident, clearErrors, setError, form]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (watchHadLicenceCancelled === YES) {
        setError("hadLicenceCancelled", { message: "" });
        gtm(event("duty of disclosure licence"));
      } else if (watchHadLicenceCancelled === NO) {
        clearErrors("hadLicenceCancelled");
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [watchHadLicenceCancelled, clearErrors, setError, form]);

  const onSubmit = async (value: Values) => {
    try {
      setBackdrop(true);
      const request = createSaveQuoteRequest(
        value,
        query.get("promotion"),
        memberDetailsState,
        yourCaravanState,
        aboutYourCaravanState,
      );

      const response = await apiClient.savequote(request);

      const newPersonalInfo = {
        ...personalInformationSessionState,
      };
      if (newPersonalInfo.captureMembershipLevel) {
        newPersonalInfo.captureMembershipLevel =
          toDateString(value.yourDateOfBirth) === toDateString(state.yourDateOfBirth) &&
          personalInformationSessionState.captureMembershipLevel === true;
      }
      setPersonalInformationSessionState(newPersonalInfo);
      setQuoteSessionState({ ...quoteSessionState, quote: response.result });
      setQuoteNumber(response.result.quoteNumber);

      if (quoteSessionState && quoteSessionState.cover) {
        const cover = quoteSessionState.cover;
        const updateCoverResult = await apiClient.updatequotecover(cover);
        setQuoteSessionState({
          ...quoteSessionState,
          quote: {
            quoteNumber: quoteSessionState.quote.quoteNumber,
            startDate: quoteSessionState.quote.startDate,
            covers: [updateCoverResult.result],
          },
        });
      }

      setSessionState({ ...value, isCompleted: true });
      redirectToQuotePage();
    } catch (ex) {
      logException({
        location: "useAboutYou.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    data: {
      membershipLevels,
      isMultiMatch: memberDetailsState.isMultiMatch,
      hasMemberNotMatched: memberDetailsState.areYouAMember !== YES,
    },
    onSubmit,
  };
};

export default useAboutYou;
