import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { Model } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

interface UseModelsResult {
  models?: Model[];
  isError: boolean;
}

export const useLoadModels = (): ((manufacturer?: string | null, year?: number | null) => Promise<UseModelsResult>) => {
  const apiClient = useApiClient();

  const loadModels = async (manufacturer?: string | null, year?: number | null) => {
    try {
      if (manufacturer && year) {
        const { result } = await apiClient.getmodels(manufacturer, year);
        return { models: result, isError: false };
      } else {
        return { models: [], isError: false };
      }
    } catch (ex) {
      return { models: [], isError: true };
    }
  };

  return loadModels;
};

export const useModels = (manufacturer?: string | null, year?: number | null): UseModelsResult => {
  const loadModels = useLoadModels();
  const [isError, setIsError] = useState<boolean>(false);
  const [models, setModels] = useState<Model[]>();

  useEffect(() => {
    let isMounted = true;
    const getModels = async () => {
      const { models, isError } = await loadModels(manufacturer, year);
      if (isMounted) {
        setIsError(isError);
        setModels(models);
      }
    };
    getModels();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, year]);

  return { models, isError };
};

export default useModels;
