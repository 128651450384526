import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCheckHealth from "../../hooks/useCheckHealth";
import { UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../routing/routes.config";

const HealthCheck: React.FC = () => {
  const status = useCheckHealth();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === false) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    }
  }, [status, navigate]);
  return <></>;
};

export default HealthCheck;
