import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Grid, Link } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { useState } from "react";
import { CoverType, PaymentFrequency, PremiumDetails } from "../../hooks/useApiClient/ClientProxy.generated";
import PremiumBreakdownItem from "./PremiumBreakdownItem";
import PremiumBreakdownItemSummary from "./PremiumBreakdownItemSummary";
import { useStyles } from "./styles";

export interface PremiumBreakdownProps extends PremiumDetails {
  coverType: CoverType;
  frequency: PaymentFrequency;
  open?: boolean | undefined;
}

export const PremiumBreakdown: React.FC<PremiumBreakdownProps> = ({
  coverType,
  frequency,
  annual,
  monthly,
  open = false,
}) => {
  const coverTypeId = coverType.toLowerCase();
  const id = `link-${coverTypeId}-premium-breakdown`;
  const classes = useStyles;
  const [isOpen, setIsOpen] = useState(open);
  const icon = isOpen ? faSortUp : faSortDown;
  const isAnnual = frequency === PaymentFrequency.Annual;
  const { basic, gst, governmentCharges } = isAnnual ? annual : monthly;
  const action = isOpen ? "Hide" : "See";

  return (
    <>
      <Grid container sx={classes.link}>
        <Grid item xs={12}>
          <Link
            id={id}
            data-testid={id}
            variant="inherit"
            color="inherit"
            href="#"
            underline="none"
            onClick={(e: React.SyntheticEvent<Element, Event>) => {
              e.preventDefault();
              setIsOpen(!isOpen);
              gtm(event("Premium Breakdown"));
            }}
          >
            {action} quote breakdown
            <FontAwesomeIcon icon={icon} aria-hidden style={isOpen ? classes.iconUp : classes.iconDown} />
          </Link>
        </Grid>
      </Grid>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Grid container>
          <PremiumBreakdownItem coverType={coverType} label="Basic" value={basic} />
          <PremiumBreakdownItem coverType={coverType} label="Government charges" value={governmentCharges} />
          <PremiumBreakdownItem coverType={coverType} label="GST" value={gst} />
          <PremiumBreakdownItemSummary
            coverType={coverType}
            monthlyTotal={monthly.total}
            showCheaperText={monthly.total - annual.total > 0}
          />
        </Grid>
      </Collapse>
    </>
  );
};

export default PremiumBreakdown;
