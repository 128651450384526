import { Grid, Typography } from "@mui/material";
import { CoverType } from "../../hooks/useApiClient/ClientProxy.generated";
import format from "../CurrencyInput/format";
import { useStyles } from "./styles";

export interface PremiumBreakdownItemSummaryProps {
  coverType: CoverType;
  monthlyTotal: number;
  showCheaperText?: boolean;
}

export const PremiumBreakdownItemSummary: React.FC<PremiumBreakdownItemSummaryProps> = ({
  coverType,
  monthlyTotal,
  showCheaperText = false,
}) => {
  const classes = useStyles;
  const id = `${coverType.toLowerCase()}-premium-breakdown-additional-information`;
  const monthlyTotalId = `${id}-monthly-total`;
  return (
    <Grid item xs={12} sx={classes.summary}>
      <Typography variant="caption" id={id} data-testid={id} sx={classes.text}>
        {showCheaperText && "It is cheaper to pay annually. "}If you choose to pay monthly, your total annual price is{" "}
        <span id={monthlyTotalId} data-testid={monthlyTotalId}>
          {format(monthlyTotal, { withDecimals: true })}
        </span>
        . This includes government charges, GST and an instalment charge.
      </Typography>
    </Grid>
  );
};

export default PremiumBreakdownItemSummary;
