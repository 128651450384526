import { colors } from "@racwa/styles";

export const useStyles = {
  summaryTopHeading: {
    margin: "10px 0 10px 0",
  },
  dateOfBirth: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  address: {
    paddingTop: "15px",
    "& p": {
      padding: "0px",
    },
  },
  membershipLevel: {
    paddingTop: "15px",
    "& p": {
      padding: "0px",
    },
  },
  disabled: {
    color: colors.racGray,
  },
};
