import { useLogger, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { PaymentReferenceData } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";

export const useGetPaymentReferenceData = () => {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [isError, setIsError] = useState<boolean>(false);
  const [paymentReferenceData, setPaymentReferenceData] = useState<PaymentReferenceData>();

  useEffect(() => {
    let isCancelled = false;
    const getPaymentReferenceData = async () => {
      setBackdrop(true);
      try {
        setIsError(false);
        const response = await apiClient.getpaymentreferencedata();
        setPaymentReferenceData(response.result);
      } catch (ex) {
        logException({
          location: "useGetPaymentReferenceData.getPaymentReferenceData",
          message: ex,
          customProperties: customLogProperties,
        });
        setIsError(true);
      } finally {
        setBackdrop(false);
      }
    };

    if (!isCancelled && !isError && !paymentReferenceData) {
      getPaymentReferenceData();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentReferenceData, isError]);

  return { paymentReferenceData, isError };
};

export default useGetPaymentReferenceData;
