import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RacwaCardNotification } from "@racwa/react-components";
import React from "react";

export interface RoadblockNotificationCardProps {
  message: string | JSX.Element;
  show?: boolean | null;
}

const title = "Sorry, we've hit a roadblock";
const testId = "roadblockInfoCard";

export const RoadblockNotificationCard: React.FC<RoadblockNotificationCardProps> = ({
  message,
  show = true,
  ...props
}) =>
  show ? (
    <RacwaCardNotification
      {...props}
      severity="error"
      title={title}
      data-testid={testId}
      icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
    >
      {message}
    </RacwaCardNotification>
  ) : null;

export default RoadblockNotificationCard;
