import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { FormotivQuoteNumber, formotivQuoteNumber } from "../../atoms";
import QuoteDisclaimer from "../../shared/components/QuoteDisclaimer";
import WhatIsIncluded from "../../shared/components/WhatIsIncluded";
import useFormotiv from "../../shared/hooks/useFormotiv";
import FormotivDialog from "./components/FormotivDialog";
import GetPolicyButton from "./components/GetPolicyButton";
import Header from "./components/Header";
import QuoteCard from "./components/QuoteCard";
import { FormProps } from "./types";

declare global {
  interface Window {
    formotiv: any;
    formotivConfig: any;
  }
}

export const Form: React.FC<FormProps> = ({
  form,
  emailForm,
  values: {
    quote: {
      quoteNumber,
      covers: [cover],
    },
  },
  showFormotivPopup,
  onCloseFormotivPopup,
  onEmail,
  onSubmit,
  onQuoteCustomisation,
}) => {
  const onSubmitWrapper = useFormotiv(onSubmit);

  const [hasQuoteNumberBeenPreviouslyPushedToFormotiv, setPushQuoteNumberState] =
    useRecoilState<FormotivQuoteNumber>(formotivQuoteNumber);

  useEffect(() => {
    if (window.formotiv && !hasQuoteNumberBeenPreviouslyPushedToFormotiv.hasBeenPushed) {
      window.formotivConfig.state.ptv1 = quoteNumber;
      setPushQuoteNumberState({ hasBeenPushed: true });
    }
  });

  return quoteNumber && cover ? (
    <>
      <Grid container spacing={1} data-testid="yourQuoteForm">
        <Grid item xs={12}>
          <Header quoteNumber={quoteNumber} />
        </Grid>
        <Grid item xs={12}>
          <QuoteCard
            {...cover}
            form={form}
            emailForm={emailForm}
            onEmail={onEmail}
            onSubmit={onSubmitWrapper}
            onQuoteCustomisation={onQuoteCustomisation}
          />
        </Grid>
        <Grid item xs={12}>
          <WhatIsIncluded />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: `0px` }}>
          <GetPolicyButton
            coverType={cover.type}
            onClick={form.handleSubmit((values) => onSubmitWrapper({ ...values, type: cover.type }))}
          />
        </Grid>
        <Grid item xs={12}>
          <QuoteDisclaimer showOnlineDiscountDisclaimer={false} />
        </Grid>
      </Grid>
      <FormotivDialog open={showFormotivPopup} onCloseFormotivDialog={onCloseFormotivPopup} />
    </>
  ) : null;
};

export default Form;
