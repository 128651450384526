import { useBsbLookup, useGetSessionState, useSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLookupApiClient } from "../../../../shared/hooks/useApiClient";
import { PaymentFrequency, PaymentMethod } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { Values as YourQuoteValues } from "../../../YourQuote/types";
import BankPayment, { OnSubmitBankAccountEvent } from "../../components/BankAccount/BankPayment";
import { FormProps, PaymentData, Values } from "../../types";
import useWestpacScript from "../useWestpacScript";

export const usePayment = (): FormProps => {
  const [sessionState] = useSessionState<Values>();
  const yourQuoteState = useGetSessionState<YourQuoteValues>(QuoteRoutes.YourQuote);
  const [, setDisplayAuthorisationCard] = useState<boolean>(false);
  const { onSubmit: onSubmitBankAccount, isError: isBankAccountError } = BankPayment();
  const paymentData = useGetSessionState<PaymentData>(PolicyRoutes.Payment);

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...sessionState,
      frequency: yourQuoteState.cover?.frequency || null,
      method: paymentData.annualCCPaymentInProgress ? PaymentMethod.Card : null,
    },
  });

  const { watch } = form;
  const method = watch("method") as PaymentMethod;
  const frequency = watch("frequency") as PaymentFrequency;
  const [showTryAgainDialog, setShowTryAgainDialog] = useState(false);

  const {
    showAuthorisationCard,
    isFormFilledOut,
    isError: CreditCardError,
    ...westpacScriptProps
  } = useWestpacScript(
    method === PaymentMethod.Card,
    frequency === PaymentFrequency.Monthly,
    (openTryAgainDialog: boolean) => setShowTryAgainDialog(openTryAgainDialog),
  );

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setDisplayAuthorisationCard((method != null && frequency != null) || false);
    }

    return () => {
      unmounted = true;
    };
  }, [method, frequency]);

  const onSubmit = async (values: Values) => {
    if (values.method === PaymentMethod.Bank_account) {
      const bankFrom = form.getValues();
      if (bankFrom.bsb && bankFrom.accountNumber && bankFrom.accountName) {
        await onSubmitBankAccount({
          bsb: values.bsb,
          accountName: values.accountName,
          accountNumber: values.accountNumber,
          frequency: frequency as PaymentFrequency,
          method: PaymentMethod.Bank_account,
        } as OnSubmitBankAccountEvent);
      }
    } else if (values.method === PaymentMethod.Card) {
      await westpacScriptProps.onSubmitCreditCard();
    }
  };

  const onDoneClick = () => {
    westpacScriptProps.onDoneClick();
  };

  const onTryAgainClick = () => {
    westpacScriptProps.onTryAgainClick();
  };

  const lookupApiClient = useLookupApiClient();
  const { onBsbLookup } = useBsbLookup({ apiClient: lookupApiClient });

  return {
    data: {
      hasError: CreditCardError || isBankAccountError,
      premiumDetails: yourQuoteState?.quote?.covers[0]?.premiumDetails || { annual: {}, monthly: {} },
      trustedFrame: westpacScriptProps.trustedFrame,
      displayAuthorisationCard: showAuthorisationCard,
    },
    form,
    lockField: westpacScriptProps.isEditLocked,
    openTryAgainDialog: showTryAgainDialog,
    onSubmit,
    onDoneClick,
    onTryAgainClick,
    onBsbLookup,
  };
};
export default usePayment;
