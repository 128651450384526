import { faCertificate, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import useStyles from "./styles";

export interface DiscountItemProps {
  id: string;
  text: string;
}

export const DiscountItem: React.FC<DiscountItemProps> = ({ id, text }) => {
  const classes = useStyles;
  const innerId = `discount-${id}`;
  return (
    <Grid item xs={12}>
      <FontAwesomeIcon icon={faCheck} mask={faCertificate} transform="shrink-8" aria-hidden style={classes.icon} />
      <Typography id={innerId} data-testid={innerId} variant="caption" sx={classes.text}>
        {text}
      </Typography>
    </Grid>
  );
};

export default DiscountItem;
