import YesNoButtonGroup from "../../../../shared/components/YesNoButtonGroup";

const inputId = "addAdditionalPolicyholder";
const inputContainerId = `${inputId}-container`;
const label = "Do you want to add a joint policyholder?";
const subTitle = "This could be someone who tows the caravan regularly or manages the policy.";

export const AddPolicyholder: React.FC = () => {
  return (
    <YesNoButtonGroup id={inputId} data-testid={inputContainerId} label={label} subtitle={subTitle} name={inputId} />
  );
};

export default AddPolicyholder;
