import { DateOfBirthInput, DateOfBirthInputProps } from "raci-react-library";
import React from "react";
export interface YourDateOfBirthProps extends DateOfBirthInputProps {}

const YourDateOfBirth: React.FC<YourDateOfBirthProps> = (props) => {
  const label = "Your date of birth";
  const id = "yourDateOfBirth";

  return <DateOfBirthInput {...props} label={label} id={id} name={id} />;
};

export default YourDateOfBirth;
