import {
  HTTP_STATUS_CODE_INTERNAL_SERVER,
  HTTP_STATUS_CODE_NOT_FOUND,
  HTTP_STATUS_CODE_TOO_MANY_REQUESTS,
  useLogger,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { quoteNumberAtom } from "../../../../atoms";
import { NO, YES } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  PaymentFrequency,
  RetrieveQuoteResponse,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCreateSession from "../../../../shared/hooks/useCreateSession";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  PolicyRoutes,
  QuoteRoutes,
  QUOTE_YOUR_QUOTE_PAGE_URL,
  UtilityRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { Values as AboutYourValues } from "../../../AboutYou/types";
import { Values as AboutYourCaravanValues } from "../../../AboutYourCaravan/types";
import { Values as JointPolicyholderInformationValues } from "../../../JointPolicyHolderInformation/types";
import { Values as MemberDetailsValues } from "../../../MemberDetails/types";
import { Values as PersonalInformationValues } from "../../../PersonalInformation/types";
import { useLoadCaravans } from "../../../YourCaravan/hooks/useCaravans";
import { useLoadModels } from "../../../YourCaravan/hooks/useModels";
import { Values as YourCaravanValues } from "../../../YourCaravan/types";
import { Values as YourQuoteValues } from "../../../YourQuote/types";
import { FormProps, Values } from "../../types";

const defaultMemberDetailsSelection = "skip";

export const useRetrieveQuote = (): FormProps => {
  useCreateSession();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const setBackdrop = useSetBackdrop();
  const setQuoteNumber = useSetRecoilState(quoteNumberAtom);
  const [cantFindQuote, setCantFindQuote] = useState<boolean>(false);
  const [attemptsExhausted, setAttemptsExhausted] = useState<boolean>(false);
  const { manufacturers, suburbs, overnightParkingOptions } = useReferenceData();

  const [, setMemberDetailsState] = useSessionState<MemberDetailsValues>({
    specificKey: QuoteRoutes.MemberDetails,
    skipPageTrackingRecalculation: true,
  });

  const [, setAboutYouState] = useSessionState<AboutYourValues>({
    specificKey: QuoteRoutes.AboutYou,
    skipPageTrackingRecalculation: true,
  });

  const [, setAboutYourCaravanState] = useSessionState<AboutYourCaravanValues>({
    specificKey: QuoteRoutes.AboutYourCaravan,
    skipPageTrackingRecalculation: true,
  });

  const [, setYourCaravanState] = useSessionState<YourCaravanValues>({
    specificKey: QuoteRoutes.YourCaravan,
    skipPageTrackingRecalculation: true,
  });

  const [yourQuoteState, setYourQuoteState] = useSessionState<YourQuoteValues>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  const [, setYourPersonalInformationState] = useSessionState<PersonalInformationValues>({
    specificKey: PolicyRoutes.PersonalInformation,
    skipPageTrackingRecalculation: true,
  });

  const [, setJointPolicyholderInformationState] = useSessionState<JointPolicyholderInformationValues>({
    specificKey: PolicyRoutes.JointPolicyholder,
    skipPageTrackingRecalculation: true,
  });

  const [retrieveQuoteState, setRetrieveQuoteState] = useSessionState<Values>({
    specificKey: UtilityRoutes.RetrieveQuote,
    skipPageTrackingRecalculation: true,
  });

  const getModels = useLoadModels();
  const getCaravans = useLoadCaravans();

  const SetSessionState = async (retrievedQuote: RetrieveQuoteResponse) => {
    if (retrievedQuote) {
      setMemberDetailsState({
        areYouAMember: defaultMemberDetailsSelection,
        isCompleted: true,
      });

      setAboutYouState({
        yourDateOfBirth: retrievedQuote.mainPolicyholder.dateOfBirth,
        addAdditionalPolicyholder: retrievedQuote.hasAdditionalPolicyholder ? YES : NO,
        additionalPolicyholderDateOfBirth: retrievedQuote.additionalPolicyholder?.dateOfBirth,
        anyAccident: NO,
        hadLicenceCancelled: NO,
        isCompleted: true,
      });

      setAboutYourCaravanState({
        registrationNumber: retrievedQuote.asset.registrationNumber,
        isBusinessOrCommercialUsage: NO,
        suburb: suburbs.find(
          (x) =>
            x.postcode === retrievedQuote.asset.postcode.toString() &&
            x.name.toLowerCase() === retrievedQuote.asset.suburb.toLowerCase(),
        ),
        overnightParking: overnightParkingOptions.find((x) => x.description === retrievedQuote.asset.keptLocation)
          ?.description,
        isCompleted: true,
      });

      const year = retrievedQuote.asset.year;
      const manufacturer = manufacturers.find((x) => x.code === retrievedQuote.asset.manufacturerId);
      const { models } = await getModels(manufacturer?.code, year);
      const model = models?.find((x) => x.code === retrievedQuote.asset.modelId);
      const { caravans } = await getCaravans(manufacturer?.code, year, model?.code);
      const caravan = caravans?.find((x) => x.id === retrievedQuote.asset.vehicleId);

      setYourCaravanState({
        year: year,
        model: model,
        manufacturer: manufacturer,
        marketValue: retrievedQuote.asset.marketValue === 0.0 ? retrievedQuote.covers[0].sumInsured.value : null,
        caravan: caravan,
        isCompleted: true,
      });

      setYourQuoteState({
        quote: {
          ...yourQuoteState.quote,
          quoteNumber: retrievedQuote.quoteNumber,
          startDate: retrievedQuote.startDate,
          covers: retrievedQuote.covers,
        },
        cover: {
          frequency: retrievedQuote.paymentFrequency ?? PaymentFrequency.Annual,
          excess: retrievedQuote.covers[0].excess.value,
          sumInsured: retrievedQuote.covers[0].sumInsured.value,
          type: retrievedQuote.covers[0].type,
          email: retrievedQuote.mainPolicyholder.email ?? "",
        },
        isCompleted: true,
      });

      setYourPersonalInformationState({
        title: retrievedQuote.mainPolicyholder.title ?? "",
        firstName: retrievedQuote.mainPolicyholder.firstName ?? "",
        lastName: retrievedQuote.mainPolicyholder.surname ?? "",
        contactNumber: retrievedQuote.mainPolicyholder.contactNumber ?? "",
        email: retrievedQuote.mainPolicyholder.email ?? "",
      });

      setJointPolicyholderInformationState({
        title: retrievedQuote.additionalPolicyholder?.title ?? "",
        firstName: retrievedQuote.additionalPolicyholder?.firstName ?? "",
        lastName: retrievedQuote.additionalPolicyholder?.surname ?? "",
        contactNumber: retrievedQuote.additionalPolicyholder?.contactNumber ?? "",
        email: retrievedQuote.additionalPolicyholder?.email ?? "",
      });
    }
  };

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...retrieveQuoteState,
    },
  });

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const redirectToYourQuotePage = () => {
    navigate(QUOTE_YOUR_QUOTE_PAGE_URL);
  };

  const onSubmit = async (values: Values) => {
    try {
      setBackdrop(true);
      setAttemptsExhausted(false);
      setCantFindQuote(false);
      const response = await apiClient.retrievequote(
        values.quoteNumber ?? "",
        values.suburb?.name ?? "",
        values.suburb?.postcode ? +values.suburb?.postcode : 0,
      );
      setRetrieveQuoteState(values);
      setQuoteNumber(response.result.quoteNumber);
      await SetSessionState(response.result);
      redirectToYourQuotePage();
    } catch (ex) {
      logException({
        location: "useRetrieveQuote.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      const errorResponse = ex as ApiException;
      setAttemptsExhausted(errorResponse.status === HTTP_STATUS_CODE_TOO_MANY_REQUESTS);
      setCantFindQuote(errorResponse.status === HTTP_STATUS_CODE_NOT_FOUND);

      if (errorResponse.status === HTTP_STATUS_CODE_INTERNAL_SERVER) {
        redirectToSystemUnavailable();
      }
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    cantFindQuote,
    attemptsExhausted,
    onSubmit,
  };
};

export default useRetrieveQuote;
