import { Box, Link, Typography } from "@mui/material";
import { theme } from "@racwa/react-components";

const useStyles = {
  root: {
    padding: "0px 0px 24px 13px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      padding: "0px 0px 30px 13px",
    },
  },
  inline: {
    fontWeight: 400,
  },
};

export interface ProductDisclosureStatementProps {
  preamble?: string;
  showAbbreviation?: boolean;
  showInline?: boolean;
}

const ProductDisclosureStatement: React.FC<ProductDisclosureStatementProps> = ({
  preamble,
  showAbbreviation = true,
  showInline = true,
}) => {
  const classes = useStyles;
  const className = showInline ? classes.inline : classes.root;
  const variantName = showInline ? "body2" : "h4";
  const preambleText = preamble ? `${preamble} ` : null;

  const PdsLink = () => {
    return (
      <Link
        id="pdsLink"
        data-testid="pdsLink"
        variant="inherit"
        color="inherit"
        href={process.env.REACT_APP_RAC_PDS_URL}
        target="_blank"
        rel="noreferrer noopener"
      >
        Product Disclosure Statement {showAbbreviation === true ? " (PDS)" : null}
      </Link>
    );
  };

  return showInline ? (
    <strong>
      {preambleText}
      <PdsLink />
    </strong>
  ) : (
    <Box sx={className}>
      <Typography variant={variantName} sx={className}>
        {preambleText}
        <PdsLink />
      </Typography>
    </Box>
  );
};

export default ProductDisclosureStatement;
