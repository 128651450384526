import { useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import useApiClient from "../useApiClient";
import { Manufacturer, ReferenceDataOption, Suburb } from "../useApiClient/ClientProxy.generated";

const manufacturersListAtom = atom<Manufacturer[]>({
  key: "ManufacturersList",
  default: [],
});

const suburbsListAtom = atom<Suburb[]>({
  key: "SuburbsList",
  default: [],
});

const overnightParkingAtom = atom<ReferenceDataOption[]>({
  key: "OvernightParkingList",
  default: [],
});

const membershipLevelsAtom = atom<ReferenceDataOption[]>({
  key: "MembershipLevelsList",
  default: [],
});

export const useLoadableReferenceData = () => {
  const apiClient = useApiClient();
  const [manufacturersLoadable, setManufacturersLoadable] = useRecoilState(manufacturersListAtom);
  const [suburbsLoadable, setSuburbsLoadable] = useRecoilState(suburbsListAtom);
  const [overnightParkingLoadable, setOvernightParkingLoadable] = useRecoilState(overnightParkingAtom);
  const [membershipLevelsLoadable, setMembershipLevelsLoadable] = useRecoilState(membershipLevelsAtom);

  useEffect(() => {
    let isManufacturersListMounted = true;
    const loadManufacturers = async () => {
      const { result } = await apiClient.getmanufacturers();
      if (isManufacturersListMounted) {
        setManufacturersLoadable(result);
      }
    };

    loadManufacturers();

    return () => {
      isManufacturersListMounted = false;
    };
  }, [apiClient, setManufacturersLoadable]);

  useEffect(() => {
    let isSuburbsListMounted = true;
    const loadSuburbs = async () => {
      const { result } = await apiClient.getsuburbs();
      if (isSuburbsListMounted) {
        setSuburbsLoadable(result);
      }
    };

    loadSuburbs();

    return () => {
      isSuburbsListMounted = false;
    };
  }, [apiClient, setSuburbsLoadable]);

  useEffect(() => {
    let isOvernightParkingMounted = true;
    const loadOvernightParking = async () => {
      const { result } = await apiClient.getovernightparkingoptions();
      if (isOvernightParkingMounted) {
        setOvernightParkingLoadable(result);
      }
    };

    loadOvernightParking();

    return () => {
      isOvernightParkingMounted = false;
    };
  }, [apiClient, setOvernightParkingLoadable]);

  useEffect(() => {
    let isMembershipLevelsMounted = true;
    const loadMembershipLevels = async () => {
      const { result } = await apiClient.getmembershiplevels();
      if (isMembershipLevelsMounted) {
        setMembershipLevelsLoadable(result);
      }
    };

    loadMembershipLevels();

    return () => {
      isMembershipLevelsMounted = false;
    };
  }, [apiClient, setMembershipLevelsLoadable]);

  return {
    manufacturersLoadable,
    suburbsLoadable,
    overnightParkingLoadable,
    membershipLevelsLoadable,
  };
};

export const useReferenceData = () => {
  const manufacturers = useRecoilValue(manufacturersListAtom);
  const suburbs = useRecoilValue(suburbsListAtom);
  const overnightParkingOptions = useRecoilValue(overnightParkingAtom);
  const membershipLevels = useRecoilValue(membershipLevelsAtom);

  return {
    manufacturers,
    suburbs,
    overnightParkingOptions,
    membershipLevels,
  };
};

export default useReferenceData;
