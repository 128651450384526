import { MenuItem } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { isMobileOnly } from "react-device-detect";
import { Controller, useFormContext, type RegisterOptions } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/HelperText";
import { ReferenceDataOption } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { trackCustomFormotivInput } from "../../../../shared/hooks/useFormotiv";

const inputId = "select-overnight-parking";
const inputContainerId = `${inputId}-container`;
const label = "Where is your caravan usually parked?";

export interface SelectWhereCaravanUsuallyKeptProps {
  options?: ReferenceDataOption[];
  validate?: RegisterOptions["validate"];
  disabled?: boolean;
}

export const SelectWhereCaravanUsuallyKept: React.FC<SelectWhereCaravanUsuallyKeptProps> = ({
  options,
  validate,
  disabled,
}) => {
  const { control } = useFormContext();

  const overnightParkingOptions =
    options &&
    options.map((option) => {
      const optionProps = {
        id: option.externalCode,
        key: option.externalCode,
        value: option.externalCode,
        "data-testid": `overnightParkingOptionTest${option.externalCode}`,
      };
      return isMobileOnly ? (
        <option {...optionProps}>{option.description}</option>
      ) : (
        <MenuItem {...optionProps}>{option.description}</MenuItem>
      );
    });

  if (overnightParkingOptions && isMobileOnly) {
    overnightParkingOptions.unshift(
      <option key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </option>,
    );
  } else if (overnightParkingOptions && !isMobileOnly) {
    overnightParkingOptions.unshift(
      <MenuItem key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </MenuItem>,
    );
  }

  return (
    <Controller
      control={control}
      name="overnightParking"
      aria-label={label}
      rules={{
        required: { value: true, message: RequiredSelection },
        validate,
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, value, ...props }, fieldState: { error } }) => (
        <RacwaSelect
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label}
          aria-label={label}
          native={isMobileOnly}
          fullWidth
          displayEmpty
          disabled={disabled}
          onChange={(e) => {
            const overnightParking = e.target.value as string;
            trackCustomFormotivInput("overnightParking", "autocomplete", overnightParking);
            onComponentChange && onComponentChange(overnightParking);
          }}
          onBlur={() => {
            gtm(fieldTouched(label));
            onBlur && onBlur();
          }}
          value={value ?? ""}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
        >
          {overnightParkingOptions}
        </RacwaSelect>
      )}
    />
  );
};

export default SelectWhereCaravanUsuallyKept;
