import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_DD_MM_YYYY, YES } from "../../../../../../shared/constants";
import { MembershipTier, Title } from "../../../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PolicyRoutes,
  POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL,
  POLICY_JOINT_POLICYHOLDER_PAGE_URL,
  QuoteRoutes,
  QUOTE_ABOUT_YOU_PAGE_URL,
} from "../../../../../../shared/routing/routes.config";
import { Values as AboutYouValues } from "../../../../../AboutYou/types";
import { Values as JointPolicyholderValues } from "../../../../../JointPolicyHolderInformation/types";
import { Values as PersonalInformationValues } from "../../../../../PersonalInformation/types";
import { Values as MembershipLevelValues } from "../../../../../PolicyholderMembershipLevel/types";
import SummaryItem from "../SummaryItem";
import { useStyles } from "./styles";

const componentTestId = "policySummary-aboutJointPolicyHolder";

const isGenderVisible = (title: string) => {
  return title === Title.Dr || title === Title.Mx;
};

const getDateOfBirth = (dateOfBirth?: Date | null) => {
  return dateOfBirth ? format(new Date(dateOfBirth), DATE_FORMAT_DD_MM_YYYY) : null;
};

const getMembershipLevel = (membership?: string | null) => {
  if (!membership) {
    return null;
  } else if (membership === MembershipTier.NotAMember) {
    return "No RAC membership";
  } else if (membership === MembershipTier.NotSure) {
    return "Not sure of membership level";
  } else {
    return `${membership} membership level`;
  }
};
const AboutJointPolicyholderContent: React.FC = () => {
  const aboutYouState = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const dateOfBirth = getDateOfBirth(aboutYouState.additionalPolicyholderDateOfBirth);

  return (
    <>
      <Typography variant="body2" data-testid={`${componentTestId}-jointPolicyHolder`}>
        Yes, joint policyholder
      </Typography>
      {dateOfBirth && (
        <Typography variant="body2" data-testid={`${componentTestId}-dateOfBirth`}>
          Date of birth {dateOfBirth}
        </Typography>
      )}
    </>
  );
};

const JointPolicyholderInformationContent: React.FC = () => {
  const jointPolicyholderInformationState = useGetSessionState<JointPolicyholderValues>(PolicyRoutes.JointPolicyholder);
  const personalInformationState = useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);

  return (
    <>
      <Typography variant="body2" data-testid={`${componentTestId}-fullName`}>
        {jointPolicyholderInformationState.title} {jointPolicyholderInformationState.firstName}{" "}
        {jointPolicyholderInformationState.lastName}
      </Typography>
      {isGenderVisible(jointPolicyholderInformationState.title ?? "") && (
        <Typography variant="body2" data-testid={`${componentTestId}-gender`}>
          {jointPolicyholderInformationState.gender}
        </Typography>
      )}
      <Typography variant="body2" data-testid={`${componentTestId}-contactNumber`}>
        {jointPolicyholderInformationState.contactNumber}
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-email`}>
        {jointPolicyholderInformationState.email}
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-mailingAddress`}>
        {jointPolicyholderInformationState.mailAddressSameAsPrimary === YES
          ? personalInformationState.mailingAddress?.displayAddress
          : jointPolicyholderInformationState.mailingAddress?.displayAddress}
      </Typography>
    </>
  );
};

const MembershipLevelContent: React.FC = () => {
  const jointMembershipLevelState = useGetSessionState<MembershipLevelValues>(PolicyRoutes.JointMembershipLevel);

  return (
    <Typography variant="body2" data-testid={`${componentTestId}-membershipLevel`}>
      {getMembershipLevel(jointMembershipLevelState.membershipLevel)}
    </Typography>
  );
};

export const JointPolicyholderSummaryItem: React.FC = () => {
  const classes = useStyles;
  const aboutYouState = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const jointPolicyholderInformationState = useGetSessionState<JointPolicyholderValues>(PolicyRoutes.JointPolicyholder);
  const navigate = useNavigate();
  const redirectToJointPolicyholderInformationPage = () => {
    navigate(POLICY_JOINT_POLICYHOLDER_PAGE_URL);
  };
  const redirectToAboutYouPage = () => {
    navigate(QUOTE_ABOUT_YOU_PAGE_URL);
  };
  const redirectToJointMembershipLevelPage = () => {
    navigate(POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL);
  };

  const isMemberMatched =
    aboutYouState.addAdditionalPolicyholder === YES && jointPolicyholderInformationState.captureMembershipLevel;

  return (
    <Grid container alignItems="center" data-testid="jointPolicyholderDetailsItem">
      <Grid item xs={12}>
        <SummaryItem title="Joint policyholder details" name="AboutYou" onClick={redirectToAboutYouPage}>
          <AboutJointPolicyholderContent />
        </SummaryItem>
      </Grid>
      <Grid item xs={12} sx={classes.sectionSpacing}>
        <SummaryItem name="JointPolicyholder" onClick={redirectToJointPolicyholderInformationPage}>
          <JointPolicyholderInformationContent />
        </SummaryItem>
      </Grid>
      {isMemberMatched && (
        <Grid item xs={12} sx={classes.sectionSpacing}>
          <SummaryItem name="JointMembershipLevel" onClick={redirectToJointMembershipLevelPage}>
            <MembershipLevelContent />
          </SummaryItem>
        </Grid>
      )}
    </Grid>
  );
};

export default JointPolicyholderSummaryItem;
