import { FormControl, FormLabel, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { Controller, useFormContext } from "react-hook-form";

const label = "Are you an RAC member?";
const labelId = "formlabelAreYouAnRACMember";
const inputId = "areYouAMemberButtonGroup";

export interface AreYouAMemberButtonGroupProps {
  disabled?: boolean;
  onChange?: () => void;
}

const AreYouAMemberButtonGroup: React.FC<AreYouAMemberButtonGroupProps> = ({ disabled, onChange }) => {
  const { control } = useFormContext();
  const name = "areYouAMember";
  return (
    <FormControl disabled={disabled}>
      <FormLabel id={labelId} data-testid={labelId}>
        {label}
        <Typography variant="body2" sx={{marginTop: "5px",marginBottom: "5px",fontSize: "1rem"}}>
          We'll use this to pre-fill your details and apply eligible discounts.
        </Typography>
      </FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onBlur, onChange: onComponentChange, ...props } }) => (
          <ToggleButtonGroup
            {...props}
            onChange={(_, value) => {
              onComponentChange(value);
              gtm(fieldTouched(label));
              gtm(event(value));

              const inputNameSuffix = value ?? props.value;
              window.formotiv &&
                window.formotiv.inputActivity(
                  window.formotivConfig,
                  `${name}_${inputNameSuffix[0]}`,
                  "buttongroup",
                  value,
                );

              onChange && onChange();
            }}
            onBlur={() => {
              onBlur();
            }}
            exclusive
            id={inputId}
            data-testid={inputId}
            aria-labelledby="areYouAMemberLabel"
          >
            <ToggleButton type="button" role="button" key="yes" value="yes" data-testid="yesButton" disabled={disabled}>
              Yes
            </ToggleButton>
            <ToggleButton type="button" role="button" key="no" value="no" data-testid="noButton" disabled={disabled}>
              No
            </ToggleButton>
            <ToggleButton
              type="button"
              role="button"
              key="skipButton"
              value="skip"
              data-testid="skipButton"
              disabled={disabled}
            >
              Skip
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      />
    </FormControl>
  );
};

export default AreYouAMemberButtonGroup;
