import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { quoteNumberAtom, sessionIdAtom } from "../../../atoms";

/**
 * Add custom properties that will help triage issues.
 * See @microsoft/applicationinsights-common/types/Interfaces/IPartC
 * @returns dictionary containing additional custom properties to log ApplicationInsights as CustomDimensions
 */
const useCustomLogProperties = () => {
  const quoteNumber = useRecoilValue(quoteNumberAtom);
  const sessionId = useRecoilValue(sessionIdAtom);

  const customLogProperties: { [key: string]: string } = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    customLogProperties["Quote Number"] = quoteNumber;
    customLogProperties["Session ID"] = sessionId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, quoteNumber]);

  return customLogProperties;
};

export default useCustomLogProperties;
