import { Collapse } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { CoverType, EmailQuoteRequest } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import EmailThisQuoteButton from "./EmailThisQuoteButton";
import SendQuoteButton from "./SendQuoteButton";
import { EmailInput } from "raci-react-library";

export interface EmailQuoteFormProps {
  coverType: CoverType;
  open?: boolean | undefined;
  onSubmit: (value: EmailQuoteRequest) => Promise<void>;
}

export const EmailQuoteForm: React.FC<EmailQuoteFormProps> = ({ coverType, open = false, onSubmit }) => {
  const { handleSubmit, getValues } = useFormContext();
  const coverTypeId = coverType.toLowerCase();
  const emailId = `input-${coverTypeId}-email`;
  const [isOpen, setIsOpen] = useState<boolean>(open);

  return (
    <>
      <EmailThisQuoteButton coverType={coverType} onClick={() => setIsOpen(!isOpen)} open={isOpen} />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <EmailInput id={emailId} />
        <SendQuoteButton
          coverType={coverType}
          onClick={handleSubmit(async () => {
            const request = {
              ...getValues(),
              type: coverType,
            };
            await onSubmit(request as EmailQuoteRequest);
            setIsOpen(false);
          })}
        />
      </Collapse>
    </>
  );
};

export default EmailQuoteForm;
