import { Grid, Typography } from "@mui/material";
import React from "react";
import { FormProvider } from "react-hook-form";
import { CallToActionButton } from "../../shared/components/CallToActionButon";
import SelectSuburb from "../../shared/components/SelectSuburb";
import useFormotiv from "../../shared/hooks/useFormotiv";
import CaravanRegistrationInput from "./components/CaravanRegistrationInput";
import OnsiteInformationCard from "./components/OnsiteInformationCard";
import SelectIsBusinessOrCommercialUsage from "./components/SelectIsBusinessOrCommercialUsage";
import SelectWhereCaravanUsuallyKept from "./components/SelectWhereCaravanUsuallyKept";
import { FormProps } from "./types";

export const Form: React.FC<FormProps> = ({ form, data, onSubmit }) => {
  const suburbInputLabel = "What suburb is your caravan usually kept in?";
  const onSubmitWrapper = useFormotiv(onSubmit);

  const validateParkingAndSuburb = {
    valid: () => data.hasParkingSelectionAndSuburbValidationFailed === false,
  };

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Tell us more about your {data?.caravan?.manufacturer}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <CaravanRegistrationInput />
          </Grid>
          <Grid item xs={12}>
            <SelectIsBusinessOrCommercialUsage />
          </Grid>
          <Grid item xs={12}>
            <SelectSuburb
              options={data.suburbs}
              label={suburbInputLabel}
              validate={validateParkingAndSuburb}
              disabled={data.isBusinessOrCommercialUsage}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectWhereCaravanUsuallyKept
              options={data.overnightLocations}
              validate={validateParkingAndSuburb}
              disabled={data.isBusinessOrCommercialUsage}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={data.hasParkingSelectionAndSuburbValidationFailed ? { display: "block" } : { display: "none" }}
          >
            <OnsiteInformationCard />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton type="submit" data-testid="submit" color="primary" variant="contained" fullWidth={true}>
              Next
            </CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
