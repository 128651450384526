import { event, gtm } from "@racwa/analytics";
import {
  HTTP_STATUS_CODE_OK,
  useGetSessionState,
  useLogger,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { FormotivPopupState, formotivPopupState } from "../../../../atoms";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  CaravanCover,
  EmailQuoteRequest,
  FormotivModelRequest,
  PaymentFrequency,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useSetBackdropMessage from "../../../../shared/hooks/useBackdropMessage";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import {
  POLICY_START_DATE_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  UtilityRoutes,
} from "../../../../shared/routing/routes.config";
import { Values as MemberDetailsValues } from "../../../MemberDetails/types";
import { Values as RetrieveQuoteValues } from "../../../RetrieveQuote/types";
import { Values as YourCaravanValues } from "../../../YourCaravan/types";
import { CoverValues, FormProps, Values } from "../../types";

export const useYourQuote = (): FormProps => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const setBackdropMessage = useSetBackdropMessage();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const memberDetailsState = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const retrieveQuoteState = useGetSessionState<RetrieveQuoteValues>(UtilityRoutes.RetrieveQuote);
  const [initialState, setState] = useSessionState<Values>();
  const [yourCaravanState, setYourCaravanState] = useSessionState<YourCaravanValues>({
    specificKey: QuoteRoutes.YourCaravan,
    skipPageTrackingRecalculation: true,
  });
  const [showFormotivPopup, setShowFormotivPopup] = useState(false);
  const [formotivDisplayedState, setFormotivDisplayedState] = useRecoilState<FormotivPopupState>(formotivPopupState);
  const covers = initialState.quote?.covers || [];
  const isFormotivPopupEnabled = process.env.REACT_APP_FORMOTIV_POPUP_ENABLED === "true";
  const isFormotivTestMode = process.env.REACT_APP_FORMOTIV_POPUP_TESTING === "true";
  const hasComeFromRetrieveQuotePage = retrieveQuoteState?.quoteNumber !== undefined;

  const updateQuoteCover = async (value: CoverValues, isCompleted: boolean) => {
    const result = await apiClient.updatequotecover({ ...value });
    setState({
      ...initialState,
      quote: {
        quoteNumber: initialState?.quote?.quoteNumber,
        startDate: initialState?.quote?.startDate,
        covers: [result.result],
      },
      cover: { ...value },
      isCompleted: isCompleted,
    });
  };

  let excess = 0,
    sumInsured = 0,
    contents = 0;

  if (covers.length > 0) {
    excess = covers[0].excess.value;
    sumInsured = covers[0].sumInsured.value;
    contents = (covers[0] as CaravanCover).contents?.value || 0;
  }
  const savedCoverState = initialState.cover;
  const defaultValues = {
    email: savedCoverState?.email ?? memberDetailsState.email ?? undefined,
    frequency: savedCoverState?.frequency || PaymentFrequency.Annual,
    excess: savedCoverState?.excess || excess,
    sumInsured: savedCoverState?.sumInsured || sumInsured,
    contents: savedCoverState?.contents || contents,
  };

  const form = useForm<CoverValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const emailForm = useForm<EmailQuoteRequest>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues,
  });

  const { trigger, reset } = form;

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const redirectToFurtherDetailsPage = () => {
    navigate(POLICY_START_DATE_PAGE_URL);
  };

  const hasGlassesGuideMarketValue = () => {
    return yourCaravanState.caravan?.marketValue;
  };

  const getFormotivModelRanking = async (request: FormotivModelRequest) => {
    try {
      const response = await apiClient.formotivmodel(request);
      const modelRanking =
        response.status === HTTP_STATUS_CODE_OK && response.result.modelData ? response.result.modelData.ranking : -1;
      return modelRanking;
    } catch {
      return -1;
    }
  };

  const calculatePopupVisible = async () => {
    let shouldShowPopup = false;

    if (isFormotivTestMode) {
      shouldShowPopup = true;
    } else {
      const request: FormotivModelRequest = {
        ModelId: process.env.REACT_APP_FORMOTIV_MODEL_ID || "",
        MultiPageToken: window.formotivConfig.getValue("state.multipagetoken") || null,
      };

      const modelRanking = await getFormotivModelRanking(request);

      const isModelRankingValid = modelRanking === 1 || modelRanking === 2;
      const randomValue = Math.random();

      shouldShowPopup = isModelRankingValid && randomValue > 0.2;
    }

    setShowFormotivPopup(shouldShowPopup);
    window.formotivConfig.state.ptv5 = shouldShowPopup ? "Y" : "N";
    window.formotiv.submit(window.formotivConfig);
  };

  useEffect(
    () => {
      if (
        !hasComeFromRetrieveQuotePage &&
        isFormotivPopupEnabled &&
        !formotivDisplayedState.hasBeenPreviouslyDisplayed
      ) {
        calculatePopupVisible();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseFormotivPopup = () => {
    setFormotivDisplayedState({ hasBeenPreviouslyDisplayed: true });
    setShowFormotivPopup(false);
  };

  const onSubmit = async (value: CoverValues) => {
    try {
      setBackdrop(true);
      await updateQuoteCover(value, true);
      gtm(event("Get Policy"));

      if (!hasGlassesGuideMarketValue()) {
        setYourCaravanState({ ...yourCaravanState, marketValue: value.sumInsured });
      }
      redirectToFurtherDetailsPage();
    } catch (ex) {
      logException({
        location: "useYourQuote.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  const onEmail = async (value: EmailQuoteRequest) => {
    try {
      setBackdrop(true);
      await apiClient.emailquote({ ...value });
      gtm(event("email has been sent for quote"));
      setState({ ...initialState, cover: { ...value }, isCompleted: false });
    } catch (ex) {
      logException({
        location: "useYourQuote.onEmail",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  const onQuoteCustomisation = async (value: CoverValues, forceSave: boolean = false) => {
    await trigger();
    const {
      formState: { dirtyFields, errors },
    } = form;
    const isDirty = Object.keys(dirtyFields).length > 0;
    const isValid = Object.keys(errors).length === 0;
    // Something is not right with React Hook Form
    // formState.isDirty is always false,
    // but formState.dirtyFields is correctly populated
    // formState.isValid is always false,
    // but formState.errors is correctly populated
    if (!isValid || (!forceSave && !isDirty)) {
      return;
    }

    try {
      setBackdrop(true);
      setBackdropMessage("Recalculating...");
      reset(value);
      await updateQuoteCover(value, false);

      if (!hasGlassesGuideMarketValue()) {
        setYourCaravanState({ ...yourCaravanState, marketValue: value.sumInsured });
      }
    } catch (ex) {
      logException({
        location: "useYourQuote.onQuoteCustomisation",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
      setBackdropMessage(undefined);
    }
  };

  return {
    form,
    emailForm,
    values: {
      ...initialState,
      quote: {
        ...initialState.quote,
        quoteNumber: initialState.quote?.quoteNumber || "",
        covers: [...(initialState.quote?.covers || [])],
      },
    },
    showFormotivPopup: showFormotivPopup,
    onCloseFormotivPopup,
    onEmail,
    onSubmit,
    onQuoteCustomisation,
  };
};

export default useYourQuote;
