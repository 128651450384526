import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@mui/material";
import { CoverType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { useStyles } from "./styles";

export interface EmailThisQuoteButtonProps extends ButtonProps {
  coverType: CoverType;
  open?: boolean | undefined;
}

export const EmailThisQuoteButton: React.FC<EmailThisQuoteButtonProps> = ({ coverType, open = false, ...props }) => {
  const classes = useStyles;
  const coverTypeId = coverType.toLowerCase();
  const emailToggleQuoteButtonId = `button-${coverTypeId}-toggle-email-quote-form`;
  const icon = open ? faSortUp : faSortDown;
  const color = open ? "info" : "secondary";
  const iconClass = open ? classes.iconUp : classes.iconDown;

  return (
    <Button
      type="button"
      id={emailToggleQuoteButtonId}
      data-testid={emailToggleQuoteButtonId}
      variant="contained"
      fullWidth
      endIcon={<FontAwesomeIcon icon={icon} style={{ ...classes.icon, ...iconClass }} />}
      style={{ marginTop: `15px` }}
      color={color}
      {...props}
    >
      Email quote
    </Button>
  );
};

export default EmailThisQuoteButton;
