const locale = "en-AU";
const style = "currency";
const currency = "AUD";

export const thousandSeparatorThreshold = 10000;

const formatterWithoutDecimal = new Intl.NumberFormat(locale, {
  style,
  currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: false,
});
const formatterWithDecimal = new Intl.NumberFormat("en-AU", {
  style,
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
});
const formatterWithoutDecimalAndWithThousandSeparator = new Intl.NumberFormat(locale, {
  style,
  currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
});
const formatterWithDecimalAndWithThousandSeparator = new Intl.NumberFormat("en-AU", {
  style,
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

const stripZeros = (value: string) => value.replace(/\.00$/, "");

const getFormatter = (value: number, { withDecimals }: CurrencyFormatOptions) => {
  if (value >= thousandSeparatorThreshold) {
    return withDecimals
      ? formatterWithDecimalAndWithThousandSeparator
      : formatterWithoutDecimalAndWithThousandSeparator;
  } else {
    return withDecimals ? formatterWithDecimal : formatterWithoutDecimal;
  }
};

export interface CurrencyFormatOptions {
  withDecimals?: boolean;
  stripZeroCents?: boolean;
}

export const format = (value: number, options: CurrencyFormatOptions = {}): string => {
  const formatter = getFormatter(value, options);
  const formattedValue = formatter.format(value);
  return options.stripZeroCents ? stripZeros(formattedValue) : formattedValue;
};

export default format;
