import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaTextInput } from "@racwa/react-components";
import { pleaseEnterAValidMessage } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";

const inputId = "input-registration-number";
const name = "registrationNumber";
const label = "Caravan registration";
const fieldName = "registration number";
const placeholder = "e.g. RAC123";
const VALID_REGISTRATION_PATTERN = /(?!\s)^[A-Z0-9\s]*$/i;
const REGISTRATION_MAX_LENGTH = 10;

export const CaravanRegistrationInput: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        pattern: {
          value: VALID_REGISTRATION_PATTERN,
          message: pleaseEnterAValidMessage(fieldName),
        },
      }}
      render={({ field: { ref, onBlur, ...inputProps }, fieldState: { error } }) => (
        <>
          <RacwaTextInput
            {...inputProps}
            color="primary"
            id={inputId}
            data-testid={inputId}
            label={label}
            placeholder={placeholder}
            fullWidth
            formControlMargin="none"
            inputProps={{
              maxLength: REGISTRATION_MAX_LENGTH,
            }}
            inputRef={ref}
            error={!!error?.message}
            helperText={error?.message || ""}
            onBlur={() => {
              gtm(fieldTouched(label));
            }}
            optional
          />
        </>
      )}
    />
  );
};

export default CaravanRegistrationInput;
