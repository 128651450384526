import { FormHelperText, Grid } from "@mui/material";
import { FieldError } from "react-hook-form";
import MarketValueOverThresholdNotification from "../../../../shared/components/MarketValueOverThresholdNotification";
import { MAX_CUSTOM_MARKET_VALUE } from "../../../../shared/constants";
import { CoverType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

export interface HelperTextProps {
  coverType: CoverType;
  error?: FieldError | undefined;
  amount?: number;
}

export const HelperText: React.FC<HelperTextProps> = ({ coverType, error, amount = 0 }) => {

  const id = `helper-text-overthreshold-${coverType.toLowerCase()}-notification`;

  if (!error || !error.type) {
    return null;
  }

  return error?.type === "max" && amount > MAX_CUSTOM_MARKET_VALUE ? (
    <Grid item xs={12} sx={{ marginBottom: "8px"}}>
      <MarketValueOverThresholdNotification
        coverType={coverType}
        data-testid={id}
        threshold={MAX_CUSTOM_MARKET_VALUE}
      />
    </Grid>
  ) : (
    <FormHelperText component={Grid} item xs={12} id={id} data-testid={id}>
      {error?.message}
    </FormHelperText>
  );
};

export default HelperText;
