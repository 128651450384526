import { FormControl, FormHelperText, FormLabel, RadioGroup } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/HelperText";
import { Caravan } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { Values } from "../../types";
import { CaravanItem } from "./CaravanItem";
import useStyles from "./styles";

const inputId = "input-caravan";
const containerId = `${inputId}-container`;

export interface SelectCaravanProps {
  options?: Caravan[];
  onChange?: () => void;
}

export const SelectCaravan: React.FC<SelectCaravanProps> = ({ options, onChange }) => {
  const classes = useStyles;
  const { control, getValues } = useFormContext<Values>();
  const modelName = getValues().model?.name;
  const isOnlyOneItem = options?.length === 1;
  const isEmpty = options?.length === 0;
  const label = isOnlyOneItem ? `Your ${modelName}` : `Please select your ${modelName}`;
  return (
    <Controller
      control={control}
      name="caravan"
      rules={{
        required: { value: !isEmpty, message: RequiredSelection },
      }}
      render={({ field: { onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} fullWidth={true}>
          <FormLabel  component="legend">{label}</FormLabel >
          <RadioGroup
            {...props}
            data-testid={containerId}
            aria-label="caravan"
            onChange={(_, value) => {
              onChange && onChange();
              onComponentChange(options?.find((item) => item.id === parseInt(value)));
            }}
            sx={{ ...classes.radioGroup, ...(error ? classes.error : {}) }}
          >
            {options?.map((item, index) => (
              <CaravanItem key={index.toString()} index={index} caravan={item} selected={item.id === props.value?.id} />
            )) || null}
          </RadioGroup>
          {error?.message ? <FormHelperText>{error.message}</FormHelperText> : null}
        </FormControl>
      )}
    />
  );
};

export default SelectCaravan;
