import { lazy } from "react";
import AboutYou from "../../views/AboutYou";
import AboutYourCaravan from "../../views/AboutYourCaravan";

import FurtherDetails from "../../views/FurtherDetails";
import JointPolicyholderInformation from "../../views/JointPolicyHolderInformation";
import MemberDetails from "../../views/MemberDetails";
import Payment from "../../views/Payment";
import PersonalInformation from "../../views/PersonalInformation";
import PolicyholderMembershipLevel from "../../views/PolicyholderMembershipLevel";
import RetrieveQuote from "../../views/RetrieveQuote";
import YourCaravan from "../../views/YourCaravan";
import YourQuote from "../../views/YourQuote";
import { PolicyholderType } from "../hooks/useApiClient/ClientProxy.generated";

const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));
const ConfirmationPage = lazy(() => import("../../views/Confirmation"));

export enum UtilityRoutes {
  SessionTimeout = "408",
  SystemUnavailable = "Error",
  RetrieveQuote = "RetrieveQuote",
}

export enum UtilityPageHeading {
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  RetrieveQuote = "Welcome back",
}

export enum QuoteRoutes {
  MemberDetails = "MemberDetails",
  YourCaravan = "YourCaravan",
  AboutYourCaravan = "AboutYourCaravan",
  AboutYou = "AboutYou",
  YourQuote = "YourQuote",
}

export enum PolicyRoutes {
  StartDate = "StartDate",
  PersonalInformation = "PersonalInformation",
  MembershipLevel = "MembershipLevel",
  JointPolicyholder = "JointPolicyholder",
  JointMembershipLevel = "JointMembershipLevel",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

// <------------------>
//  URLs
// <------------------>
// Quote
export const QUOTE_BASE = `${process.env.PUBLIC_URL}/Quote`;
export const QUOTE_MEMBER_DETAILS_PAGE_URL = `${QUOTE_BASE}/${QuoteRoutes.MemberDetails}`;
export const QUOTE_YOUR_CARAVAN_PAGE_URL = `${QUOTE_BASE}/${QuoteRoutes.YourCaravan}`;
export const QUOTE_ABOUT_YOUR_CARAVAN_PAGE_URL = `${QUOTE_BASE}/${QuoteRoutes.AboutYourCaravan}`;
export const QUOTE_ABOUT_YOU_PAGE_URL = `${QUOTE_BASE}/${QuoteRoutes.AboutYou}`;
export const QUOTE_YOUR_QUOTE_PAGE_URL = `${QUOTE_BASE}/${QuoteRoutes.YourQuote}`;

// Policy
export const POLICY_BASE = `${process.env.PUBLIC_URL}/Policy`;
export const POLICY_START_DATE_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.StartDate}`;
export const POLICY_PERSONAL_INFORMATION_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.PersonalInformation}`;
export const POLICY_MEMBERSHIP_LEVEL_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.MembershipLevel}`;
export const POLICY_JOINT_POLICYHOLDER_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.JointPolicyholder}`;
export const POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.JointMembershipLevel}`;
export const POLICY_PAYMENT_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.Payment}`;
export const POLICY_CONFIRMATION_PAGE_URL = `${POLICY_BASE}/${PolicyRoutes.Confirmation}`;

export const UTILITY_SESSION_TIMEOUT_PAGE_URL = `${QUOTE_BASE}/${UtilityRoutes.SessionTimeout}`;
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = `${QUOTE_BASE}/${UtilityRoutes.SystemUnavailable}`;
export const UTILITY_RETRIEVE_QUOTE_PAGE_URL = `${QUOTE_BASE}/${UtilityRoutes.RetrieveQuote}`;

export const RAC_HOME_PAGE_URL = `${process.env.REACT_APP_RAC_HOME_PAGE}/`;
export const HOME_PAGE_URL = `${process.env.PUBLIC_URL}/`;
export const ALIVE_PAGE_URL = `${HOME_PAGE_URL}Alive`;

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const quoteRoutes: Record<QuoteRoutes, RouteInformation> = {
  [QuoteRoutes.MemberDetails]: {
    key: QuoteRoutes.MemberDetails,
    path: QUOTE_MEMBER_DETAILS_PAGE_URL,
    name: "Member details",
    element: <MemberDetails />,
  },
  [QuoteRoutes.YourCaravan]: {
    key: QuoteRoutes.YourCaravan,
    path: QUOTE_YOUR_CARAVAN_PAGE_URL,
    name: "Your caravan",
    element: <YourCaravan />,
  },
  [QuoteRoutes.AboutYourCaravan]: {
    key: QuoteRoutes.AboutYourCaravan,
    path: QUOTE_ABOUT_YOUR_CARAVAN_PAGE_URL,
    name: "Storage and use",
    element: <AboutYourCaravan />,
  },
  [QuoteRoutes.AboutYou]: {
    key: QuoteRoutes.AboutYou,
    path: QUOTE_ABOUT_YOU_PAGE_URL,
    name: "About the policyholders",
    element: <AboutYou />,
  },
  [QuoteRoutes.YourQuote]: {
    key: QuoteRoutes.YourQuote,
    path: QUOTE_YOUR_QUOTE_PAGE_URL,
    name: "Your quote",
    element: <YourQuote />,
  },
};

export const policyRoutes: Record<PolicyRoutes, RouteInformation> = {
  [PolicyRoutes.StartDate]: {
    key: PolicyRoutes.StartDate,
    path: POLICY_START_DATE_PAGE_URL,
    name: "Start date",
    element: <FurtherDetails />,
  },
  [PolicyRoutes.PersonalInformation]: {
    key: PolicyRoutes.PersonalInformation,
    path: POLICY_PERSONAL_INFORMATION_PAGE_URL,
    name: "Personal information",
    element: <PersonalInformation />,
  },
  [PolicyRoutes.MembershipLevel]: {
    key: PolicyRoutes.MembershipLevel,
    path: POLICY_MEMBERSHIP_LEVEL_PAGE_URL,
    name: "Membership level",
    element: <PolicyholderMembershipLevel policyholderType={PolicyholderType.Main} />,
  },
  [PolicyRoutes.JointPolicyholder]: {
    key: PolicyRoutes.JointPolicyholder,
    path: POLICY_JOINT_POLICYHOLDER_PAGE_URL,
    name: "Joint policyholder",
    element: <JointPolicyholderInformation />,
  },
  [PolicyRoutes.JointMembershipLevel]: {
    key: PolicyRoutes.JointMembershipLevel,
    path: POLICY_JOINT_MEMBERSHIP_LEVEL_PAGE_URL,
    name: "Joint membership level",
    element: <PolicyholderMembershipLevel policyholderType={PolicyholderType.Additional1} />,
  },
  [PolicyRoutes.Payment]: {
    key: PolicyRoutes.Payment,
    path: POLICY_PAYMENT_PAGE_URL,
    name: "Payment",
    element: <Payment />,
  },

  [PolicyRoutes.Confirmation]: {
    key: PolicyRoutes.Confirmation,
    path: POLICY_CONFIRMATION_PAGE_URL,
    name: "Confirmation",
    element: <ConfirmationPage />,
  },
};

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: "Session timed out",
    element: <SessionTimeout />,
    heading: UtilityPageHeading.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: "System unavailable",
    element: <SystemUnavailable />,
    heading: UtilityPageHeading.SystemUnavailable,
  },
  {
    key: UtilityRoutes.RetrieveQuote,
    path: UTILITY_RETRIEVE_QUOTE_PAGE_URL,
    name: "Retrieve Quote",
    element: <RetrieveQuote />,
    heading: UtilityPageHeading.RetrieveQuote,
  },
];

export const quoteRoutesArray: RouteInformation[] = Object.values(quoteRoutes);
export const policyRoutesArray: RouteInformation[] = Object.values(policyRoutes);
export const allRoutes = [...quoteRoutesArray, ...policyRoutesArray, ...utilityRoutes];
