import { Card, CardContent, Divider, Typography } from "@mui/material";
import { FormProvider, UseFormReturn } from "react-hook-form";
import PaymentFrequency, { LayoutVariant } from "../../../../shared/components/PaymentFrequency";
import PremiumBreakdown from "../../../../shared/components/PremiumBreakdown";
import { REMOVE_EXCESS } from "../../../../shared/constants";
import { Cover, NumberRange } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { CoverValues } from "../../types";
import ContentsInput from "../ContentsInput";
import DiscountList from "../DiscountList";
import EmailQuoteForm from "../EmailQuoteForm";
import ExcessAdjustment from "../ExcessAdjustment";
import ExcessDisclaimer from "../ExcessDisclaimer";
import ExcessDiscountItem from "../ExcessDiscountItem";
import ExcessInput from "../ExcessInput";
import GetPolicyButton from "../GetPolicyButton";
import SumInsuredInput from "../SumInsuredInput";
import QuoteCardHeading from "./QuoteCardHeading";
import { useStyles } from "./styles";

export interface QuoteCardProps extends Cover {
  form: UseFormReturn<any>;
  emailForm: UseFormReturn<any>;
  contents?: NumberRange | undefined | null;
  onEmail: (value: CoverValues) => Promise<void>;
  onSubmit: (value: CoverValues) => Promise<unknown>;
  onQuoteCustomisation: (value: CoverValues) => Promise<void>;
}

export const QuoteCard: React.FC<QuoteCardProps> = ({
  form,
  type,
  premiumDetails,
  discounts,
  excess,
  sumInsured,
  onEmail,
  onSubmit,
  onQuoteCustomisation,
  emailForm,
  ...props
}) => {
  const classes = useStyles;
  const { watch } = form;
  const contents: NumberRange | null | undefined = props?.contents;
  const toggle = REMOVE_EXCESS;
  return (
    <Card variant="outlined">
      <FormProvider {...form}>
        <form noValidate action="#" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent>
            <QuoteCardHeading />
            <DiscountList coverType={type} items={discounts}>
              {!toggle && <ExcessDiscountItem coverType={type} {...excess} />}
            </DiscountList>
            <PaymentFrequency
              variant={LayoutVariant.Stacked}
              {...premiumDetails}
              trackingName={"quotePaymentFrequency"}
            />
            <PremiumBreakdown coverType={type} frequency={watch("frequency")} {...premiumDetails} />
          </CardContent>
          <CardContent sx={classes.cardContentMiddle}>
            {excess && excess.options && excess.options.length > 1 && (
              <>
                {!toggle ? (
                  <>
                    <ExcessInput coverType={type} options={excess.options} onChange={onQuoteCustomisation} />
                    <Typography variant="body2">Additional excesses apply to individuals under 26</Typography>
                  </>
                ) : (
                  <ExcessAdjustment options={excess.options} onChange={onQuoteCustomisation} coverType={type} />
                )}
                <Divider />
              </>
            )}
            <SumInsuredInput coverType={type} min={sumInsured.min} max={sumInsured.max} onBlur={onQuoteCustomisation} />
            {contents && (
              <>
                <Divider />
                <ContentsInput
                  coverType={type}
                  min={contents?.min}
                  max={contents?.max}
                  onChange={onQuoteCustomisation}
                />
                {!excess || (excess.options && excess.options.length < 2 && <ExcessDisclaimer />)}
              </>
            )}
          </CardContent>
        </form>
        <CardContent>
          <GetPolicyButton
            coverType={type}
            onClick={form.handleSubmit(async (values) => await onSubmit({ ...values, type: type }))}
          />
          <form noValidate action="#" onSubmit={emailForm.handleSubmit(onSubmit)}>
            <EmailQuoteForm coverType={type} onSubmit={onEmail} />
          </form>
        </CardContent>
      </FormProvider>
    </Card>
  );
};

export default QuoteCard;
