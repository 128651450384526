import { Grid, Typography } from "@mui/material";
import { CoverType } from "../../hooks/useApiClient/ClientProxy.generated";
import format from "../CurrencyInput/format";
import { useStyles } from "./styles";

export interface PremiumBreakdownItemProps {
  coverType: CoverType;
  label: string;
  value: number;
}

export const PremiumBreakdownItem: React.FC<PremiumBreakdownItemProps> = ({ coverType, label, value }) => {
  const classes = useStyles;
  const id = `${coverType.toLowerCase()}-premium-breakdown-${label.replace(/\s+/, "-").toLowerCase()}`;
  return (
    <>
      <Grid item xs={8}>
        <Typography variant="caption" sx={classes.text}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4} sx={classes.textAlignRight}>
        <Typography id={id} data-testid={id} variant="caption" sx={classes.text}>
          {format(value, { withDecimals: true })}
        </Typography>
      </Grid>
    </>
  );
};

export default PremiumBreakdownItem;
