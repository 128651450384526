import { Grid, Typography } from "@mui/material";
import useStyles from "./styles";

const id = "heading-quote-number";

export interface HeaderProps {
  quoteNumber: string;
}

export const Header: React.FC<HeaderProps> = ({ quoteNumber }) => {
  const classes = useStyles;
  return (
    <Grid container sx={classes.heading}>
      <Grid item>
        <Typography variant="body2">
          Your quote number is{" "}
          <strong id={id} data-testid={id}>
            {quoteNumber}
          </strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
