import { Grid } from "@mui/material";
import { ContactNumberInput, DateOfBirthInput, EmailInput, FirstNameInput } from "raci-react-library";
import { PrivacyPolicy } from "../../../../shared/components/PrivacyPolicy";
import { Values } from "../../types";

export interface MemberMatchFormProps {
  show?: boolean;
  disabled?: boolean;
  dispatcher?: (type: keyof Values) => void;
  dateOfBirthOnBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const MemberMatchForm: React.FC<MemberMatchFormProps> = ({ show, disabled, dispatcher, dateOfBirthOnBlur }) => {
  return show ? (
    <>
      <Grid item xs={12}>
        <FirstNameInput disabled={disabled} onChange={() => dispatcher && dispatcher("firstName")} />
      </Grid>
      <Grid item xs={12}>
        <DateOfBirthInput
          label="Date of birth"
          disabled={disabled}
          onChange={() => dispatcher && dispatcher("dateOfBirth")}
          onBlur={dateOfBirthOnBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <ContactNumberInput
          isMobile
          disabled={disabled}
          onChange={() => dispatcher && dispatcher("contactNumber")}
        />
      </Grid>
      <Grid item xs={12}>
        <EmailInput disabled={disabled} onChange={() => dispatcher && dispatcher("email")} />
      </Grid>
      <Grid item xs={12}>
        <PrivacyPolicy />
      </Grid>
    </>
  ) : null;
};

export default MemberMatchForm;
