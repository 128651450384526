import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/HelperText";
import { Model } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { Values } from "../../types";

const label = "Model";
const inputId = "input-model";
const inputContainerId = `${inputId}-container`;

export interface SelectModelProps {
  options?: Model[];
  onChange?: () => void;
}

export const SelectModel: React.FC<SelectModelProps> = ({ options, onChange }) => {
  const { control } = useFormContext<Values>();
  const isOnlyOneItem = options?.length === 1;
  const isLoading = options === undefined;
  const isEmpty = options?.length === 0;
  return (
    <Controller
      control={control}
      name="model"
      rules={{
        required: { value: !isEmpty, message: RequiredSelection },
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label}
          aria-label={label}
          placeholder="Start typing your model name"
          options={options || ([] as Model[])}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          fullWidth
          autoSelect={isOnlyOneItem}
          autoComplete={true}
          autoHighlight={true}
          clearOnEscape={true}
          loading={isLoading}
          disabled={isEmpty}
          onChange={(_, value) => {
            window.formotiv && window.formotiv.inputActivity(window.formotivConfig, "model", "autocomplete", value);
            onChange && onChange();
            onComponentChange(value);
          }}
          onBlur={() => {
            gtm(fieldTouched(label));
            onBlur && onBlur();
          }}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
        />
      )}
    />
  );
};

export default SelectModel;
