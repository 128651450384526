import { Button, Grid, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { PrivacyPolicy } from "../../shared/components/PrivacyPolicy";
import SelectMembership from "../../shared/components/SelectMembership";
import useFormotiv from "../../shared/hooks/useFormotiv";
import { FormProps } from "./types";

export const Form: React.FC<FormProps> = ({ form, data: { membershipLevels, jointPolicyholderName }, onSubmit }) => {
  const onSubmitWrapper = useFormotiv(onSubmit);

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              One more question
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectMembership options={membershipLevels} memberName={jointPolicyholderName} />
          </Grid>
          <Grid item xs={12}>
            <PrivacyPolicy />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
