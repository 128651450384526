import { Grid, Typography } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import NeedMoreInformationCardNotification from "../../../../shared/components/NeedMoreInformationCardNotification";
import YesNoButtonGroup from "../../../../shared/components/YesNoButtonGroup";
import { YES } from "../../../../shared/constants";

const anyAccidentsInputId = "anyAccident";
const anyAccidentsInputContainerId = `${anyAccidentsInputId}-container`;
const anyAccidentsLabel = "Any accidents or made any claims, to any vehicle, regardless of blame?";

const licenceCancelledInputId = "hadLicenceCancelled";
const licenceCancelledInputContainerId = `${licenceCancelledInputId}-container`;
const licenceCancelledLabel = "Their driver's licence cancelled, suspended or any special conditions applied?";

export interface DutyOfDisclosureProps {
  quoteNumber?: string;
}

const DutyOfDisclosureForm: React.FC<DutyOfDisclosureProps> = ({ quoteNumber = "" }) => {
  const { control } = useFormContext();

  const watchIsHadAccident = useWatch({
    control: control,
    name: "anyAccident",
  });

  const watchHadLicenceCancelled = useWatch({
    control: control,
    name: "hadLicenceCancelled",
  });

  const renderAccidentOrCancelledCard = (
    <Grid item xs={12}>
      <NeedMoreInformationCardNotification quoteNumber={quoteNumber} />
    </Grid>
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3" id="header" data-testid="inThePastThreeYears">
          In the past three years, have you or any policyholders had...
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <YesNoButtonGroup
          id={anyAccidentsInputId}
          data-testid={anyAccidentsInputContainerId}
          label={anyAccidentsLabel}
          name={anyAccidentsInputId}
          validate={{ valid: (value: string) => value !== YES }}
        />
      </Grid>
      <Grid item xs={12}>
        <YesNoButtonGroup
          id={licenceCancelledInputId}
          data-testid={licenceCancelledInputContainerId}
          label={licenceCancelledLabel}
          name={licenceCancelledInputId}
          validate={{ valid: (value: string) => value !== YES }}
        />
      </Grid>
      {(watchHadLicenceCancelled === YES || watchIsHadAccident === YES) && renderAccidentOrCancelledCard}
    </>
  );
};

export default DutyOfDisclosureForm;
