import { Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import {
  PaymentFrequency as ApiPaymentFrequency,
  PremiumDetails,
} from "../../hooks/useApiClient/ClientProxy.generated";
import format from "../CurrencyInput/format";
import PaymentFrequencyToggleButton, { PaymentFrequencyToggleButtonProps } from "./PaymentFrequencyToggleButton";
import SavingsInformationLabel from "./SavingsInformationLabel";
import { StyledGrid, StyledStackedGrid } from "./styled";

const amountLabelId = "label-payment-amount";

export enum LayoutVariant {
  Default,
  Stacked,
}

export interface PaymentFrequencyProps extends PremiumDetails, PaymentFrequencyToggleButtonProps {
  variant?: LayoutVariant;
  trackingName: string;
}

export const PaymentFrequency: React.FC<PaymentFrequencyProps> = ({
  trackingName,
  variant = LayoutVariant.Default,
  annual,
  monthly,
  displayLabel = false,
  disabled,
}) => {
  const { watch } = useFormContext();

  const frequency = watch("frequency");
  const isAnnual = frequency === ApiPaymentFrequency.Annual;

  const amount = format(isAnnual ? annual.total : monthly.instalment, {
    withDecimals: true,
  });

  const CustomGrid = variant === LayoutVariant.Stacked ? StyledStackedGrid : StyledGrid;

  return (
    <>
      <Grid container alignItems="center" direction="row-reverse" sx={{marginBottom: "16px"}}>
        <CustomGrid item xs={variant === LayoutVariant.Default ? 4 : 12} sm={4}>
          <Typography
            variant="h3"
            id={amountLabelId}
            data-testid={amountLabelId}
            sx={displayLabel ? {marginTop: "40px"} : {}}
          >
            {amount}
          </Typography>
        </CustomGrid>
        <Grid item xs={variant === LayoutVariant.Default ? 8 : 12} sm={8}>
          <PaymentFrequencyToggleButton trackingName={trackingName} displayLabel={displayLabel} disabled={disabled} />
        </Grid>
      </Grid>
      <SavingsInformationLabel annualTotal={annual.total} monthlyTotal={monthly.total} />
    </>
  );
};
export default PaymentFrequency;
