import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Controller, useFormContext, type RegisterOptions } from "react-hook-form";
import { pleaseEnterAMessage } from "../../constants/HelperText";
import { Suburb } from "../../hooks/useApiClient/ClientProxy.generated";

const inputId = "input-suburb";
const inputContainerId = `${inputId}-container`;

export interface SelectSuburbProps {
  options?: Suburb[];
  label?: string;
  validate?: RegisterOptions["validate"];
  formControlMargin?: "dense" | "none" | "normal";
  disabled?: boolean;
}

export const SelectSuburb: React.FC<SelectSuburbProps> = ({
  options,
  label,
  validate,
  formControlMargin = "normal",
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="suburb"
      rules={{
        required: {
          value: true,
          message: pleaseEnterAMessage("suburb"),
        },
        validate,
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, value, ...props }, fieldState: { error } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label}
          aria-label={label}
          placeholder="Enter suburb"
          options={options || ([] as Suburb[])}
          getOptionLabel={(option) => option.cityName}
          isOptionEqualToValue={(option, value) => option.cityName === value?.cityName}
          fullWidth
          autoComplete={true}
          autoHighlight={true}
          clearOnEscape={true}
          loading={options === undefined}
          disabled={disabled}
          onChange={(_, v) => {
            window.formotiv && window.formotiv.inputActivity(window.formotivConfig, "suburb", "autocomplete", v);
            const suburb = v as Suburb;
            onComponentChange && onComponentChange(suburb);
          }}
          onBlur={() => {
            gtm(fieldTouched(label || inputId));
            onBlur && onBlur();
          }}
          value={value === undefined ? null : value}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
          formControlMargin={formControlMargin}
        />
      )}
    />
  );
};

export default SelectSuburb;
