import HelpTextTooltip from "../../../../shared/components/HelpTextToolTip";

export const AgreedValueTooltip = () => {
  return (
    <HelpTextTooltip
      id="chooseAgreedValueTooltip"
      data-testid="chooseAgreedValueTooltip"
      title="Agreed value"
      message="The agreed value is the amount we agree to insure your caravan for. It includes accessories, GST, rego and any other 'on-road' costs. If you adjust the agreed value your policy premium will change."
    />
  );
};

export default AgreedValueTooltip;
