import React from "react";
import AboutYouForm from "./Form";
import useAboutYou from "./hooks/useAboutYou";

export const AboutYou: React.FC<any> = () => {
  const props = useAboutYou();

  return <AboutYouForm {...props} />;
};

export default AboutYou;
