import { FormControl, FormLabel, Grid, Hidden } from "@mui/material";
import useStyles from "./styles";
import { PropsWithChildren } from "react";

export interface CoverFormInputProps {
  labelId: string;
  inputId: string;
  labelXs?: string;
  label: string;
  tooltip: JSX.Element;
  error?: boolean | undefined;
  helperText?: React.ReactElement;
}

export const CoverFormInput: React.FC<PropsWithChildren<CoverFormInputProps>> = ({
  labelId,
  inputId,
  labelXs,
  label,
  tooltip,
  error,
  children,
  helperText,
}) => {
  const classes = useStyles;
  return (
    <FormControl
      error={error || false}
      component={Grid}
      container
      direction="row"
      sx={classes.containerRow}
      alignItems="center"
    >
      <FormLabel component={Grid} item xs={4} sm={6} id={labelId} htmlFor={inputId}>
        {labelXs && (
          <>
            <Hidden smUp implementation="css">
              {labelXs}
            </Hidden>
            <Hidden only={"xs"} implementation="css">
              {label}
            </Hidden>
          </>
        )}
        {!labelXs && label}
      </FormLabel>
      <Grid item xs={2} sx={classes.tooltip}>
        {tooltip}
      </Grid>
      <Grid item xs={6} sm={4}>
        {children}
      </Grid>
      {helperText}
    </FormControl>
  );
};

export default CoverFormInput;
