import { Link } from "@mui/material";
import { StyledTypography } from "./styled";

export interface PrivacyPolicyProps {}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {

  return (
    <StyledTypography variant="body2">
      See our{" "}
      <Link
        id="privacy-policy-link"
        data-testid="privacy-policy-link"
        href={process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE}
        target="_blank"
        rel="noreferrer noopener"
        component="a"
        color="primary"
      >
        privacy policy
      </Link>{" "}
      and{" "}
      <Link
        id="pds-link"
        data-testid="pds-link"
        href={process.env.REACT_APP_RAC_PDS_URL}
        target="_blank"
        rel="noreferrer noopener"
        component="a"
        color="primary"
      >
        PDS
      </Link>{" "}
      for why we collect, and how we handle and protect, your personal information.
    </StyledTypography >
  );
};

export default PrivacyPolicy;
