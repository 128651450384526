import MailingAddressTemplate from "./MailingAddressTemplate";
import useMailingAddress from "./useMailingAddress";

export const contactMatchedLabel = "Please confirm your mailing address";

export interface MailingAddressProps {
  label?: string | null;
  required?: boolean;
}

const MailingAddress: React.FC<MailingAddressProps> = (props: MailingAddressProps) => {
  const { ...mailingAddressProps } = useMailingAddress();

  return <MailingAddressTemplate {...mailingAddressProps} {...props} />;
};

export default MailingAddress;
