import { Typography } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";

const InportantInformationCard = () => (
  <RacwaCardNotification data-testid="importantInformationCard" title="Important information" severity="info">
    <Typography variant="body2">
      When answering our questions you have a duty to answer them honestly, accurately and to the best of your
      knowledge. The duty applies to you and anyone else insured under the policy. If you answer for another person, we
      will treat your answers as theirs. Your duty continues until we insure you.
    </Typography>
    <br></br>
    <Typography variant="body2">
      If you do not meet the duty your policy may be cancelled, or treated as if it never existed and your claim may be
      rejected or not paid in full. This insurance is a consumer insurance contract.
    </Typography>
  </RacwaCardNotification>
);

export default InportantInformationCard;
