import { event, gtm } from "@racwa/analytics";
import { useSessionState } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { MIN_CUSTOM_MARKET_VALUE } from "../../../../shared/constants";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import { QUOTE_ABOUT_YOUR_CARAVAN_PAGE_URL, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { Values as AboutYourCaravanValues } from "../../../../views/AboutYourCaravan/types";
import { Values as YourQuoteValues } from "../../../../views/YourQuote/types";
import { FormProps, Values } from "../../types";
import useCaravans from "../useCaravans";
import useModels from "../useModels";
import useYears from "../useYears";

const marketValueRandomID = 10000000;

const getInitialState = (state: Values): Values => {
  return {
    manufacturer: state.manufacturer || null,
    year: state.year || null,
    model: state.model || null,
    caravan: state.caravan || null,
    marketValue: state.marketValue || null,
  };
};

export const useYourCaravan = (): FormProps => {
  const navigate = useNavigate();
  const [state, setSessionState] = useSessionState<Values>();
  const [aboutYourCaravanSessionState, setAboutYourCaravanSessionState] = useSessionState<AboutYourCaravanValues>({
    specificKey: QuoteRoutes.AboutYourCaravan,
    skipPageTrackingRecalculation: true,
  });
  const [quoteSessionState, setQuoteSessionState] = useSessionState<YourQuoteValues>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: getInitialState(state),
  });
  const { watch, setValue, clearErrors } = form;
  const { manufacturers } = useReferenceData();
  const [setValueOptions] = useState({
    shouldValidate: true,
    shouldDirty: true,
  });
  const clearValue = (type: keyof Values) => {
    setValue(type, null);
    clearErrors(type);
  };

  const dispatcher = (type: keyof Values) => {
    if (type === "manufacturer") {
      clearValue("year");
      clearValue("model");
      clearValue("caravan");
      clearValue("marketValue");
    }

    if (type === "year") {
      clearValue("model");
      clearValue("caravan");
      clearValue("marketValue");
    }

    if (type === "model") {
      clearValue("caravan");
      clearValue("marketValue");
    }

    if (type === "caravan") {
      clearValue("marketValue");
    }
  };

  const onSubmit = async (newValue: Values) => {
    if (hasLoaded) {
      const canOverrideMarketValue = (newValue.caravan?.marketValue || 0) < MIN_CUSTOM_MARKET_VALUE;
      const marketValue = canOverrideMarketValue ? newValue.marketValue : null;
      setSessionState({
        ...newValue,
        marketValue: marketValue,
        isCompleted: true,
      });
      if (marketValue) {
        if (quoteSessionState?.cover) {
          const cover = quoteSessionState.cover;
          cover.sumInsured = marketValue;
          setQuoteSessionState({ ...quoteSessionState, cover });
        }

        const valueEvent = {
          ...event(`Market Value ${Math.floor(Math.random() * marketValueRandomID)}`),
          value: `${marketValue}`,
        };
        gtm(valueEvent);
      }

      if (state.caravan?.id !== newValue.caravan?.id) {
        setAboutYourCaravanSessionState({ ...aboutYourCaravanSessionState, registrationNumber: undefined });
      }

      navigate(QUOTE_ABOUT_YOUR_CARAVAN_PAGE_URL);
    }
  };

  const manufacturer = watch("manufacturer")?.code;
  const year = watch("year");
  const model = watch("model")?.code;
  const { years } = useYears(manufacturer);
  const { models } = useModels(manufacturer, year);
  const { caravans, hasLoaded } = useCaravans(manufacturer, year, model);

  useEffect(() => {
    if (years?.length === 1) {
      setValue("year", years[0], setValueOptions);
    }
  }, [setValue, years, setValueOptions]);

  useEffect(() => {
    if (models?.length === 1) {
      setValue("model", models[0], setValueOptions);
    }
  }, [setValue, models, setValueOptions]);

  useEffect(() => {
    if (caravans?.length === 1) {
      setValue("caravan", caravans[0], setValueOptions);
    }
  }, [setValue, caravans, setValueOptions]);

  return {
    form,
    data: { manufacturers, years, models, caravans },
    dispatcher,
    onSubmit,
  };
};

export default useYourCaravan;
