import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Divider, Grid, Link, Typography } from "@mui/material";
import { useGetSessionState } from "raci-react-library";
import React, { useState } from "react";
import { YES } from "../../../../shared/constants";
import { QuoteRoutes } from "../../../../shared/routing/routes.config";
import { Values as AboutYouValues } from "../../../AboutYou/types";
import CaravanUsageSummaryItem from "./PolicySummaryItems/CaravanUsage";
import DrivingHistorySummaryItem from "./PolicySummaryItems/DrivingHistory";
import JointPolicyholderSummaryItem from "./PolicySummaryItems/JointPolicyholderDetails";
import MoreAboutYouSummaryItem from "./PolicySummaryItems/MoreAboutYou";
import PolicyStartDateSummaryItem from "./PolicySummaryItems/PolicyStartDate";
import YourCaravanSummaryItem from "./PolicySummaryItems/YourCaravan";
import { useStyles } from "./styles";

export const PaymentPolicySummary: React.FC = () => {
  const classes = useStyles;
  const [isOpen, setIsOpen] = useState(false);
  const icon = isOpen ? faSortUp : faSortDown;

  const { addAdditionalPolicyholder } = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const hasAdditionalPolicyholder = addAdditionalPolicyholder === YES;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid container sx={classes.summaryTop}>
        <Grid item xs={12} sx={classes.summaryTopHeading}>
          <Typography variant="h3">Your policy summary</Typography>
        </Grid>

        <Grid item xs={12}>
          <YourCaravanSummaryItem />
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={5} sx={classes.summaryBottom}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Link
              data-testid="toggle_quote_information"
              variant="inherit"
              color="inherit"
              href="#"
              underline="none"
              onClick={(e: React.SyntheticEvent<Element, Event>) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? "Hide information your quote is based on" : "See information your quote is based on"}
              <FontAwesomeIcon icon={icon} aria-hidden style={isOpen ? classes.iconUp : classes.iconDown} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Grid container sx={classes.summaryBottom}>
          <Grid item xs={12}>
            <CaravanUsageSummaryItem />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <DrivingHistorySummaryItem />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PolicyStartDateSummaryItem />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MoreAboutYouSummaryItem />
          </Grid>
          {hasAdditionalPolicyholder && (
            <Grid item xs={12}>
              <Divider />
              <JointPolicyholderSummaryItem />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" sx={classes.disclaimer}>
              Price based on the caravan, excess, insured value, cover choice and information provided. Please ensure
              all details are correct and your needs are met.
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default PaymentPolicySummary;
