import { Grid, styled } from "@mui/material";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    textAlign: "right",
  },
  "& > h3": {
    fontSize: "1.75rem",
  },
}));

export const StyledStackedGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    textAlign: "right",
  },
  "& > h3": {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.only("xs")]: {
    "& > h3": {
      paddingBottom: "12px",
    },
  },
}));
