import { event, gtm } from "@racwa/analytics";
import format from "date-fns/format";
import {
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  HTTP_STATUS_CODE_MULTI_MATCH,
  HTTP_STATUS_CODE_NOT_FOUND,
  HTTP_STATUS_CODE_OK,
  HTTP_STATUS_CODE_TOO_MANY_REQUESTS,
  useLogger,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_YYYY_MM_DD, NO, SKIP } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ApiException, MatchContact } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCreateSession from "../../../../shared/hooks/useCreateSession";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import { PolicyRoutes, QUOTE_YOUR_CARAVAN_PAGE_URL, QuoteRoutes, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../../../shared/routing/routes.config";
import { Values as MemberDetailsValues } from "../../../MemberDetails/types";
import { Values as PersonalInformationValues } from "../../../PersonalInformation/types";
import { FormProps, Values } from "../../types";

const NoMatchErrorType = "NoMatch";
const NoMatchError = { type: NoMatchErrorType };

const isMultiMatch = (exception?: any): boolean => (exception as ApiException)?.status === HTTP_STATUS_CODE_MULTI_MATCH;

const isNotFound = (exception?: any): boolean => (exception as ApiException)?.status === HTTP_STATUS_CODE_NOT_FOUND;

const isRequestExceeded = (exception?: any): boolean =>
  (exception as ApiException)?.status === HTTP_STATUS_CODE_TOO_MANY_REQUESTS;

const createRequest = (data: Values): MatchContact => ({
  firstName: data.firstName || "",
  // Requires to be ignored as Typescript requires Date type
  // but serialises date in the wrong format
  // @ts-ignore
  dateOfBirth: (data.dateOfBirth && format(data.dateOfBirth, DATE_FORMAT_YYYY_MM_DD)) || null,
  email: data.email || "",
  mobileNumber: data.contactNumber || "",
});

const gtmEvent = (exception?: any) => {
  const errorResponse = exception as ApiException;

  if (errorResponse && errorResponse.status === HTTP_STATUS_CODE_MULTI_MATCH) {
    gtm(event("multi match"));
  }

  if (errorResponse && errorResponse.status === HTTP_STATUS_CODE_NOT_FOUND) {
    gtm(event("contact not found"));
  }

  if (errorResponse && errorResponse.status === HTTP_STATUS_CODE_TOO_MANY_REQUESTS) {
    gtm(event("match attempts exhausted"));
  }
};

export const useMemberDetails = (): FormProps => {
  useCreateSession();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [matchFound, setMatchFound] = useState(false);
  const [isContactMatchAttemptsExhausted, setIsContactMatchAttemptsExhausted] = useState(false);
  const [membershipTier, setMembershipTier] = useState("None");
  const [interimState, setState] = useSessionState<Values>();
  const [personalInformationSessionState, setPersonalInformationSessionState] =
    useSessionState<PersonalInformationValues>({
      specificKey: PolicyRoutes.PersonalInformation,
      skipPageTrackingRecalculation: true,
    });
  const [memberDetailsState, setMemberDetailsState] = useSessionState<MemberDetailsValues>({
    specificKey: QuoteRoutes.MemberDetails,
    skipPageTrackingRecalculation: true,
  });
  // Date of birth becomes a string
  const state = {
    ...interimState,
    dateOfBirth: interimState.dateOfBirth && new Date(interimState.dateOfBirth),
  };
  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: state,
  });
  const {
    getValues,
    setError,
    formState: { errors },
    trigger,
    reset,
  } = form;
  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };
  const redirectToNextPage = useCallback(() => {
    navigate(QUOTE_YOUR_CARAVAN_PAGE_URL);
  }, [navigate]);
  const saveStateAndRedirect = (newValue: Values) => {
    setState({ ...newValue, isCompleted: true });
    redirectToNextPage();
  };

  const setupNoMatchError = () => {
    setError("firstName", NoMatchError);
    setError("dateOfBirth", NoMatchError);
    setError("contactNumber", NoMatchError);
    setError("email", NoMatchError);
  };

  const resetPersonalInformationState = () => {
    const newPersonalInfo: PersonalInformationValues = {
      ...personalInformationSessionState,
      contactNumber: undefined,
      email: undefined,
      firstName: undefined,
      gender: undefined,
      mailingAddress: undefined,
      lastName: undefined,
      title: undefined,
      captureMembershipLevel: false,
      isCompleted: false,
    };

    setPersonalInformationSessionState(newPersonalInfo);
  };

  const onSubmit = async (newValue: Values) => {
    try {
      const request = createRequest(newValue);
      setBackdrop(true);
      const response = await apiClient.matchcontact(request);

      if (response.status === HTTP_STATUS_CODE_OK) {
        response.result.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE
          ? gtm(event("member data sync error"))
          : gtm(event("member found"));
      }

      const { isMultiMatch, ...newState } = newValue;
      setState({ ...newState, isCompleted: true });
      setMatchFound(true);
      setMembershipTier(response.result.membershipTier || "None");
      resetPersonalInformationState();
      return true;
    } catch (ex) {
      logException({
        location: "useMemberDetails.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      gtmEvent(ex);
      if (isMultiMatch(ex)) {
        setState({ ...newValue, isMultiMatch: true, isCompleted: true });
        setMatchFound(true);
        return true;
      } else if (isNotFound(ex)) {
        reset(newValue);
        setupNoMatchError();
        setMatchFound(false);
      } else if (isRequestExceeded(ex)) {
        setIsContactMatchAttemptsExhausted(true);
        setMatchFound(false);
      } else {
        redirectToSystemUnavailable();
      }
    } finally {
      setBackdrop(false);
    }

    return false;
  };

  const dispatcher = (type: keyof Values) => {
    if (type === "areYouAMember") {
      const { areYouAMember } = getValues();
      if (areYouAMember === NO || areYouAMember === SKIP) {
        apiClient.deletematchedcontactfromsession();
        if (memberDetailsState.firstName && memberDetailsState.contactNumber && memberDetailsState.dateOfBirth) {
          setPersonalInformationSessionState({
            ...personalInformationSessionState,
            mailingAddress: undefined,
          });
          setMemberDetailsState({
            ...memberDetailsState,
            firstName: undefined,
            contactNumber: undefined,
            dateOfBirth: undefined,
            email: undefined,
          });
        } else {
          setPersonalInformationSessionState({
            ...personalInformationSessionState,
          });
        }
        saveStateAndRedirect({ areYouAMember });
      }
    }

    const hasNoMatchError =
      errors.firstName?.type === NoMatchErrorType ||
      // @ts-ignore - Some issues with React Form Hook type declares
      errors.dateOfBirth?.type === NoMatchErrorType ||
      errors.contactNumber?.type === NoMatchErrorType ||
      errors.email?.type === NoMatchErrorType;
    if (hasNoMatchError) {
      trigger();
    }
  };

  useEffect(() => {
    let isCancelled = false;

    const checkContactMatchAttemptsExhausted = async () => {
      if (!isCancelled) {
        try {
          await apiClient.contactmatchattemptsexhausted();
        } catch (ex: any) {
          logException({
            location: "useMemberDetails.checkContactMatchAttemptsExhausted",
            message: ex,
            customProperties: customLogProperties,
          });
          if (ex?.status === HTTP_STATUS_CODE_TOO_MANY_REQUESTS) {
            gtm(event("match attempts exhausted"));
            setIsContactMatchAttemptsExhausted(true);
          } else {
            setIsContactMatchAttemptsExhausted(false);
          }
        }
      }
    };

    checkContactMatchAttemptsExhausted();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient]);

  return {
    form,
    matchFound,
    isContactMatchAttemptsExhausted,
    membershipTier,
    dispatcher,
    onSubmit,
    redirectToNextPage,
  };
};

export default useMemberDetails;
