export const useStyles = {
  excessDisclaimerContainer: {
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      padding: 0,
      marginTop: 12,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
};

export default useStyles;
