import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export const useStyles = {
  icon: { fontSize: "1.25rem !important" } satisfies FontAwesomeIconProps["style"],
  iconUp: {
    position: "relative",
    top: "5px",
  } satisfies FontAwesomeIconProps["style"],
  iconDown: {
    position: "relative",
    top: "-4px",
  } satisfies FontAwesomeIconProps["style"],
};
