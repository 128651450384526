import { format } from "date-fns";
import { useLogger, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHandlePremiumChanges } from "../../../../shared/components/PremiumChangedDialog/useHandlePremiumChanges";
import { DATE_FORMAT_YYYY_MM_DD } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import { POLICY_PERSONAL_INFORMATION_PAGE_URL, QuoteRoutes, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../../../shared/routing/routes.config";
import { Values as YourQuoteValues } from "../../../YourQuote/types";
import { FormProps, Values } from "../../types";

const parseDate = (inDate: string | undefined | null | Date): Date | undefined => {
  if (!inDate) {
    return undefined;
  }

  if (Object.prototype.toString.call(inDate) === "[object Date]") {
    return inDate as Date;
  }

  return new Date(Date.parse(inDate as string));
};

export const useFurtherDetails = (): FormProps => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const handlePremiumChanges = useHandlePremiumChanges();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [sessionState, setSessionState] = useSessionState<Values>();
  const [yourQuote, setYourQuote] = useSessionState<YourQuoteValues>({
    specificKey: QuoteRoutes.YourQuote,
    skipPageTrackingRecalculation: true,
  });

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      policyStartDate:
        parseDate(sessionState.policyStartDate) || parseDate(yourQuote.quote?.startDate?.value) || new Date(),
    },
  });

  const onSubmit = async (values: Values) => {
    try {
      setBackdrop(true);
      const startDate = format(values.policyStartDate, DATE_FORMAT_YYYY_MM_DD) as unknown as Date;
      const request = {
        startDate: startDate,
      };
      setSessionState({ ...values, isCompleted: true });

      const response = await apiClient.furtherdetails(request);

      setYourQuote({
        ...yourQuote,
        quote: {
          ...yourQuote.quote,
          covers: [
            {
              ...yourQuote.quote.covers[0],
              premiumDetails: response.result.premiumDetails,
              excess: response.result.excess,
            },
          ],
        },
      });

      handlePremiumChanges({ ...response.result, nextPage: POLICY_PERSONAL_INFORMATION_PAGE_URL });
    } catch (ex) {
      logException({
        location: "useFurtherDetails.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  return {
    data: {
      maxStartDate: yourQuote.quote?.startDate?.max ?? new Date(),
    },
    form,
    onSubmit,
  };
};

export default useFurtherDetails;
