import { theme } from "@racwa/react-components";
import {
  HTTP_STATUS_CODE_SERVICE_UNAVAILABLE,
  useGetSessionState,
  useLogger,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import React, { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  PaymentFrequency,
  PaymentMethod,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import { PolicyRoutes, QuoteRoutes } from "../../../../shared/routing/routes.config";
import { Values as MemberDetailValues } from "../../../MemberDetails/types";
import { PaymentData as PaymentDataValues } from "../../../Payment/types";
import { Values as PersonalInformationValues } from "../../../PersonalInformation/types";
import useGetPaymentReferenceData from "../useGetPaymentReferenceData";

const NUMBER_OF_INPUT_FIELDS_WITHOUT_CVC = 4;
const NUMBER_OF_INPUT_FIELDS_WITH_CVC = 5;

let changedFields: string[] = [];

declare global {
  interface Window {
    QuickstreamAPI: any;
  }
}

const labelStyle = {
  color: theme.palette.text.secondary,
  "font-weight": 400,
  "font-family": "'Stag Sans Web', Helvetica, Arial, sans-serif",
  "font-size": "1.125rem",
  "margin-bottom": "0.5rem",
};

const inputStyle = {
  ...labelStyle,
  "border-radius": "3px",
  "box-shadow": "rgba(0, 0, 0, 0.075)",
  border: `1px solid ${theme.palette.grey["300"]}`,
  color: "rgb(24, 45, 60)",
  "font-size": "1.125rem",
  "font-weight": "300",
  "line-height": "21.3768px",
  padding: "10.5px 10px 10.5px 10px",
  margin: 0,
  height: "44.375px",
};

export const handleIssuePolicyException = (
  ex: any,
  setError: (isError: boolean) => void,
  onUnsuccessfulSubmit: (policyNumber: string) => void,
  onTryAgainCallback: (policyNumber?: string) => void,
  currentPolicyNumber?: string
) => {
  const apiException = ex as ApiException;

  if (apiException.status === HTTP_STATUS_CODE_SERVICE_UNAVAILABLE) {
    const policyNumber = apiException.result?.policyNumber ?? currentPolicyNumber;
    const hasRetryAttempt = !!Object.keys(apiException.headers).find((key) => key.toLowerCase() === "retry-after");
    if (hasRetryAttempt) {
      onTryAgainCallback(policyNumber);
      return;
    } else if (policyNumber) {
      onUnsuccessfulSubmit(policyNumber);
      return;
    }
  }

  setError(true);
};

export interface WestpacScriptResult {
  trustedFrame: React.MutableRefObject<TrustedFrame | null>;
  onSubmitCreditCard: () => void;
  showAuthorisationCard: boolean;
  isFormFilledOut: boolean;
  isError: boolean;
  isEditLocked: boolean;
  policyNumber: string;
  onDoneClick: () => void;
  onTryAgainClick: () => void;
}

interface QuickStreamEvent {
  eventType: string;
  fieldName: string;
  errorMessages?: string[];
}

interface TrustedFrame extends React.MutableRefObject<any | null> {
  submitForm(callBack: any): () => void;
  teardown: (callback?: (errors: Error[], data: any) => void) => void;
  setEventHandler: (
    fieldName: "cardholderName" | "cardNumber" | "expiryDateMonth" | "expiryDateYear" | "cvn",
    event: "focus" | "change" | "error" | "blur",
    handler: (event: QuickStreamEvent) => void
  ) => void;
}

function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

/**
 * TODO - SPK-4490 - Integrate RRL useWestpacScript
 */

const useWestpacScript = (
  isCreditCardSelected: boolean,
  isPaymentFrequencyMonthly: boolean,
  openTryAgainDialogFunction: (openTryAgainDialog: boolean) => void
): WestpacScriptResult => {
  const client = useApiClient();
  const setBackDrop = useSetBackdrop();
  const { paymentReferenceData, isError: paymentReferenceDataIsError } = useGetPaymentReferenceData();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const initialTrustedFrameState = React.createRef<TrustedFrame | null>();
  const trustedFrame = React.useRef<TrustedFrame | null>(initialTrustedFrameState.current);
  const [policyNumber, setPolicyNumber] = useState("");
  const [showAuthorisationCard, setShowAuthorisationCard] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEditLocked, setIsEditLocked] = useState(false);
  const [currentPaymentState, setPaymentState] = useSessionState<PaymentDataValues>();
  const tellUsMoreAboutYou = useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);
  const areYouAMember = useGetSessionState<MemberDetailValues>(QuoteRoutes.MemberDetails);

  const handleUnsuccessfulSubmit = (policyNumber: string) => {
    setPaymentState({
      isCompleted: true,
      isSuccessful: false,
      policyNumber,
      lockdownPreviousPage: false,
      annualCCPaymentInProgress: false,
    });
  };

  const onSubmitCreditCard = async () => {
    if (trustedFrame && trustedFrame.current) {
      trustedFrame.current.submitForm(async (errors: any, data: any) => {
        if (errors) {
          // Handle errors here
        } else {
          const singleUseTokenId = data.singleUseToken.singleUseTokenId;
          const form = document.getElementById("CreditCardpaymentform");
          window.QuickstreamAPI.creditCards.appendTokenToForm(form, singleUseTokenId);

          const memberName = tellUsMoreAboutYou
            ? tellUsMoreAboutYou.firstName
            : areYouAMember.firstName
            ? areYouAMember.firstName
            : "";

          const performPurchase = async () => {
            try {
              setBackDrop(true);
              const response = await client.payment({
                frequency: isPaymentFrequencyMonthly ? PaymentFrequency.Monthly : PaymentFrequency.Annual,
                method: PaymentMethod.Card,
                token: singleUseTokenId,
              });
              setPaymentState({
                isCompleted: true,
                isSuccessful: true,
                policyNumber: response.result.policyNumber,
                receiptNumber: response.result.receiptNumber,
                total: response.result.total,
                firstName: memberName,
                lockdownPreviousPage: false,
                annualCCPaymentInProgress: false,
              });
            } catch (ex) {
              logException({
                location: "useWestpacScript.onSubmitCreditCard.performPurchase",
                message: ex,
                customProperties: customLogProperties,
              });
              const handleTryAgain = (policyNumber?: string) => {
                setPolicyNumber(policyNumber ?? "");
                openTryAgainDialogFunction(true);
                setIsEditLocked(!!policyNumber);
                setPaymentState({
                  lockdownPreviousPage: !isPaymentFrequencyMonthly,
                  annualCCPaymentInProgress: !isPaymentFrequencyMonthly,
                  isSuccessful: false,
                  policyNumber: policyNumber ?? "",
                });
              };
              handleIssuePolicyException(
                ex,
                setIsError,
                handleUnsuccessfulSubmit,
                handleTryAgain,
                currentPaymentState.policyNumber
              );
            } finally {
              setBackDrop(false);
            }
          };

          await performPurchase();
        }
      });
    }
  };

  useEffect(() => {
    setShowAuthorisationCard(false);
    if (!isCreditCardSelected && trustedFrame.current) {
      trustedFrame.current.teardown();
      trustedFrame.current = null;
      changedFields = [];
    }
  }, [isCreditCardSelected]);

  useEffect(() => {
    let isMounted = true;

    const trackCompletion = (frame: TrustedFrame | null) => {
      if (!frame) {
        return;
      }

      changedFields = [];

      const trackChanges = (event: QuickStreamEvent) => {
        const newChangedFields = [...changedFields, event.fieldName].filter(onlyUnique);
        changedFields = newChangedFields;
        const fieldsCount = isPaymentFrequencyMonthly
          ? NUMBER_OF_INPUT_FIELDS_WITHOUT_CVC
          : NUMBER_OF_INPUT_FIELDS_WITH_CVC;
        setShowAuthorisationCard(changedFields.length === fieldsCount);
      };

      frame.setEventHandler("cardNumber", "change", trackChanges);
      frame.setEventHandler("cardholderName", "change", trackChanges);
      frame.setEventHandler("cvn", "change", trackChanges);
      frame.setEventHandler("expiryDateMonth", "change", trackChanges);
      frame.setEventHandler("expiryDateYear", "change", trackChanges);
    };

    const createTrustedFrameHandler = (errors: any, data: any) => {
      setShowAuthorisationCard(false);
      if (errors) {
        logException({
          location: "useWestpacScript.createTrustedFrameHandler",
          message: errors,
          customProperties: customLogProperties,
        });
      } else {
        trustedFrame.current = data.trustedFrame;
        trackCompletion(trustedFrame.current);
      }
    };

    if (
      isMounted &&
      window?.QuickstreamAPI &&
      isCreditCardSelected &&
      !paymentReferenceDataIsError &&
      paymentReferenceData?.westpacPublishableApiKey
    ) {
      changedFields = [];
      trustedFrame?.current?.teardown();
      window.QuickstreamAPI.init({ publishableApiKey: paymentReferenceData?.westpacPublishableApiKey });
      const height = isPaymentFrequencyMonthly ? "320px" : "420px";
      const options = {
        config: {
          supplierBusinessCode: "RACIAPI",
        },
        body: {
          style: {
            width: "100%",
            height: "100%",
          },
        },
        labels: {
          style: {
            ...labelStyle,
            "margin-bottom": "0.5rem",
          },
        },
        iframe: {
          width: "100%",
          height,
          style: {
            width: "100%",
            height,
          },
        },
        cardholderName: {
          label: "Name on card",
          placeholder: "e.g. John Smith",
          style: inputStyle,
        },
        cardNumber: {
          label: "Card number",
          placeholder: "e.g. 1234 5678 1234 5678",
          style: inputStyle,
        },
        expiryDateMonth: {
          style: inputStyle,
        },
        expiryDateYear: {
          style: inputStyle,
        },
        cvn: {
          hidden: isPaymentFrequencyMonthly,
          label: "CVC",
          showHelp: false,
          placeholder: "xxx",
          style: inputStyle,
        },
      };
      window.QuickstreamAPI.creditCards.createTrustedFrame(options, createTrustedFrameHandler);
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreditCardSelected,
    isPaymentFrequencyMonthly,
    paymentReferenceData?.westpacPublishableApiKey,
    paymentReferenceDataIsError,
    customLogProperties,
  ]);

  return {
    trustedFrame: trustedFrame,
    onSubmitCreditCard,
    showAuthorisationCard,
    isFormFilledOut: showAuthorisationCard,
    isError,
    isEditLocked,
    policyNumber,
    onDoneClick: () => handleUnsuccessfulSubmit(policyNumber),
    onTryAgainClick: () => openTryAgainDialogFunction(false),
  };
};

export default useWestpacScript;
