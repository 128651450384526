import { theme } from "@racwa/react-components";
import { colors } from "@racwa/styles";

export const useStyles = {
  radioGroup: {
    border: `1px solid ${colors.dieselLighter}`,
    borderRadius: "3px",
    padding: 0,
    "& :last-child": {
      borderBottom: "none",
    },
  },
  item: {
    padding: "6px 15px 4px 5px",
    borderBottom: `1px solid ${colors.dieselLighter}`,
    margin: 0,
  },
  itemSelected: {
    color: colors.white,
    backgroundColor: colors.dieselDeep,
    "& .Mui-checked": {
      color: colors.white,
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    "& > label": {
      borderColor: theme.palette.error.main,
    },
  },
};

export default useStyles;
