import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Dialog, Hidden, IconButton } from "@mui/material";
import { RacwaTooltip } from "@racwa/react-components";
import { colors } from "@racwa/styles";
import { useState } from "react";

const useStyles = {
  smallButton: {
    padding: "0 0 0 3px",
    width: "20px",
  },
};

const iconStyle = {
  color: colors.dieselDeeper,
  fontSize: "20px",
} satisfies FontAwesomeIconProps["style"];

export interface HelpTextTooltipProps {
  id?: string;
  titleXs?: string;
  title?: string;
  message?: string | React.ReactNode;
  smallButton?: boolean;
}

export const HelpTextTooltip: React.FC<HelpTextTooltipProps> = ({ id, titleXs, title, message, smallButton }) => {
  const classes = useStyles;
  const [openToolTipDialogue, setOpenToolTipDialogue] = useState(false);
  const buttonStyle = smallButton ? classes.smallButton : "";
  const titleElement =
    title && titleXs ? (
      <>
        <Hidden smUp implementation="css">
          {titleXs}
        </Hidden>
        <Hidden only={"xs"} implementation="css">
          {title}
        </Hidden>
      </>
    ) : (
      title
    );

  return (
    <>
      <RacwaTooltip
        id={id}
        aria-describedby={id}
        title={titleElement}
        message={message}
        open={openToolTipDialogue}
        onClick={() => setOpenToolTipDialogue(!openToolTipDialogue)}
        onClickClose={() => setOpenToolTipDialogue(false)}
        placement="top"
      >
        <IconButton
          data-testid={`${id}Button`}
          id={`${id}Button`}
          size={"medium"}
          arial-label="anchor"
          sx={{ root: buttonStyle }}
        >
          <FontAwesomeIcon style={iconStyle} icon={faQuestionCircle} aria-hidden />
        </IconButton>
      </RacwaTooltip>
      <Dialog open={openToolTipDialogue} onClose={() => setOpenToolTipDialogue(false)} />
    </>
  );
};

export default HelpTextTooltip;
