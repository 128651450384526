import { Grid, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { MAX_CUSTOM_MARKET_VALUE, MIN_CUSTOM_MARKET_VALUE } from "../../shared/constants";
import useFormotiv from "../../shared/hooks/useFormotiv";
import SelectCaravan from "./components/SelectCaravan";
import SelectManufacturer from "./components/SelectManufacturer";
import MarketValueInput from "./components/SelectMarketValue";
import SelectModel from "./components/SelectModel";
import SelectYear from "./components/SelectYear";
import { FormProps } from "./types";
import { CallToActionButton } from "../../shared/components/CallToActionButon";

export const minMarketValue = MIN_CUSTOM_MARKET_VALUE;
const maxMarketValue = MAX_CUSTOM_MARKET_VALUE;

export const Form: React.FC<FormProps> = ({ form, data, dispatcher, onSubmit }) => {
  const showCaravanSelection = (data.caravans?.length || 0) > 0;
  const caravan = form.watch("caravan");
  const showMarketValueInput = (caravan && caravan.marketValue < minMarketValue) ?? false;
  const onSubmitWrapper = useFormotiv(onSubmit);

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Let's start with your caravan
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectManufacturer options={data.manufacturers} onChange={() => dispatcher("manufacturer")} />
          </Grid>
          <Grid item xs={12}>
            <SelectYear options={data.years} onChange={() => dispatcher("year")} />
          </Grid>
          <Grid item xs={12}>
            <SelectModel options={data.models} onChange={() => dispatcher("model")} />
          </Grid>
          <Grid item xs={12} sx={showCaravanSelection ? {display: "block"} : {display: "none"}}>
            <SelectCaravan options={data.caravans} onChange={() => dispatcher("caravan")} />
          </Grid>
          <Grid item xs={12} sx={showMarketValueInput ? {display: "block"} : {display: "none"}}>
            <MarketValueInput
              isCustomMarketValueRequired={showMarketValueInput}
              min={minMarketValue}
              max={maxMarketValue}
            />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
            >
              Next
            </CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
