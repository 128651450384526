import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Controller, useFormContext } from "react-hook-form";
import { pleaseSelectAMessage } from "../../../../shared/constants/HelperText";
import { Values } from "../../types";

const label = "Year";
const name = "year";
const inputId = "input-year";
const inputContainerId = `${inputId}-container`;

export interface SelectYearProps {
  options?: number[];
  onChange?: () => void;
}

export const SelectYear: React.FC<SelectYearProps> = ({ options, onChange }) => {
  const { control } = useFormContext<Values>();
  const isOnlyOneItem = options?.length === 1;
  const isLoading = options === undefined;
  const isEmpty = options?.length === 0;

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: { value: !isEmpty, message: pleaseSelectAMessage(name) },
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label}
          aria-label={label}
          placeholder="YYYY"
          options={options || []}
          getOptionLabel={(option) => option.toString()}
          autoSelect={isOnlyOneItem}
          autoComplete={true}
          autoHighlight={true}
          clearOnEscape={true}
          loading={isLoading}
          disabled={isEmpty}
          inputMode="numeric"
          onChange={(_, value) => {
            window.formotiv && window.formotiv.inputActivity(window.formotivConfig, name, "autocomplete", value);
            onChange && onChange();
            onComponentChange(value);
          }}
          onBlur={() => {
            gtm(fieldTouched(label));
            onBlur && onBlur();
          }}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
        />
      )}
    />
  );
};

export default SelectYear;
