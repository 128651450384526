import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { colors } from "@racwa/styles";

export const useStyles = {
  summaryTopHeading: {
    margin: "10px 0 10px 0",
  },
  summaryTop: {
    backgroundColor: colors.white,
    "& .MuiDivider-root": {
      margin: "20px 0 20px 0",
    },
    "& p": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
  summaryBottom: {
    backgroundColor: colors.white,
    "& .MuiDivider-root": {
      margin: "20px 0 20px 0",
    },
    "& p": {
      paddingTop: "2.5px",
      paddingBottom: "2.5px",
    },
  },
  iconDown: {
    marginLeft: "5px",
    position: "relative",
    bottom: "1px",
  } satisfies FontAwesomeIconProps["style"],
  iconUp: {
    marginLeft: "5px",
    position: "relative",
    top: "3px",
  } satisfies FontAwesomeIconProps["style"],
  link: {
    fontSize: "1rem",
    textDecoration: "underline",
    marginLeft: "20px",
    "& + a": {
      marginLeft: "2px",
    },
  },
  linkPencil: {
    fontSize: "1rem",
    textDecoration: "underline",
    marginLeft: "30px",
    "& + a": {
      marginLeft: "2px",
    },
    paddingRight: "5px",
  },
  disabled: {
    color: colors.racGray,
    pointerEvents: "none",
  },
  text: {
    color: colors.dieselDeeper,
    fontWeight: 400,
    lineHeight: "144%",
  },
  textAlignRight: {
    textAlign: "right",
  },
  disclaimer: {
    marginTop: "20px",
  },
};
