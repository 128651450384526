import { useGetSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Values as AboutYouValues } from "../../../views/AboutYou/types";
import { Values as JointPolicyholderInformationValues } from "../../../views/JointPolicyHolderInformation/types";
import { PaymentData as PaymentDataValues } from "../../../views/Payment/types";
import { Values as PersonalInformationValues } from "../../../views/PersonalInformation/types";
import { Values as YourQuoteValues } from "../../../views/YourQuote/types";
import { YES } from "../../constants";
import {
  allRoutes,
  PolicyRoutes,
  policyRoutesArray,
  QuoteRoutes,
  quoteRoutesArray,
  UTILITY_RETRIEVE_QUOTE_PAGE_URL,
  RouteInformation,
} from "../routes.config";

const jointPolicyholderName = "Joint policyholder";
const membershipLevelName = "Membership level";
const jointMembershipLevelName = "Joint policyholder's membership level";

export interface StepInformation {
  name: string;
  path: string;
}

export interface UseRoutesResults {
  isQuoteOrPurchaseFlow: boolean;
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  isCompleted: boolean;
  isSuccessful: boolean;
  lockdownPreviousPage: boolean;
  isRetrieveQuote: boolean;
  navigateToPreviousStep?: () => void;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const isQuoteOrPurchaseFlow =
    [...quoteRoutesArray, ...policyRoutesArray].filter((item) => item.path === location.pathname).length > 0;
  const { lockdownPreviousPage, isCompleted, isSuccessful } = useGetSessionState<PaymentDataValues>(
    PolicyRoutes.Payment,
  );
  const { addAdditionalPolicyholder } = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const { captureMembershipLevel: captureMainPolicyholderMembershipLevel } =
    useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);
  const { captureMembershipLevel: captureJointPolicyholderMembershipLevel } =
    useGetSessionState<JointPolicyholderInformationValues>(PolicyRoutes.JointPolicyholder);
  const { quote } = useGetSessionState<YourQuoteValues>(QuoteRoutes.YourQuote);

  const hasQuoteNumber = !!quote?.quoteNumber;

  const allFormRoutes = [...quoteRoutesArray, ...policyRoutesArray].filter(
    (value) =>
      (value.name === jointPolicyholderName && addAdditionalPolicyholder === YES) ||
      (value.name === membershipLevelName && captureMainPolicyholderMembershipLevel === true) ||
      (value.name === jointMembershipLevelName && captureJointPolicyholderMembershipLevel === true) ||
      (value.name !== jointPolicyholderName &&
        value.name !== membershipLevelName &&
        value.name !== jointMembershipLevelName),
  );

  const steps: StepInformation[] = useMemo(() => {
    return (
      (hasQuoteNumber && [...allFormRoutes]) ||
      (quoteRoutesArray.find((route) => route.path === location.pathname) && [...quoteRoutesArray]) ||
      (policyRoutesArray.find((route) => route.path === location.pathname) && [...policyRoutesArray]) ||
      []
    );
  }, [allFormRoutes, hasQuoteNumber, location.pathname]);

  const activeStepIndex = isQuoteOrPurchaseFlow
    ? steps.findIndex((item) => item.path === location.pathname)
    : undefined;

  const totalStepCount = isQuoteOrPurchaseFlow ? steps.length : undefined;
  const previousIndex = allFormRoutes.findIndex((route) => route.path === location.pathname) - 1;
  const navigateToPreviousStep =
    previousIndex > -1
      ? () => {
          navigate(allFormRoutes[previousIndex].path, { replace: true });
        }
      : undefined;
  const isRetrieveQuote = location.pathname === UTILITY_RETRIEVE_QUOTE_PAGE_URL;

  return {
    isQuoteOrPurchaseFlow,
    steps,
    formRoutes: allFormRoutes,
    activeStepIndex,
    totalStepCount,
    lockdownPreviousPage: lockdownPreviousPage ?? false,
    isCompleted: isCompleted ?? false,
    isSuccessful: isSuccessful ?? false,
    navigateToPreviousStep,
    isRetrieveQuote,
  };
};
export default useRoutes;
