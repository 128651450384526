import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaAutocomplete } from "@racwa/react-components";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/HelperText";
import { Manufacturer } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { Values } from "../../types";

const label = "Make";
const inputId = "input-manufacturer";
const inputContainerId = `${inputId}-container`;

export interface SelectManufacturerProps {
  options?: Manufacturer[];
  onChange?: () => void;
}

export const SelectManufacturer: React.FC<SelectManufacturerProps> = ({ options, onChange }) => {
  const { control } = useFormContext<Values>();
  return (
    <Controller
      control={control}
      name="manufacturer"
      rules={{
        required: { value: true, message: RequiredSelection },
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <RacwaAutocomplete
          {...props}
          id={inputId}
          data-testid={inputContainerId}
          label={label}
          aria-label={label}
          placeholder="Start typing your make"
          options={options || ([] as Manufacturer[])}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          groupBy={(option) => (option.isPopular ? "Most popular makes" : "Other")}
          fullWidth
          autoComplete={true}
          autoHighlight={true}
          clearOnEscape={true}
          loading={options === undefined}
          onChange={(_, value) => {
            window.formotiv &&
              window.formotiv.inputActivity(window.formotivConfig, "manufacturer", "autocomplete", value);
            onChange && onChange();
            onComponentChange(value);
          }}
          onBlur={() => {
            gtm(fieldTouched(label));
            onBlur && onBlur();
          }}
          textFieldProps={{ name: inputId, "data-testid": inputId }}
          error={!!error}
          helperText={error?.message}
          inputRef={ref}
        />
      )}
    />
  );
};

export default SelectManufacturer;
