import { Grid } from "@mui/material";
import { BsbInput as BSB } from "raci-react-library";
import { BsbLookupResponse } from "../../types";
import AccountName from "./AccountName";
import AccountNumber from "./AccountNumber";

export interface BankAccountData {
  bsb?: string;
  accountNumber?: string;
  accountName?: string;
}

interface BankAccountProps extends BankAccountData {
  onBsbLookup: (bsbNumber: string) => Promise<BsbLookupResponse>;
}

export const BankAccount: React.FC<BankAccountProps> = ({ onBsbLookup }) => {
  return (
    <div data-testid="bankAccountContainer" data-quickstream-api="bankAccountContainer" data-hj-suppress>
      <Grid item xs={12}>
        <BSB onBsbLookup={onBsbLookup} />
      </Grid>
      <Grid item xs={12}>
        <AccountNumber />
      </Grid>
      <Grid item xs={12}>
        <AccountName />
      </Grid>
    </div>
  );
};

export default BankAccount;
