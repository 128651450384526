export const useStyles = {
  containerRow: {
    flexDirection: "row",
    margin: 0,
    "& .MuiFormControl-marginNormal": {
      margin: 0,
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.875rem",
    },
    "& .MuiFormLabel-root.MuiFormLabel-filled": {
      margin: 0,
    },
  },
  tooltip: {
    textAlign: "right",
  },
};

export default useStyles;
