import {
  FormControl,
  FormHelperText,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  Typography,
} from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import React from "react";
import { Control, Controller, useFormContext, type RegisterOptions } from "react-hook-form";
import { RequiredYesNoMessage } from "../../constants/HelperText";
export interface YesNoButtonGroupProps extends ToggleButtonGroupProps {
  id?: string;
  label?: string;
  subtitle?: string;
  disabled?: boolean;
  control?: Control<any>;
  inputRef?: React.Ref<any>;
  value?: string | null;
  name?: string | null;
  validate?: RegisterOptions["validate"];
}

export const YesNoButtonGroup: React.FC<YesNoButtonGroupProps> = ({
  id = "",
  label,
  subtitle,
  disabled,
  name,
  validate,
}) => {
  const componentName = name ?? "yesNoButtonGroup";
  const inputId = `input-${id ?? componentName}`;
  const labelId = `label-${id ?? componentName}`;
  const subTitleId = "yesNoSubtitle";
  const { control } = useFormContext();

  const renderSubtitle = subtitle ? (
    <Typography variant="body2" sx={{marginTop: "5px", marginBottom: "5px", fontSize: "1rem"}} id={subTitleId} data-testid={subTitleId}>
      {subtitle}
    </Typography>
  ) : null;

  const renderLabel = label ? (
    <FormLabel id={labelId} component="legend" sx={{marginTop: "5px", marginBottom: "12px", fontSize: "1.125rem"}}>
      {label}
      {renderSubtitle}
    </FormLabel >
  ) : null;

  return (
    <Controller
      control={control}
      name={componentName}
      rules={{
        required: { value: true, message: RequiredYesNoMessage },
        validate,
      }}
      render={({ field: { onBlur, onChange: onChangeComponent, ...props }, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} fullWidth={true}>
          {renderLabel}
          <ToggleButtonGroup
            {...props}
            exclusive
            id={inputId}
            data-testid={inputId}
            aria-labelledby={label ? labelId : undefined}
            aria-label={label ? undefined : "Yes No?"}
            onChange={(_: any, value: string) => {
              const inputNameSuffix = value ?? props.value;
              if (window.formotiv) {
                const fieldName = inputNameSuffix.toLowerCase() === "yes" ? `${name}_y` : `${name}_n`;
                window.formotiv.inputActivity?.(window.formotivConfig, fieldName, "buttongroup", inputNameSuffix);
              }

              onChangeComponent && onChangeComponent(value);
            }}
            onBlur={() => {
              if (label != null) {
                gtm(fieldTouched(label));
              }
              onBlur && onBlur();
            }}
          >
            <ToggleButton
              type="button"
              role="button"
              value="yes"
              aria-label="Yes"
              data-testid="yesButton"
              disabled={disabled}
            >
              Yes
            </ToggleButton>
            <ToggleButton
              type="button"
              role="button"
              value="no"
              aria-label="No"
              data-testid="noButton"
              disabled={disabled}
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
          {error?.message ? <FormHelperText>{error.message}</FormHelperText> : null}
        </FormControl>
      )}
    />
  );
};

export default YesNoButtonGroup;
