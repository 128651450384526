import { Link, MenuItem, Typography } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import { RacwaSelect } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../constants/HelperText";
import { ReferenceDataOption } from "../../hooks/useApiClient/ClientProxy.generated";
import InformationCard from "../InformationCard";

const label = "What RAC membership level";
const inputId = "select-membership-level";
const inputContainerId = `${inputId}-container`;

export interface SelectMembershipProps {
  memberName?: string | null;
  options?: ReferenceDataOption[];
}

const SelectMembership: React.FC<SelectMembershipProps> = ({ options, memberName }) => {
  const { control } = useFormContext();
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  const membershipLevelOptions =
    options &&
    options.map((option) => {
      const optionProps = {
        id: option.externalCode,
        key: option.externalCode,
        value: option.externalCode,
        "data-testid": `membershipLevelOptionTest${option.externalCode}`,
      };
      return isMobileOnly ? (
        <option {...optionProps}>{option.description}</option>
      ) : (
        <MenuItem {...optionProps}>{option.description}</MenuItem>
      );
    });

  if (membershipLevelOptions && isMobileOnly) {
    membershipLevelOptions.unshift(
      <option key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </option>,
    );
  } else if (membershipLevelOptions && !isMobileOnly) {
    membershipLevelOptions.unshift(
      <MenuItem key="disabled" disabled selected hidden aria-hidden value="">
        Please select
      </MenuItem>,
    );
  }

  const labelToDisplay = memberName ? `${label} is ${memberName}?` : `${label} are you?`;

  const informationCardMessage = memberName ? (
    <>
      <Typography variant="body2">
        Members can confirm their RAC membership level by checking their RAC card, logging in to{" "}
        <Link href={process.env.REACT_APP_RAC_LOGIN_PAGE} target="_blank" rel="noreferrer noopener">
          myRAC
        </Link>{" "}
        or calling us on <InsurancePhoneNumberLink id="call us link" />. Choosing 'Not sure' means you'll miss out on any discounts {memberName} is
        eligible for.
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="body2">
        You can check your RAC card, login to{" "}
        <Link href={process.env.REACT_APP_RAC_LOGIN_PAGE} target="_blank" rel="noreferrer noopener">
          myRAC
        </Link>{" "}
        or call us on <InsurancePhoneNumberLink id="call us link" /> to confirm your RAC membership level. Choosing 'Not sure' means you'll miss out on any
        eligible discounts.
      </Typography>
    </>
  );

  return (
    <Controller
      control={control}
      name="membershipLevel"
      aria-label={labelToDisplay}
      rules={{
        required: { value: true, message: RequiredSelection },
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <>
          <RacwaSelect
            {...props}
            id={inputId}
            data-testid={inputContainerId}
            label={labelToDisplay}
            aria-label={labelToDisplay}
            native={isMobileOnly}
            fullWidth
            displayEmpty
            onChange={(e) => {
              const membershipLevel = e.target.value as string;
              setDisplayMessage(membershipLevel === "NotSure");
              onComponentChange && onComponentChange(membershipLevel);
              gtm(event(membershipLevel));
            }}
            onBlur={() => {
              gtm(fieldTouched("RAC membership level"));
              onBlur && onBlur();
            }}
            value={props.value ?? ""}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
          >
            {membershipLevelOptions}
          </RacwaSelect>
          {displayMessage ? (
            <InformationCard severity={"info"} title={"Please note"} informationHtml={informationCardMessage} />
          ) : null}
        </>
      )}
    />
  );
};

export default SelectMembership;
