import { FormControlLabel, Radio } from "@mui/material";
import { Caravan } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useStyles from "./styles";

export interface CaravanItemProps {
  index: number;
  caravan: Caravan;
  selected?: boolean | null;
}

export const CaravanItem: React.FC<CaravanItemProps> = ({ index, caravan, selected }) => {
  const classes = useStyles;
  const inputId = `input-caravan-${index}`;
  const selectedClass = selected ? classes.itemSelected : {};
  
  return (
    <FormControlLabel
      id={inputId}
      data-testid={inputId}
      value={caravan.id}
      control={<Radio checked={selected || false} />}
      label={caravan.description}
      sx={{ ...classes.item, ...selectedClass }}
    />
  );
};
