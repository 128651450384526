import { BasicTooltipProps } from "raci-react-library";
import { useState } from "react";
import { CoverType, NumberOptions } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { CoverValues } from "../../types";
import ExcessDialog from "../ExcessDialog";
import SelectAmountAdjustment from "../SelectAmountAdjustment";

export interface ExcessAdjustmentProps extends Omit<NumberOptions, "value"> {
  onChange: (value: CoverValues, forceSave?: boolean) => Promise<void>;
  coverType: CoverType;
}

export const ExcessAdjustment: React.FC<ExcessAdjustmentProps> = ({ onChange, options, coverType }) => {
  const [showDialog, setShowDialog] = useState(false);
  const labelTitle = "Basic excess";
  const tooltipProps: BasicTooltipProps = {
    customOnClick: () => setShowDialog(true),
    title: labelTitle,
  };

  return (
    <>
      {options && (
        <>
          <SelectAmountAdjustment
            id="excess"
            label={labelTitle}
            subLabel="Please check your policy documents for any extra excesses that may apply"
            name={"excess"}
            tooltipProps={tooltipProps}
            options={options}
            onChange={onChange}
            coverType={coverType}
          />
          <ExcessDialog showDialog={showDialog} setShowDialog={setShowDialog} />
        </>
      )}
    </>
  );
};

export default ExcessAdjustment;
