export const useStyles = {
  hidden: {
    height: "0 !important",
    width: "0 !important",
    padding: "0 !important",
    margin: "0 !important",
    border: "0 !important",
    visibility: "hidden",
    display: "none !important",
  },
};

export default useStyles;
