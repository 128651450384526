const styles = {
  GridContainer: {
    alignItems: "center",
    paddingTop: "24px",
  },
  MessageContainer: {
    display: "flex",
    paddingBottom: "24px",
  },
  SelectContainer: {
    display: "flex",
  },
};

export default styles;
