import { useSessionIdStorageKey } from "raci-react-library";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { useMemo } from "react";
import { ApiClient } from "./ClientProxy.generated";

export type ClientOptions = { fetch: (url: RequestInfo, init?: RequestInit) => Promise<Response> };

export interface UseApiClientProps<T> {
  Client: { new (baseUrl: string, http: ClientOptions): T };
  apiBaseUrl: string;
}

let useRawApiClient: () => ApiClient;
let useRawLookupApiClient: () => LookupServiceApiClient;

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT"
) {
  const { useApiClient, useLookupApiClient } = require("../../../__NPE__/useApiClient");
  useRawApiClient = useApiClient;
  useRawLookupApiClient = useLookupApiClient;
} else {
  const useApi = <T>({ Client, apiBaseUrl }: UseApiClientProps<T>) => {
    const sessionIdKey = useSessionIdStorageKey();
    return useMemo(() => {
      const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
        const headers: any = { ...init?.headers };
        const sessionId = sessionStorage.getItem(sessionIdKey);
        if (sessionId) {
          headers.SessionId = sessionId;
        }
        const response = await fetch(url, {
          ...init,
          headers,
        });
        return response;
      };

      return new Client(apiBaseUrl, {
        fetch: fetchWrapper,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };
  useRawApiClient = () => {
    return useApi({ Client: ApiClient, apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "" });
  };

  useRawLookupApiClient = () => {
    return useApi({ Client: LookupServiceApiClient, apiBaseUrl: process.env.REACT_APP_LOOKUP_SERVICE_API_URL || "" });
  };
}

const useApiClient = () => useRawApiClient();
const useLookupApiClient = () => useRawLookupApiClient();

export { useApiClient, useLookupApiClient };
export default useApiClient;
