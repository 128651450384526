import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SummaryItem from "../SummaryItem";
import { QUOTE_ABOUT_YOU_PAGE_URL } from "../../../../../../shared/routing/routes.config";

const componentTestId = "policySummary-drivingHistory";

const DrivingHistoryContent: React.FC = () => {
  return (
    <>
      <Typography variant="body2" data-testid={`${componentTestId}-noAccidents`}>
        No accidents or claims in the past 3 years
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-noSuspension`}>
        No cancellation, suspension or special conditions applied to drivers licence in the past 3 years
      </Typography>
    </>
  );
};

export const DrivingHistorySummaryItem: React.FC = () => {
  const navigate = useNavigate();
  const redirectToAboutYouPage = () => {
    navigate(QUOTE_ABOUT_YOU_PAGE_URL);
  };

  return (
    <>
      <Grid container alignItems="center" data-testid="drivingHistoryItem">
        <SummaryItem title="Driving history" name="AboutYou" onClick={redirectToAboutYouPage}>
          <DrivingHistoryContent />
        </SummaryItem>
      </Grid>
    </>
  );
};

export default DrivingHistorySummaryItem;
