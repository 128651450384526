import { Box, FormControl, FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import { BasicTooltip, BasicTooltipProps, currencyFormatter } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";
import { CoverType, NumberOptions } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { CoverValues } from "../../types";
import styles from "./styles";
import { fieldTouched, gtm } from "@racwa/analytics";

export interface SelectAmountAdjustmentProps extends Omit<NumberOptions, "value"> {
  id: string;
  label: string;
  subLabel: string;
  name: string;
  tooltipProps: Omit<BasicTooltipProps, "id">;
  coverType: CoverType;
  onChange: (value: CoverValues, forceSave?: boolean) => Promise<void>;
}

export const createId = (prefix: string, suffix: string) => {
  return `${prefix}-${suffix}`;
};

export const SelectAmountAdjustment: React.FC<SelectAmountAdjustmentProps> = ({
  id,
  label,
  subLabel,
  name,
  tooltipProps,
  options,
  onChange,
  coverType,
}: SelectAmountAdjustmentProps) => {
  const { control, getValues } = useFormContext();

  const labelId = createId(id, "label");
  const dropdownId = createId(id, "dropdown");
  const tooltipContainerId = createId(id, "tooltip");
  const tooltipId = createId(tooltipContainerId, "id");

  const tooltipPropsWithId = { ...tooltipProps, id: tooltipId } as BasicTooltipProps;

  return options && options.length > 1 ? (
    <Grid item xs={12}>
      <Controller
        control={control}
        aria-label={id}
        name={name}
        render={({ field: { onChange: onChangeComponent, ...props }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth margin="none">
            <Box mt={0} mb={1}>
              <Grid container sx={styles.GridContainer}>
                <Grid item xs={5}>
                  <FormLabel id={labelId} htmlFor={dropdownId}>
                    {label}
                  </FormLabel>
                </Grid>
                <Grid item xs={7} sx={styles.SelectContainer}>
                  <Box mr={1} id={tooltipContainerId} data-testid={tooltipContainerId}>
                    <BasicTooltip {...tooltipPropsWithId} />
                  </Box>
                  <Select
                    id={dropdownId}
                    labelId={labelId}
                    fullWidth
                    value={props.value}
                    onChange={async (e: any) => {
                      const value = e.target.value;
                      gtm(fieldTouched(label));
                      onChangeComponent && onChangeComponent(value as number);
                      const request = {
                        ...getValues(),
                        type: coverType,
                        excess: value,
                      };
                      await onChange(request as CoverValues, true);
                    }}
                  >
                    {options?.map((item: number) => (
                      <MenuItem key={item} value={item}>
                        {currencyFormatter(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        )}
      />
      <Grid item xs={12} sx={styles.MessageContainer}>
        <Typography variant="body2">{subLabel}</Typography>
      </Grid>
    </Grid>
  ) : null;
};

export default SelectAmountAdjustment;
