import { Grid, Link, Typography } from "@mui/material";
import HelpTextTooltip from "../../../../shared/components/HelpTextToolTip";

export const ExcessTooltip = () => {
  const PEDLink = () => {
    return (
      <Link
        id="pedLink"
        data-testid="pedLink"
        variant="inherit"
        color="inherit"
        href={process.env.REACT_APP_RAC_PDS_URL}
        target="_blank"
        rel="noreferrer noopener"
      >
        PED Guide
      </Link>
    );
  };
  return (
    <HelpTextTooltip
      id="chooseYourExcessTooltip"
      data-testid="chooseYourExcessTooltip"
      title="Excesses"
      message={
        <>
          <Typography variant="body2">
            <b>
              An excess is an amount you may have to contribute when making a claim. If you adjust your excess, your
              policy premium will change.
            </b>
          </Typography>
          <br />
          <Typography variant="body2">
            <b>An additional age excess applies to drivers and policyholders under 26 years of age.</b>
          </Typography>
          <br />
          <Typography variant="body2">
            <Grid container>
              <Grid item xs={10}>
                Individual under 19 years of age
              </Grid>
              <Grid item xs={2}>
                $650
              </Grid>
              <Grid item xs={10}>
                Individual aged 19 - 20 years
              </Grid>
              <Grid item xs={2}>
                $550
              </Grid>
              <Grid item xs={10}>
                Individual aged 21 - 23 years
              </Grid>
              <Grid item xs={2}>
                $450
              </Grid>
              <Grid item xs={10}>
                Individual aged 24 - 25 years
              </Grid>
              <Grid item xs={2}>
                $300
              </Grid>
            </Grid>
          </Typography>
          <br />
          <Typography variant="body2">
            <b>
              For more details, see our <PEDLink />.
            </b>
          </Typography>
        </>
      }
    />
  );
};

export default ExcessTooltip;
