import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import React from "react";

export interface NeedMoreInformationCardNotificationProps {
  quoteNumber: string;
  show?: boolean | null;
}

export const NeedMoreInformationCardNotification: React.FC<NeedMoreInformationCardNotificationProps> = ({
  quoteNumber,
  show = true,
  ...props
}) => {
  const dataTestId = "needMoreInfoCard";
  const title = "We need some more information";

  return show ? (
    quoteNumber ? (
      <RacwaCardNotification
        {...props}
        severity="error"
        title={title}
        data-testid={dataTestId}
        icon={<FontAwesomeIcon icon={faPhone} />}
      >
        Please call <InsurancePhoneNumberLink id="call us link" /> to finish your quote. Your reference number is{" "}
        <strong id="need-more-info-quote-number" data-testid="needMoreInfoQuoteNumber">
          {quoteNumber}
        </strong>
        {"."}
      </RacwaCardNotification>
    ) : (
      <RacwaCardNotification
        {...props}
        severity="error"
        title={title}
        data-testid={dataTestId}
        icon={<FontAwesomeIcon icon={faPhone} />}
      >
        Please call <InsurancePhoneNumberLink id="call us link" /> to finish your quote.
      </RacwaCardNotification>
    )
  ) : null;
};

export default NeedMoreInformationCardNotification;
