import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { colors } from "@racwa/styles";
import { useGetSessionState } from "raci-react-library";
import { PolicyRoutes } from "../../../../../../shared/routing/routes.config";
import { PaymentData as PaymentDataValues } from "./../../../../../Payment/types";

export interface SummaryItemProps extends React.ComponentProps<any> {
  title?: string;
  name: string;
  onClick?: () => void;
  gtmEvent?: string;
}

export const SummaryItem: React.FC<SummaryItemProps> = ({ title, children, name, onClick, gtmEvent }) => {
  const { lockdownPreviousPage: disabledLink } = useGetSessionState<PaymentDataValues>(PolicyRoutes.Payment);
  const onLinkClick = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    gtmEvent && gtm(event(gtmEvent));
    if (!disabledLink) {
      onClick && onClick();
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row" width="100%">
        <Box width="100%">
          {title && (
            <Typography variant="h4" sx={{ margin: "10px 0 10px 0" }}>
              {title}
            </Typography>
          )}
          {children}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" paddingTop={title ? "31.98px" : "0px"}>
          <Link
            data-testid={`pencilLinkTo${name}Page`}
            sx={
              disabledLink
                ? {
                  color: colors.racGray,
                    pointerEvents: "none",
                  }
                : {
                    fontSize: "1rem",
                    textDecoration: "underline",
                    marginLeft: "30px",
                    "& + a": {
                      marginLeft: "2px",
                    },
                    color: colors.linkBlue,
                    paddingRight: "5px",
                  }
            }
            variant="inherit"
            color="inherit"
            href="#"
            underline="none"
            id="editLink"
            onClick={onLinkClick}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Link>{" "}
          <Link
            data-testid={`htmlLinkTo${name}Page`}
            sx={
              disabledLink
                ? {
                    color: colors.racGray,
                    pointerEvents: "none",
                  }
                : {
                    fontSize: "1rem",
                    textDecoration: "underline",
                    marginLeft: "20px",
                    "& + a": {
                      marginLeft: "2px",
                    },
                    color: colors.linkBlue
                  }
            }
            variant="inherit"
            color="inherit"
            href="#"
            underline="none"
            id="editLink"
            onClick={onLinkClick}
          >
            Edit
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default SummaryItem;
