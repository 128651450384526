import { colors } from "@racwa/styles";

export const useStyles = {
  icon: {
    color: colors.dieselDeeper,
  },
  text: {
    marginLeft: "5px",
    color: colors.dieselDeeper,
  },
};

export default useStyles;
