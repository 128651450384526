import { CoverType, Discount } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import DiscountItem from "../DiscountItem";
import { StyledGrid } from "./styled";
import { PropsWithChildren } from "react";

export interface DiscountListProps {
  coverType: CoverType;
  items?: Discount[];
}

export const DiscountList: React.FC<PropsWithChildren<DiscountListProps>> = ({ coverType, items = [], children }) => {

  return (
    <StyledGrid container>
      {items.map((item, index) => (
        <DiscountItem key={index} id={`${coverType}-${index}`} text={item.description} />
      ))}
      {children}
    </StyledGrid>
  );
};

export default DiscountList;
