import {
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  useGetSessionState,
  useLogger,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHandlePremiumChanges } from "../../../../shared/components/PremiumChangedDialog/useHandlePremiumChanges";
import { YES } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  ApiException,
  Gender,
  PolicyholderType,
  Title,
  UpdateContactDetailsRequest,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import {
  PolicyRoutes,
  POLICY_JOINT_POLICYHOLDER_PAGE_URL,
  POLICY_MEMBERSHIP_LEVEL_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  POLICY_PAYMENT_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { getGenderOrDefault } from "../../../../shared/utils";
import { Values as AboutYouValues } from "../../../AboutYou/types";
import { Values as MemberDetailsValues } from "../../../MemberDetails/types";
import { FormProps, Values } from "../../types";
import useReferenceData from "../useReferenceData";

export const usePersonalInformation = (): FormProps => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const handlePremiumChanges = useHandlePremiumChanges();
  const [sessionState, setSessionState] = useSessionState<Values>();
  const [showMoreInfoCard, setShowMoreInfoCard] = useState(false);
  const { isTitleAndGenderRequired, postalAddress, surname, title, gender, isLoading } = useReferenceData();
  const setBackdrop = useSetBackdrop();
  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const memberDetailsState = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const hasMemberMatched = memberDetailsState.areYouAMember === YES;
  const isMultiMatch = hasMemberMatched && memberDetailsState.isMultiMatch === true;

  const { addAdditionalPolicyholder } = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const hasAdditionalJointPolicyholder = addAdditionalPolicyholder === YES;

  const mainPolicyholderFirstName = useGetSessionState<Values>(PolicyRoutes.PersonalInformation).firstName;

  const [membershipLevelState, setMembershipLevelState] = useSessionState({
    specificKey: PolicyRoutes.MembershipLevel,
    skipPageTrackingRecalculation: true,
  });
  const skipMembershipLevelPage = () => setMembershipLevelState({ ...membershipLevelState, isCompleted: true });

  const [jointPolicyholderState, setJointPolicyholderState] = useSessionState({
    skipPageTrackingRecalculation: true,
    specificKey: PolicyRoutes.JointPolicyholder,
  });
  const skipJointPolicyholderPage = () => setJointPolicyholderState({ ...jointPolicyholderState, isCompleted: true });

  const [jointMembershipLevelState, setJointMembershipLevelState] = useSessionState({
    skipPageTrackingRecalculation: true,
    specificKey: PolicyRoutes.JointMembershipLevel,
  });
  const skipJointMembershipLevelPage = () =>
    setJointMembershipLevelState({ ...jointMembershipLevelState, isCompleted: true });

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted && postalAddress) {
      form.setValue("mailingAddress", postalAddress);
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalAddress]);

  const createRequest = (values: Values) => {
    const request: UpdateContactDetailsRequest = {
      mailingAddress: values.mailingAddress?.moniker || "",
      policyholderType: PolicyholderType.Main,
    };

    if (isTitleAndGenderRequired) {
      request.title = values.title as Title | undefined;
      request.gender = getGenderOrDefault(values.title as Title | undefined, values.gender as Gender | undefined);
    }

    if (isMultiMatch || !hasMemberMatched) {
      request.surname = values.lastName;
    }

    if (!hasMemberMatched) {
      request.firstName = values.firstName;
      request.email = values.email;
      request.contactNumber = values.contactNumber;
    }

    return request;
  };

  const onSubmit = async (values: Values) => {
    try {
      setBackdrop(true);
      const request = createRequest(values);
      const { result } = await apiClient.updatecontactdetails(request);

      setSessionState({
        ...request,
        title: values.title || title,
        gender: values.gender || gender,
        firstName: values.firstName ? values.firstName : mainPolicyholderFirstName,
        lastName: request.surname || surname,
        mailingAddress: values.mailingAddress,
        captureMembershipLevel: result.askMembershipTier,
        isCompleted: true,
      });

      const isNextPageMembershipLevel = result.askMembershipTier && !hasMemberMatched;
      const nextPage = isNextPageMembershipLevel
        ? POLICY_MEMBERSHIP_LEVEL_PAGE_URL
        : hasAdditionalJointPolicyholder
        ? POLICY_JOINT_POLICYHOLDER_PAGE_URL
        : POLICY_PAYMENT_PAGE_URL;

      if (!isNextPageMembershipLevel) {
        skipMembershipLevelPage();
      }

      if (!hasAdditionalJointPolicyholder) {
        skipJointPolicyholderPage();
        skipJointMembershipLevelPage();
      }

      handlePremiumChanges({ discounts: result.discounts, premiumDetails: { ...result }, nextPage });
    } catch (ex) {
      logException({
        location: "usePersonalInformation.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      const errorResponse = ex as ApiException;
      if (errorResponse.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE) {
        setShowMoreInfoCard(true);
      } else {
        redirectToSystemUnavailable();
      }
    } finally {
      setBackdrop(false);
    }
  };

  return {
    data: {
      isTitleAndGenderRequired,
      hasMemberMatched,
      isMultiMatch,
      isLoading,
      showMoreInfoCard,
    },
    form,
    onSubmit,
  };
};

export default usePersonalInformation;
