import { FinePrint } from "@racwa/react-components";
import ProductDisclosureStatement from "../ProductDisclosureStatement";

const useStyles = {
  QuoteDisclaimer: {
    marginTop: "16px",
  },
};

export interface QuoteDisclaimerProps {
  showOnlineDiscountDisclaimer: boolean;
}

export const QuoteDisclaimer: React.FC<QuoteDisclaimerProps> = ({ showOnlineDiscountDisclaimer }) => {
  const classes = useStyles;
  return (
    <>
      <FinePrint paragraph sx={classes.QuoteDisclaimer}>
        <strong>
          Please remember that this is a quote only and does not provide cover. The final information you provide to us
          may affect the terms and premium we offer.
        </strong>
      </FinePrint>
      <FinePrint paragraph>
        <strong>
          It is important that you read and understand the <ProductDisclosureStatement /> to ensure the cover meets your
          needs. Please contact us if you have any difficulty understanding the PDS or your quote.{" "}
        </strong>
      </FinePrint>
      {showOnlineDiscountDisclaimer && (
        <FinePrint paragraph>
          <strong>
            *Discount applies to new policyholders for the first year of insurance only and is subject to a minimum
            premium.{" "}
          </strong>
        </FinePrint>
      )}
    </>
  );
};

export default QuoteDisclaimer;
