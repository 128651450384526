import { FormControl, FormHelperText, FormLabel, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../constants/HelperText";
import { Gender, Title } from "../../hooks/useApiClient/ClientProxy.generated";
import { tagIfJointPolicyHolder } from "../../utils/routeHelper";
import useStyles from "./styles";

interface GenericToggleInputProps {
  name: string;
  question: string;
  items: string[];
  validate?: any;
  requiredMessage?: string;
  value: string | null;
}

const GenericToggleInput: React.FC<GenericToggleInputProps> = ({ value, name, question, items, validate }) => {
  const { trigger } = useFormContext();
  const [state, setValue] = useState(value);
  const labelId = `label-${name}`;
  const groupId = `toggle-button-group-${name}`;
  const errorId = `error-${name}`;
  useEffect(() => {
    if (value !== state) {
      setValue(value);
    }
  }, [value, state, setValue]);
  return (
    <Controller
      name={name}
      rules={{
        validate,
      }}
      render={({ field: { ref, onBlur, onChange: onComponentChange, ...props }, fieldState: { error } }) => (
        <FormControl error={!!error}>
          <FormLabel  id={labelId} data-testid={labelId} error={!!error}>
            {question}
          </FormLabel >
          <ToggleButtonGroup
            {...props}
            value={state}
            onChange={(_, value) => {
              if (value) {
                onComponentChange(value);
                window.formotiv &&
                  window.formotiv.inputActivity(
                    window.formotivConfig,
                    tagIfJointPolicyHolder(name),
                    "buttongroup",
                    value,
                  );
                setValue(value);
                trigger(props.name);
              }
            }}
            onBlur={() => {
              gtm(fieldTouched(name));
              onBlur();
            }}
            exclusive
            id={groupId}
            data-testid={groupId}
            aria-labelledby={labelId}
          >
            {items.map((item, idx) => {
              const id = `toggle-button-${item.toLowerCase()}`;
              return (
                <ToggleButton
                  type="button"
                  role="button"
                  key={item}
                  value={item}
                  id={id}
                  data-testid={id}
                  ref={idx === 0 ? ref : null}
                >
                  {item}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          {error?.message && (
            <FormHelperText id={errorId} data-testid={errorId}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

const ToArray = (value: any): string[] => Object.keys(value).map((key) => value[key]);

export interface TitleGenderProps {
  show?: boolean;
}

export const TitleGender: React.FC<TitleGenderProps> = ({ show = false }) => {
  const { watch, setValue, clearErrors } = useFormContext();
  const title = watch("title");
  const gender = watch("gender");
  const classes = useStyles;
  const titleClass = show ? undefined : classes.hidden;
  const showGender = title === Title.Dr || title === Title.Mx;
  const showGenderClass = showGender && show ? undefined : classes.hidden;

  useEffect(() => {
    if (!showGender) {
      setValue("gender", null);
      clearErrors("gender");
    }
  }, [title, showGender, setValue, clearErrors]);

  return (
    <>
      <Grid item xs={12} sx={titleClass}>
        <GenericToggleInput
          name="title"
          question="Title"
          value={title}
          items={ToArray(Title)}
          validate={{
            isValid: (title?: Title) => !show || (title && true) || RequiredSelection,
          }}
        />
      </Grid>
      <Grid item xs={12} sx={showGenderClass}>
        <GenericToggleInput
          name="gender"
          question="What gender do you most identify with?"
          value={showGender ? gender : null}
          items={ToArray(Gender)}
          validate={{
            isValid: (gender?: Gender) => !showGender || !!gender || RequiredSelection,
          }}
        />
      </Grid>
    </>
  );
};

export default TitleGender;
