import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { Controller, useFormContext } from "react-hook-form";
import format from "../../../../shared/components/CurrencyInput/format";
import { CoverType, NumberOptions } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { CoverValues } from "../../types";
import CoverFormInput from "../CoverFormInput";
import ExcessTooltip from "../Tooltips/ExcessTooltip";
import useStyles from "./styles";

const name = "excess";
const label = "Basic excess";

export interface ExcessInputProps extends Omit<NumberOptions, "value"> {
  coverType: CoverType;
  onChange: (value: CoverValues, forceSave?: boolean) => Promise<void>;
}

export const ExcessInput: React.FC<ExcessInputProps> = ({ coverType, options, onChange }) => {
  const classes = useStyles;
  const { control, getValues } = useFormContext();
  const coverTypeId = coverType.toLowerCase();
  const labelId = `label-${coverTypeId}-${name}`;
  const inputId = `button-group-${coverTypeId}-${name}`;

  return options && options.length > 1 ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange: onChangeComponent, ...props }, fieldState: { error } }) => (
        <CoverFormInput
          inputId={inputId}
          labelId={labelId}
          labelXs={label}
          label={label}
          tooltip={<ExcessTooltip />}
          error={!!error}
        >
          <ToggleButtonGroup
            {...props}
            exclusive
            id={inputId}
            sx={classes.toggleButtonGroup}
            data-testid={inputId}
            aria-labelledby={labelId}
            onChange={async (_: any, value: number) => {
              if (options.indexOf(value) > -1) {
                window.formotiv && window.formotiv.inputActivity(window.formotivConfig, name, "buttongroup", value);
                onChangeComponent && onChangeComponent(value);
                const request = {
                  ...getValues(),
                  type: coverType,
                  excess: value,
                };
                await onChange(request as CoverValues, true);
              }
            }}
            onBlur={() => {
              gtm(fieldTouched(label));
              onBlur && onBlur();
            }}
          >
            {options.sort().map((item, idx) => {
              const button = "button";
              const id = `${inputId}-${item}`;
              return (
                <ToggleButton
                  type={button}
                  role={button}
                  value={item}
                  key={idx}
                  id={id}
                  data-testid={id}
                  aria-label={format(item)}
                  sx={classes.button}
                >
                  {format(item)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </CoverFormInput>
      )}
    />
  ) : null;
};

export default ExcessInput;
