import { Grid, Typography } from "@mui/material";
import WhatsIncludedDialog from "../WhatsIncludedDialog";
import { useStyles } from "./styles";

export const QuoteCardHeading: React.FC = () => {
  const classes = useStyles;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={10}>
        <Typography variant="h3">Here's your quote</Typography>
      </Grid>
      <Grid item xs={2} sx={classes.headingTooltip}>
        <WhatsIncludedDialog />
      </Grid>
    </Grid>
  );
};

export default QuoteCardHeading;
