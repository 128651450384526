import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { Caravan } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

interface UseCaravansResult {
  caravans?: Caravan[];
  isError: boolean;
  hasLoaded?: boolean;
}

export const useLoadCaravans = (): ((
  manufacturer?: string | null,
  year?: number | null,
  model?: string | null
) => Promise<UseCaravansResult>) => {
  const apiClient = useApiClient();

  const loadCaravans = async (manufacturer?: string | null, year?: number | null, model?: string | null) => {
    try {
      if (manufacturer && year && model) {
        const { result } = await apiClient.getcaravans(manufacturer, year, model);
        return { caravans: result, isError: false };
      } else {
        return { caravans: [], isError: false };
      }
    } catch (ex) {
      return { caravans: [], isError: true };
    }
  };

  return loadCaravans;
};

export const useCaravans = (
  manufacturer?: string | null,
  year?: number | null,
  model?: string | null
): UseCaravansResult => {
  const loadCaravans = useLoadCaravans();
  const [isError, setIsError] = useState<boolean>(false);
  const [caravans, setCaravans] = useState<Caravan[]>();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    const getCaravans = async () => {
      setHasLoaded(false);
      const { caravans, isError } = await loadCaravans(manufacturer, year, model);
      if (isMounted) {
        setIsError(isError);
        setCaravans(caravans);
        if (caravans && caravans.length >= 1) {
          setHasLoaded(true);
        }
      }
    };
    getCaravans();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturer, year, model]);

  return { caravans, isError, hasLoaded };
};

export default useCaravans;
