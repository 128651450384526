import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop, ScrollToTop, SessionStatePrefixProvider, TrackPageChanges } from "raci-react-library";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import HealthCheck from "./shared/components/HealthCheck";
import PreloadReferenceData from "./shared/components/PreloadReferenceData";
import PremiumChangedDialog from "./shared/components/PremiumChangedDialog";
import { SESSION_KEY_PREFIX } from "./shared/constants";
import { applicationInsightsPlugin } from "./shared/utils/applicationInsights/index";
import Views from "./views";

export const App: React.FC = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <AppInsightsContext.Provider value={applicationInsightsPlugin}>
        <Backdrop />
        <BrowserRouter>
          <SessionStatePrefixProvider prefix={SESSION_KEY_PREFIX}>
            <PreloadReferenceData />
            <HealthCheck />
            <ScrollToTop />
            <Views />
            <TrackPageChanges />
            <PremiumChangedDialog />
          </SessionStatePrefixProvider>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
