import { Card, CardContent, Grid } from "@mui/material";
import { AuthorisationCard, PaymentFormDisclaimerDisplay, useGetSessionState } from "raci-react-library";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PaymentFrequency as PaymentFrequencyControl } from "../../shared/components/PaymentFrequency";
import { PaymentMethod as PaymentMethodOptions } from "../../shared/hooks/useApiClient/ClientProxy.generated";
import useFormotiv from "../../shared/hooks/useFormotiv";
import {
  PolicyRoutes,
  POLICY_CONFIRMATION_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../shared/routing/routes.config";
import BankAccount from "./components/BankAccount";
import CreditCard from "./components/CreditCard";
import PaymentMethod from "./components/PaymentMethod";
import PaymentPolicySummary from "./components/PaymentPolicySummary";
import { FormProps, PaymentData } from "./types";
import TryAgainDialog from "./components/TryAgainDialog";
import { CallToActionButton } from "../../shared/components/CallToActionButon";

export const Form: React.FC<FormProps> = ({
  form,
  data: { hasError, premiumDetails, trustedFrame, displayAuthorisationCard },
  openTryAgainDialog,
  lockField,
  onSubmit,
  onDoneClick,
  onTryAgainClick,
  onBsbLookup: onBsbLookupService,
}) => {
  const { getValues, watch, formState } = form;
  const method = watch("method");
  const frequency = watch("frequency");
  const [isFirstTimeValid, setIsFirstTimeValid] = useState(false);
  const navigate = useNavigate();
  const paymentData = useGetSessionState<PaymentData>(PolicyRoutes.Payment);
  const onSubmitWrapper = useFormotiv(onSubmit);

  const isDisabled = lockField || paymentData.annualCCPaymentInProgress;
  useEffect(() => {
    const { bsb, accountNumber, accountName } = getValues();
    if (
      formState.dirtyFields.bsb &&
      formState.dirtyFields.accountNumber &&
      formState.dirtyFields.accountName &&
      bsb &&
      accountNumber &&
      accountName
    ) {
      setIsFirstTimeValid(isFirstTimeValid || true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, getValues]);

  useEffect(() => {
    if (hasError) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
    } else if (paymentData.isCompleted) {
      navigate(POLICY_CONFIRMATION_PAGE_URL);
    }
  }, [hasError, navigate, paymentData.isCompleted]);

  const displayBankAccount = <BankAccount onBsbLookup={onBsbLookupService} />;
  const displayCreditCard = <CreditCard />;

  const displayPaymentMethodContainer = () => {
    if (method == null) {
      return <></>;
    } else if (method === PaymentMethodOptions.Card) {
      return displayCreditCard;
    } else if (method === (method as PaymentMethodOptions.Bank_account)) {
      return displayBankAccount;
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <PaymentPolicySummary />
                </CardContent>
              </Card>
            </Grid>
            <PaymentFrequencyControl
              {...premiumDetails}
              displayLabel={true}
              disabled={isDisabled}
              trackingName={"paymentFrequency"}
            />
            <PaymentMethod disabled={isDisabled} />
            <Grid item xs={12}>
              {displayPaymentMethodContainer()}
            </Grid>
            {(isFirstTimeValid || displayAuthorisationCard) && (
              <Grid item xs={12} style={{ paddingTop: "30px" }}>
                <AuthorisationCard
                  paymentFrequency={frequency || ""}
                  paymentMethod={method || ""}
                  pdsUrl={process.env.REACT_APP_RAC_PDS_URL}
                  ddrsaUrl={process.env.REACT_APP_RAC_PDS_URL}
                />
                <PaymentFormDisclaimerDisplay paymentFrequency={frequency} paymentMethod={method} />
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <CallToActionButton
                type="submit"
                role="submit"
                data-testid="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={formState.isSubmitting}
              >
                Purchase policy
              </CallToActionButton>
            </Grid>
          </Grid>
        </form>
        <TryAgainDialog
          open={openTryAgainDialog}
          policyNumber={paymentData.policyNumber}
          onTryAgainClick={() => onTryAgainClick()}
          onDoneClick={() => onDoneClick()}
        ></TryAgainDialog>
      </FormProvider>
    </>
  );
};

export default Form;
