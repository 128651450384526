import { colors } from "@racwa/styles";

const border = `1px solid rgba(0, 0, 0, 0.12)`;

export const useStyles = {
  headingTooltip: {
    textAlign: "right",
    "& > button": {
      color: colors.dieselDeeper,
      position: "relative",
    },
  },
  cardContentMiddle: {
    backgroundColor: colors.racGrayLight,
    borderTop: border,
    borderBottom: border,
    "& .MuiDivider-root": {
      margin: "20px 0 20px 0",
      color: colors.dieselLight,
    },
  },
  excessPadding: {
    paddingTop: 5,
  },
};
