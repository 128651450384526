import { FormLabel, Grid, Typography } from "@mui/material";
import { useLogger, useSessionIdStorageKey } from "raci-react-library";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { CallToActionButton } from "../../shared/components/CallToActionButon";
import SelectSuburb from "../../shared/components/SelectSuburb";
import { Suburb } from "../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../shared/hooks/useCustomLogProperties";
import useFormotiv from "../../shared/hooks/useFormotiv";
import CantFindQuoteCard from "./components/CantFindQuoteCard";
import QuoteNumberInput from "./components/QuoteNumberInput";
import RetrieveAttemptsExhaustedCard from "./components/RetrieveAttemptsExhaustedCard";
import { FormProps, Values } from "./types";

export const RetrieveQuoteForm: React.FC<FormProps> = ({
  form,
  onSubmit,
  suburbs,
  attemptsExhausted,
  cantFindQuote,
}) => {
  const location = useLocation();
  const onSubmitWrapper = useFormotiv(onSubmit);
  const sessionIdKey = useSessionIdStorageKey();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();

  const isValidQuoteNumber = (quoteNumber?: string | null) => {
    return /^qte\d{3,15}$/i.test(quoteNumber || "");
  };

  useEffect(() => {
    if (sessionStorage.getItem(sessionIdKey)) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams) {
        const quoteNumber = queryParams.get("quotenumber") || queryParams.get("quoteNumber");
        const suburbName = queryParams.get("suburb");
        const postcode = queryParams.get("postcode");

        if (quoteNumber && suburbName && postcode) {
          const suburb: Suburb = { name: suburbName, postcode, cityName: "" };

          onSubmit({ quoteNumber, suburb } as Values);
        } else if (isValidQuoteNumber(quoteNumber)) {
          form.setValue("quoteNumber", quoteNumber?.toLocaleUpperCase());
        }
      }
    }
  }, [form, location.search, onSubmit, sessionIdKey]);

  useEffect(() => {
    try {
      if (window.formotiv) {
        window.formotiv.init(window.formotivConfig);
      }
    } catch (ex) {
      logException({
        location: "RetrieveQuoteForm.formotiv.init",
        message: ex,
        customProperties: customLogProperties,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container sx={{ justifyContent: "center", maxWidth: { sm: "600px" } }}>
        <FormProvider {...form}>
          <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate data-testid="retrieveQuoteForm">
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ paddingBottom: "24px" }}>
                <Typography variant="h2" id="header" data-testid="header">
                  Let's retrieve your quote
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: "28px" }}>
                <QuoteNumberInput />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: "28px" }}>
                <FormLabel>Suburb</FormLabel>
                <Typography variant="body1" sx={{ fontSize: "16px", lineHeight: "23px", paddingBottom: "10px" }}>
                  This is the suburb you entered when getting your quote.
                </Typography>
                <SelectSuburb options={suburbs} formControlMargin="none" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: "14px", lineHeight: "20px" }}>
                  Please note, your quote may have changed. Please check that all of your information is correct.
                </Typography>
              </Grid>
              {attemptsExhausted ? (
                <Grid item xs={12}>
                  <RetrieveAttemptsExhaustedCard />
                </Grid>
              ) : null}
              {cantFindQuote ? (
                <Grid item xs={12}>
                  <CantFindQuoteCard />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <CallToActionButton
                  id="btn-retrieve-quote"
                  name="btn-retrieve-quote"
                  type="submit"
                  data-testid="btn-retrieve-quote"
                  role="button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={attemptsExhausted}
                >
                  Retrieve quote
                </CallToActionButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default RetrieveQuoteForm;
