import { Box, Grid, Typography } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";

export interface NotFoundCardProps extends React.ComponentProps<any> {
  titleAndMessage: CardMessage;
}

export interface CardMessage {
  title: string;
  firstParagraph: string | JSX.Element;
  secondParagraph?: string | JSX.Element;
  thirdParagraph?: string | JSX.Element;
}

export const NotFoundCard: React.FC<NotFoundCardProps> = ({ titleAndMessage }) => (
  <Grid item xs={12}>
    <RacwaCardNotification data-testid="notFoundCard" severity="warning" title={titleAndMessage.title}>
      <Box marginBottom={1}>
        <Typography variant="body2">{titleAndMessage.firstParagraph}</Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="body2">{titleAndMessage.secondParagraph}</Typography>
      </Box>
      <Typography variant="body2">{titleAndMessage.thirdParagraph}</Typography>
    </RacwaCardNotification>
  </Grid>
);

export default NotFoundCard;
