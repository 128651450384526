import { useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiClient from "../../../../shared/hooks/useApiClient";
import {
  AddressSummary,
  Gender,
  PersonalInformationReferenceData,
  Title,
} from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../../../shared/routing/routes.config";

export interface PersonalInformationReferenceDataProps extends PersonalInformationReferenceData {
  isLoading?: boolean;
}

const useReferenceData = (): PersonalInformationReferenceDataProps => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [address, setAddress] = useState<AddressSummary>();
  const [surname, setSurname] = useState<string>();
  const [title, setTitle] = useState<Title>();
  const [gender, setGender] = useState<Gender>();
  const [isTitleAndGenderRequired, setIsTitleAndGenderRequired] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  useEffect(() => {
    let isMounted = true;

    const loadReferenceData = async () => {
      try {
        setIsLoading(true);
        setBackdrop(true);
        const { result } = await apiClient.getpersonalinformationreferencedata();
        if (isMounted) {
          setAddress(result.postalAddress);
          setSurname(result.surname);
          setTitle(result.title);
          setGender(result.gender);
          setIsTitleAndGenderRequired(result.isTitleAndGenderRequired);
        }
      } catch (ex) {
        redirectToSystemUnavailable();
      } finally {
        setBackdrop(false);
        setIsLoading(false);
      }
    };

    if (isMounted) {
      loadReferenceData();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient]);

  return {
    isTitleAndGenderRequired,
    postalAddress: address,
    surname: surname,
    title: title,
    gender: gender,
    isLoading,
  };
};

export default useReferenceData;
