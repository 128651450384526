import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { happyBirthdayState } from "../../atoms";
import HelpTextTooltip from "../../shared/components/HelpTextToolTip";
import { YES } from "../../shared/constants";
import useFormotiv from "../../shared/hooks/useFormotiv";
import AreYouAMemberButtonGroup from "./Components/AreYouAMemberButtonGroup";
import CouldNotFindDetailsCard from "./Components/CouldNotFindDetailsCard";
import FindMyDetailsButton from "./Components/FindMyDetailsButton";
import MemberMatchForm from "./Components/MemberMatchForm";
import SkipLink from "./Components/SkipLink";
import { FormProps, Values } from "./types";
import { BirthdayConfetti, isBirthdayToday } from "raci-react-library";

export const Form: React.FC<FormProps> = ({
  form,
  isContactMatchAttemptsExhausted,
  dispatcher,
  onSubmit,
  membershipTier,
  redirectToNextPage,
}) => {
  const [isSubmitError, setIsSubmitError] = useState(false);
  const {
    handleSubmit,
    formState: { isSubmitting: isLoading, isValid },
  } = form;
  const [hasSubmittedBefore, setHasSubmittedBefore] = useState(false);
  const [showBirthdayConfetti, setShowBirthdayConfetti] = useState(false);
  const hasBirthdayConfettiBeenPreviouslyDisplayed = useRecoilValue(happyBirthdayState).hasBeenPreviouslyDisplayed;
  const isLoadingOrHasSubmittedBeforeAndWasntAnError = isLoading || (hasSubmittedBefore && !isSubmitError);
  const disabledInputs: boolean =
    (isLoadingOrHasSubmittedBeforeAndWasntAnError && isValid) || isContactMatchAttemptsExhausted || false;
  const disabledButtons = isValid && isLoadingOrHasSubmittedBeforeAndWasntAnError;
  const showMemberMatchForm = useWatch({ control: form.control, name: "areYouAMember" }) === YES;
  const firstName = useWatch({ control: form.control, name: "firstName" });

  const birthdayConfettiOnBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event?.target?.value && isBirthdayToday(event?.target.value) && !hasBirthdayConfettiBeenPreviouslyDisplayed
      ? setShowBirthdayConfetti(true)
      : setShowBirthdayConfetti(false);
  };

  const onSubmitWrapper = useFormotiv(async (newState: Values) => {
    onSubmit && setIsSubmitError(!(await onSubmit(newState)));
    setHasSubmittedBefore(true);
  });

  enum SkipButtonStates {
    AboveFindDetails,
    BelowFindDetails,
    Hidden,
  }

  const getSkipButtonState = () => {
    if ((hasSubmittedBefore && isSubmitError) || isContactMatchAttemptsExhausted) {
      return SkipButtonStates.AboveFindDetails;
    }

    if (hasSubmittedBefore && !isSubmitError) {
      return SkipButtonStates.Hidden;
    }

    return SkipButtonStates.BelowFindDetails;
  };

  const skipButtonState = getSkipButtonState();

  const skipLink = (
    <Box pt={3} pb={3} width={1}>
      <SkipLink show={true} disabled={disabledButtons} onClick={() => dispatcher("areYouAMember")} />
    </Box>
  );

  return (
    <FormProvider {...form}>
      <form
        action="#"
        onSubmit={handleSubmit(onSubmitWrapper)}
        data-testid="areYouAMemberForm"
        autoComplete="on"
        noValidate
      >
        {showBirthdayConfetti ? <BirthdayConfetti name={firstName} /> : null}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Before we get started
            </Typography>
          </Grid>
          <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={11}>
              <AreYouAMemberButtonGroup disabled={disabledButtons} onChange={() => dispatcher("areYouAMember")} />
            </Grid>
            <Grid item xs={1}>
              <HelpTextTooltip
                id="areYouAnRACMemberToolTip"
                title="Are you an RAC member?"
                message="If you've any of these RAC products you're a member:
                        Roadside Assistance, Insurance, FREE2go, Life Insurance, Health Insurance,
                        Pet Insurance, Finance, Security Monitoring or Rewards Membership."
              />
            </Grid>
          </Grid>
          <MemberMatchForm
            show={showMemberMatchForm}
            disabled={disabledInputs}
            dispatcher={dispatcher}
            dateOfBirthOnBlur={birthdayConfettiOnBlur}
          />
          {showMemberMatchForm && (isContactMatchAttemptsExhausted || (hasSubmittedBefore && isSubmitError)) ? (
            <Grid item xs={12}>
              <CouldNotFindDetailsCard isContactMatchAttemptsExhausted={isContactMatchAttemptsExhausted} />
            </Grid>
          ) : null}

          {showMemberMatchForm && (
            <Grid item xs={12}>
              <Box pb={6} width={1}>
                {skipButtonState === SkipButtonStates.AboveFindDetails && skipLink}
                <FindMyDetailsButton
                  sx={{width: "100%", margin: "25px 0 30px 0"}}
                  hasMatch={hasSubmittedBefore && !isSubmitError}
                  firstName={firstName}
                  disabled={disabledInputs}
                  membershipTier={membershipTier}
                  onButtonAnimationEnd={redirectToNextPage}
                />
                {skipButtonState === SkipButtonStates.BelowFindDetails && skipLink}
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
