import { HTTP_STATUS_CODE_INTERNAL_SERVER, useLogger } from "raci-react-library";
import { useEffect, useState } from "react";
import useApiClient from "../../hooks/useApiClient";
import { AddressSummary, ApiException } from "../../hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../hooks/useCustomLogProperties";
import useDebounce from "../../hooks/useDebounce";
import { MailingAddressTemplateProps } from "./MailingAddressTemplate";

interface UseMailingAddressResult extends MailingAddressTemplateProps {}

export const useMailingAddress = (mailingAddress?: AddressSummary | null): UseMailingAddressResult => {
  const apiClient = useApiClient();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [searchTerm, setSearchTerm] = useState<string>(mailingAddress?.displayAddress || "");
  const [addresses, setAddresses] = useState<AddressSummary[]>([]);
  const [address, setAddress] = useState<AddressSummary | null>(mailingAddress || null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSelection = (selectedAddress: AddressSummary | null) => {
    setSearchTerm(selectedAddress?.displayAddress || "");
    setAddress(selectedAddress || null);
    setAddresses([]);
  };

  const onInputChange = (value: string) => {
    if (!value) {
      setAddress(null);
      setAddresses([]);
    }

    if (
      addresses.length > 0 &&
      addresses.some((a) => a.displayAddress.toLocaleLowerCase().includes(value.toLocaleLowerCase())) === false
    ) {
      setAddresses([]);
    }

    setSearchTerm(value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    let isCancelled = false;

    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length >= 10 && debouncedSearchTerm !== address?.displayAddress) {
        setIsLoading(true);

        const fetchAddresses = async () => {
          setIsError(false);
          setIsLoading(true);
          try {
            if (!isCancelled) {
              const response = await apiClient.getaddresses(debouncedSearchTerm);
              setAddresses(response.result);
            }
          } catch (ex) {
            logException({
              location: "useMailingAddress.fetchAddresses",
              message: ex,
              customProperties: customLogProperties,
            });
            const errorResponse = ex as ApiException;
            if (errorResponse.status >= HTTP_STATUS_CODE_INTERNAL_SERVER) {
              setIsError(true);
              return;
            }
            setAddresses([]);
            setIsError(true);
          } finally {
            setIsLoading(false);
          }
        };

        fetchAddresses();
      }
    } else {
      setAddress(null);
      setAddresses([]);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled && mailingAddress) {
      setAddress(mailingAddress);
    }

    return () => {
      isCancelled = true;
    };
  }, [mailingAddress]);

  return {
    data: addresses,
    isError,
    isLoading,
    onSelection,
    onInputChange,
  };
};

export default useMailingAddress;
