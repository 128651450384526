export const useStyles = {
  heading: {
    marginBottom: "8px",
    "& strong": {
      fontWeight: 900,
    },
  },
};

export default useStyles;
