import { Grid, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { RacwaDetailedDialog } from "@racwa/react-components";
import { StyledLink } from "./styles";

export interface ExcessDialogProps {
  showDialog: boolean;
  setShowDialog: (open: boolean) => void;
}

export const createId = (prefix: string, suffix: string) => {
  return `${prefix}-${suffix}`;
};

export const ExcessDialog = ({ showDialog, setShowDialog }: ExcessDialogProps) => {
  const id = "excess-dialog";
  const link = "Premium, Excess and Discount Guide";

  return (
    <>
      <RacwaDetailedDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onClickClose={() => setShowDialog(false)}
        title="Excess"
        titleId={createId(id, "title")}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography id={createId(id, "paragraph-one")}>
              The excess is the amount you may need to pay towards settlement of any claim.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography id={createId(id, "paragraph-two")}>
              If you adjust your excess, your premium will change.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography id={createId(id, "extra-excesses")}>
              <b>Extra excesses may apply:</b>
            </Typography>
            <ul>
              <li>
                <Typography id={createId(id, "extra-excesses-one")}>Driver under 19: $650</Typography>
              </li>
              <li>
                <Typography id={createId(id, "extra-excesses-two")}>Driver under 21: $550</Typography>
              </li>
              <li>
                <Typography id={createId(id, "extra-excesses-three")}>Driver under 24: $450</Typography>
              </li>
              <li>
                <Typography id={createId(id, "extra-excesses-four")}>Driver under 26: $300</Typography>
              </li>
              <li>
                <Typography id={createId(id, "extra-excesses-five")}>
                  Special excess: will be stated in your policy documents if applicable
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography id={createId(id, "paragraph-four")}>
              See the{" "}
              <StyledLink
                id={createId(id, "link")}
                href={process.env.REACT_APP_RAC_PDS_URL}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  gtm(event(link));
                }}
              >
                {link}
              </StyledLink>{" "}
              for more information.
            </Typography>
          </Grid>
        </Grid>
      </RacwaDetailedDialog>
    </>
  );
};

export default ExcessDialog;
