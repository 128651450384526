import { useEffect, useState } from "react";
import useApiClient from "../../../../shared/hooks/useApiClient";

interface UseYearsResult {
  years?: number[];
  isError: boolean;
}

export const useYears = (manufacturer?: string | null): UseYearsResult => {
  const apiClient = useApiClient();
  const [isError, setIsError] = useState<boolean>(false);
  const [years, setYears] = useState<number[]>();

  useEffect(() => {
    let isMounted = true;

    const loadYears = async () => {
      try {
        if (manufacturer) {
          setYears(undefined);
          const { result } = await apiClient.getmnfctrsyrs(manufacturer);
          if (isMounted) {
            setYears(result);
          }
        } else {
          setYears([]);
        }
      } catch (ex) {
        setIsError(true);
        setYears([]);
      }
    };

    if (isMounted) {
      loadYears();
    }

    return () => {
      isMounted = false;
    };
  }, [apiClient, manufacturer]);

  return { years, isError };
};

export default useYears;
