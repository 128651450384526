import { fieldTouched, gtm } from "@racwa/analytics";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CurrencyInput from "../../../../shared/components/CurrencyInput";
import format from "../../../../shared/components/CurrencyInput/format";
import { MAX_CUSTOM_MARKET_VALUE, MIN_CUSTOM_MARKET_VALUE } from "../../../../shared/constants";
import { CoverType, NumberRange } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { CoverValues } from "../../types";
import CoverFormInput from "../CoverFormInput";
import AgreedValueTooltip from "../Tooltips/AgreedValueTooltip";
import HelperText from "./HelperText";

const name = "sumInsured";
const label = "Agreed value";

const getMinErrorMessage = (min?: number, max?: number) => {
  return min === MIN_CUSTOM_MARKET_VALUE && max === MAX_CUSTOM_MARKET_VALUE
    ? `Please enter a value between ${format(min)} and ${format(max)}`
    : getRangedErrorMessage(min, max);
};

const getRangedErrorMessage = (min?: number, max?: number) => {
  return `Please enter a value between ${format(min || 0)} and ${format(max || 0)}`;
};

export interface SumInsuredInputProps extends Omit<NumberRange, "value"> {
  coverType: CoverType;
  onBlur: (value: CoverValues, forceSave?: boolean) => Promise<void>;
}

export const SumInsuredInput: React.FC<SumInsuredInputProps> = ({ coverType, min = 0, max = 0, onBlur }) => {
  const { control, watch, getValues, trigger } = useFormContext();
  const [state, setState] = useState<number | undefined>(parseInt(watch(name)) || undefined);
  const coverTypeId = coverType.toLowerCase();
  const labelId = `label-${coverTypeId}-${name}`;
  const inputId = `input-${coverTypeId}-${name}`;
  const errorMessage = getMinErrorMessage(min, max);
  const maxErrorMessage = getRangedErrorMessage(min, max);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: { value: true, message: errorMessage },
        min: { value: min, message: errorMessage },
        max: { value: max, message: maxErrorMessage },
        validate: {
          isValid: () => state !== undefined || errorMessage,
        },
      }}
      render={({
        field: { ref: inputRef, onBlur: onComponentBlur, onChange: onChangeComponent, ...props },
        fieldState: { error },
      }) => (
        <CoverFormInput
          inputId={inputId}
          labelId={labelId}
          labelXs={label}
          label={label}
          tooltip={<AgreedValueTooltip />}
          error={!!error}
          helperText={<HelperText coverType={coverType} error={error} amount={state} />}
        >
          <CurrencyInput
            {...props}
            id={inputId}
            data-testid={inputId}
            allowNegative={false}
            color="primary"
            fullWidth
            value={state}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            error={!!error}
            onValueChange={(newValue) => {
              onChangeComponent(newValue?.floatValue);
              setState(newValue?.floatValue);
            }}
            onBlur={async () => {
              gtm(fieldTouched(label));
              onComponentBlur();
              await trigger();
              if (!!error === false) {
                const request = {
                  ...getValues(),
                  type: coverType,
                  sumInsured: state,
                };
                await onBlur(request as CoverValues);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.currentTarget.blur();
              }
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            inputRef={inputRef}
          />
        </CoverFormInput>
      )}
    />
  );
};

export default SumInsuredInput;
