import { Grid, Typography } from "@mui/material";
import { ContactNumberInput, EmailInput, FirstNameInput, LastNameInput } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import MailingAddress, { contactMatchedLabel } from "../../shared/components/MailingAddress";
import { PrivacyPolicy } from "../../shared/components/PrivacyPolicy";
import TitleGender from "../../shared/components/TitleGender";
import { CONFIRM_TEXT, NEXT_TEXT, NO, YES } from "../../shared/constants";
import useFormotiv from "../../shared/hooks/useFormotiv";
import SelectIsMailAddressSameAsPrimary from "./components/IsMailingAddressSame";
import { FormProps } from "./types";
import { CallToActionButton } from "../../shared/components/CallToActionButon";

export const Form: React.FC<FormProps> = ({ form, data: { hasMemberMatched, isMultiMatch }, onSubmit }) => {
  const isSingleMatch = hasMemberMatched && !isMultiMatch;
  const mailingAddressLabel = isSingleMatch ? contactMatchedLabel : null;
  const buttonText = isSingleMatch ? CONFIRM_TEXT : NEXT_TEXT;
  const onSubmitWrapper = useFormotiv(onSubmit);

  const hideMailingAddress = form.watch("mailAddressSameAsPrimary", form.getValues().mailAddressSameAsPrimary || YES);
  const mailingAddressClass = hideMailingAddress === NO ? {} : { display: "none"};

  return (
    <>
      <FormProvider {...form}>
        <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2" id="header" data-testid="header">
                Tell us more about your joint policyholder
              </Typography>
            </Grid>
            <TitleGender show />
            <Grid item xs={12}>
              <FirstNameInput />
            </Grid>
            <Grid item xs={12}>
              <LastNameInput />
            </Grid>
            <Grid item xs={12}>
              <ContactNumberInput />
            </Grid>
            <Grid item xs={12}>
              <EmailInput />
            </Grid>
            <Grid item xs={12}>
              <SelectIsMailAddressSameAsPrimary />
            </Grid>
            <Grid item xs={12} sx={mailingAddressClass}>
              <MailingAddress label={mailingAddressLabel} required={hideMailingAddress === NO} />
            </Grid>
            <Grid item xs={12}>
              <PrivacyPolicy />
            </Grid>
            <Grid item xs={12}>
              <CallToActionButton
                type="submit"
                data-testid="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                {buttonText}
              </CallToActionButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
