import { theme } from "@racwa/react-components";

const useStyles = {
  default: {
    margin: 0,
    background: theme.palette.common.white,
  },
  tooltipCard: {
    margin: 0,
    background: theme.palette.grey["100"],
  },
};

export default useStyles;
