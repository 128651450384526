import { FormControl, FormHelperText, FormLabel, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RequiredSelection } from "../../../../shared/constants/HelperText";
import { PaymentMethod as PaymentMethodOptions } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

const button = "button";
const cardLabel = "Card";
const bankAccountLabel = "Bank account";
const label = "Payment method";
const inputId = "payment-method";
const cardButtonId = "toggle-payment-method-card";
const bankAccountButtonId = "toggle-payment-method-bank-account";

export interface PaymentMethodProps {
  disabled?: boolean | null;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ disabled }) => {
  const { control } = useFormContext();
  const requiredValidationRule = {
    required: {
      value: true,
      message: RequiredSelection,
    },
  };

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="method"
        rules={requiredValidationRule}
        render={({ field: { onBlur, onChange, ...props }, fieldState: { error } }) => (
          <FormControl component="fieldset" error={!!error} fullWidth={true}>
            <FormLabel data-testid="paymentMethodSelection">Payment method</FormLabel>

            <ToggleButtonGroup
              {...props}
              onChange={(_, value) => {
                gtm(event(value));
                window.formotiv &&
                  window.formotiv.inputActivity(window.formotivConfig, "paymentMethod", "buttongroup", value);
                if (value === cardLabel || value === bankAccountLabel) {
                  const method = value === cardLabel ? PaymentMethodOptions.Card : PaymentMethodOptions.Bank_account;
                  onChange(method);
                }
              }}
              onBlur={() => {
                gtm(fieldTouched(label));
                onBlur();
              }}
              exclusive
              id={inputId}
              data-testid={inputId}
              aria-label={label}
            >
              <ToggleButton
                type={button}
                role={button}
                value={cardLabel}
                id={cardButtonId}
                data-testid={cardButtonId}
                disabled={disabled !== null && disabled}
              >
                {cardLabel}
              </ToggleButton>
              <ToggleButton
                type={button}
                role={button}
                value={bankAccountLabel}
                id={bankAccountButtonId}
                data-testid={bankAccountButtonId}
                disabled={disabled !== null && disabled}
              >
                {bankAccountLabel}
              </ToggleButton>
            </ToggleButtonGroup>
            {error?.message ? <FormHelperText>{error.message}</FormHelperText> : null}
          </FormControl>
        )}
      />
    </Grid>
  );
};

export default PaymentMethod;
