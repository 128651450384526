import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonProps } from "@mui/material";
import { Icon082, RacwaNotifyButton } from "@racwa/react-components";
import { useEffect, useState } from "react";
import { MembershipTier } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";

export interface FindMyDetailsButtonProps extends ButtonProps {
  hasMatch?: boolean | null;
  firstName?: string | null;
  membershipTier?: string | null;
  onButtonAnimationEnd?: () => void;
}

const getDiscountPercentage = (membershipTier?: string | null) => {
  if (membershipTier === MembershipTier.Gold) {
    return "10%";
  } else if (membershipTier === MembershipTier.Silver) {
    return "7.5%";
  } else if (membershipTier === MembershipTier.Bronze) {
    return "5%";
  } else {
    return null;
  }
};

enum ButtonStates {
  FindMyDetails = 0,
  WeFoundYou = 1,
  DiscountApplied = 2,
}

export const FindMyDetailsButton: React.FC<FindMyDetailsButtonProps> = ({
  hasMatch,
  firstName,
  membershipTier,
  onButtonAnimationEnd,
  disabled,
}) => {
  const [activeState, setActiveState] = useState(ButtonStates.FindMyDetails);

  const timeout = parseInt(process.env.REACT_APP_MEMBER_DETAILS_FIND_DETAILS_ANIMATION_TIMEOUT || "0", 0);
  const canFitOnScreen = firstName && firstName.length > 13 ? "We found you!" : `We found you ${firstName}!`;
  const discount = getDiscountPercentage(membershipTier);

  const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const buttonStates = [
    {
      children: "Find my details",
      color: "primary",
      fullWidth: true,
      disabled,
      type: "submit",
      "data-testid": "submit",
    },
    {
      children: canFitOnScreen,
      color: "secondary",
      fullWidth: true,
      startIcon: <FontAwesomeIcon icon={faUserCheck} aria-hidden />,
      "data-testid": "weFoundYouButton",
    },
    {
      children: `${discount} discount applied`,
      color: "secondary",
      fullWidth: true,
      startIcon: <Icon082 fontSize="large" color="inherit" />,
      "data-testid": "discountAppliedButton",
    },
  ];

  useEffect(() => {
    const animate = async () => {
      setActiveState(ButtonStates.WeFoundYou);
      await wait(timeout);

      if (discount) {
        setActiveState(ButtonStates.DiscountApplied);
        await wait(timeout);
      }

      onButtonAnimationEnd && onButtonAnimationEnd();
    };

    if (hasMatch) {
      animate();
    }
  }, [discount, hasMatch, timeout, onButtonAnimationEnd]);

  return (
    <RacwaNotifyButton
      activeState={activeState}
      // The buttonStates forward all of their props to the underlying button
      // but are strictly typed to only allow specific props,
      // this makes it "impossible" to set things like type and testid
      buttonStates={buttonStates as React.ComponentProps<typeof RacwaNotifyButton>["buttonStates"]}
    />
  );
};

export default FindMyDetailsButton;
