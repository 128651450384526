import React from "react";
import Form from "./Form";
import useMemberDetails from "./hooks/useMemberDetails";

export const MemberDetails: React.FC<any> = () => {
  const props = useMemberDetails();
  return <Form {...props} />;
};

export default MemberDetails;
