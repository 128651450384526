import { Grid, Typography } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import HelpTextTooltip from "../../../../shared/components/HelpTextToolTip";
import YesNoButtonGroup from "../../../../shared/components/YesNoButtonGroup";
import { NO, YES } from "../../../../shared/constants";
import CaravanInvalidPurposeCard from "../CaravanInvalidPurposeCard";

const usageControlName = "isBusinessOrCommercialUsage";
const inputId = `check-${usageControlName}`;
const inputContainerId = `${inputId}-container`;
const label = "Do you use your caravan for business or commercial purposes?";

const SelectIsBusinessOrCommercialUsage: React.FC = () => {
  const { control } = useFormContext();

  const watchSelectedBusinessUse = useWatch({
    control: control,
    name: usageControlName,
  });

  const renderWarningCard = (
    <Grid item xs={12}>
      <CaravanInvalidPurposeCard />
    </Grid>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <YesNoButtonGroup
            label={label}
            aria-label={label}
            data-testid={inputContainerId}
            name={usageControlName}
            id={usageControlName}
            validate={{
              isValid: (answer: string) => answer === NO,
            }}
          />
        </Grid>
        <Grid item xs={1} style={{ paddingTop: "7px" }}>
          <HelpTextTooltip
            id="businessOrCommercialUsageTooltip"
            data-testid="businessOrCommercialUsage"
            title="Business or commercial use"
            message={
              <Typography variant="body2">
                We only cover caravans for personal use such as holidays or permanent accommodation.
                <br />
                <br />
                We don't cover caravans for any business or commercial use such as hiring out, catering, food or parcel
                delivery, carrying paying passengers or as portable office space.
              </Typography>
            }
          />
        </Grid>
        {watchSelectedBusinessUse === YES && renderWarningCard}
      </Grid>
    </>
  );
};

export default SelectIsBusinessOrCommercialUsage;
