import { Grid, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { startOfDay } from "date-fns";
import { KeyboardDateInput } from "raci-react-library";
import React from "react";
import { FormProvider } from "react-hook-form";
import { SelectPolicyStartDateMessage } from "../../shared/constants/HelperText";
import useFormotiv from "../../shared/hooks/useFormotiv";
import { FormProps } from "./types";
import { CallToActionButton } from "../../shared/components/CallToActionButon";

const dateInputName = "policyStartDate";
const dateInputLabel = "When would you like your policy to start?";

export const Form: React.FC<FormProps> = ({ form, data: { maxStartDate, ...data }, onSubmit }) => {
  const onSubmitWrapper = useFormotiv(onSubmit);

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Great, let's set a start date.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <KeyboardDateInput
              onBlur={() => {
                gtm(fieldTouched(dateInputLabel));
              }}
              onDateChange={(date) => {
                window.formotiv &&
                  window.formotiv.inputActivity(window.formotivConfig, dateInputName, "keyboarddatepicker", date);
              }}
              id={dateInputName}
              name={dateInputName}
              data-testid={dateInputName}
              label={dateInputLabel}
              minDate={startOfDay(new Date())}
              maxDate={new Date(maxStartDate)}
              clickAnywhereToShowCalendar
              errorMessage={SelectPolicyStartDateMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <CallToActionButton
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
            >
              Next
            </CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
