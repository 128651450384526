import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "@mui/material";
import { CoverType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { useStyles } from "./styles";

export interface SendQuoteButtonProps extends ButtonProps {
  coverType: CoverType;
}

export const SendQuoteButton: React.FC<SendQuoteButtonProps> = ({ coverType, ...props }) => {
  const classes = useStyles;
  const coverTypeId = coverType.toLowerCase();
  const emailQuoteButtonId = `button-${coverTypeId}-email-quote`;
  return (
    <Button
      type="button"
      id={emailQuoteButtonId}
      data-testid={emailQuoteButtonId}
      color="secondary"
      variant="contained"
      fullWidth
      startIcon={<FontAwesomeIcon icon={faEnvelopeOpenText} style={classes.icon} />}
      {...props}
    >
      Send quote
    </Button>
  );
};

export default SendQuoteButton;
