import YesNoButtonGroup from "../../../../shared/components/YesNoButtonGroup";

const inputId = "mailAddressSameAsPrimary";
const inputContainerId = `${inputId}-container`;
const label = "Is their mailing address the same as yours?";

export const SelectIsMailAddressSameAsPrimary: React.FC = () => {
  return <YesNoButtonGroup id={inputId} name={inputId} data-testid={inputContainerId} label={label} />;
};

export default SelectIsMailAddressSameAsPrimary;
