import { Controller, useFormContext } from "react-hook-form";
import RacwaNameInput from "../../../../shared/components/RacwaNameInput";
import { pleaseEnterAValidMessage } from "../../../../shared/constants/HelperText";

const accountNameField = "account name";
const AccountNameValidationPattern = /^(?=.*\S)^[a-zA-Z\s'\-—’&`]*$/;
const AccountNameSizeLimit = 40;

export const AccountName: React.FC = () => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="accountName"
      defaultValue={""}
      rules={{
        required: {
          value: true,
          message: pleaseEnterAValidMessage(accountNameField),
        },
        pattern: {
          value: AccountNameValidationPattern,
          message: pleaseEnterAValidMessage(accountNameField),
        },
      }}
      render={({ field: { ref, onChange, ...inputProps }, fieldState: { error } }) => (
        <RacwaNameInput
          {...inputProps}
          color="primary"
          id="accountName"
          data-testid="accountName"
          label="Account name"
          placeholder="e.g. John Smith"
          fullWidth
          inputProps={{
            maxLength: AccountNameSizeLimit,
          }}
          inputRef={ref}
          error={!!error?.message}
          helperText={error?.message || ""}
          onChange={(e: any) => {
            onChange && onChange(e);
          }}
        />
      )}
    />
  );
};

export default AccountName;
