import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { colors } from "@racwa/styles";

export const useStyles = {
  iconDown: {
    marginLeft: "5px",
    position: "relative",
    bottom: "1px",
  } satisfies FontAwesomeIconProps["style"],
  iconUp: {
    marginLeft: "5px",
    position: "relative",
    top: "3px",
  } satisfies FontAwesomeIconProps["style"],
  link: {
    fontSize: "0.8125rem",
    textDecoration: "underline",
    marginBottom: "4px",
  },
  text: {
    color: colors.dieselDeeper,
    fontWeight: 400,
    lineHeight: "144%",
  },
  textAlignRight: {
    textAlign: "right",
  },
  summary: {
    marginTop: "6px",
  },
};
