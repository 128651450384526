import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useGetSessionState } from "raci-react-library";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_DD_MM_YYYY, YES } from "../../../../../../shared/constants";
import { MembershipTier, Title } from "../../../../../../shared/hooks/useApiClient/ClientProxy.generated";
import {
  PolicyRoutes,
  POLICY_MEMBERSHIP_LEVEL_PAGE_URL,
  POLICY_PERSONAL_INFORMATION_PAGE_URL,
  QuoteRoutes,
  QUOTE_ABOUT_YOU_PAGE_URL,
  QUOTE_MEMBER_DETAILS_PAGE_URL,
} from "../../../../../../shared/routing/routes.config";
import { Values as AboutYouValues } from "../../../../../AboutYou/types";
import { Values as MemberDetailsValues } from "../../../../../MemberDetails/types";
import { Values as PersonalInformationValues } from "../../../../../PersonalInformation/types";
import { Values as MembershipLevelValues } from "../../../../../PolicyholderMembershipLevel/types";
import SummaryItem from "../SummaryItem";
import { useStyles } from "./styles";

const componentTestId = "policySummary-moreAboutYou";

const isGenderVisible = (title: string) => {
  return title === Title.Dr || title === Title.Mx;
};

const getDateOfBirth = (dateOfBirth?: Date | null) => {
  return dateOfBirth ? format(new Date(dateOfBirth), DATE_FORMAT_DD_MM_YYYY) : null;
};

const getMembershipLevel = (membership?: string | null) => {
  if (!membership) {
    return null;
  } else if (membership === MembershipTier.NotAMember) {
    return "No RAC membership";
  } else if (membership === MembershipTier.NotSure) {
    return "Not sure of membership level";
  } else {
    return `${membership} membership level`;
  }
};
const DateOfBirthContent: React.FC = () => {
  const aboutYouState = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const dateOfBirth = getDateOfBirth(aboutYouState.yourDateOfBirth);
  const classes = useStyles;

  return (
    <>
      {dateOfBirth && (
        <Typography variant="body2" sx={classes.dateOfBirth} data-testid={`${componentTestId}-dateOfBirth`}>
          Date of birth {dateOfBirth}
        </Typography>
      )}
    </>
  );
};

interface PersonalInformationContentProps {
  isMatchedMember: boolean;
}
const PersonalInformationContent: React.FC<PersonalInformationContentProps> = ({ isMatchedMember }) => {
  const memberDetailsState = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const personalInformationState = useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);
  const aboutYouState = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const dateOfBirth = getDateOfBirth(memberDetailsState.dateOfBirth || aboutYouState.yourDateOfBirth);

  return (
    <>
      <Typography variant="body2" data-testid={`${componentTestId}-fullName`}>
        {personalInformationState.title} {memberDetailsState.firstName || personalInformationState.firstName}{" "}
        {personalInformationState.lastName}
      </Typography>
      {isGenderVisible(personalInformationState.title ?? "") && (
        <Typography variant="body2" data-testid={`${componentTestId}-gender`}>
          {personalInformationState.gender}
        </Typography>
      )}
      {isMatchedMember && (
        <Typography
          variant="body2"
          data-testid={`${componentTestId}-dateOfBirth`}
        >{`Date of birth ${dateOfBirth}`}</Typography>
      )}
      <Typography variant="body2" data-testid={`${componentTestId}-contactNumber`}>
        {memberDetailsState.contactNumber || personalInformationState.contactNumber}
      </Typography>
      <Typography variant="body2" data-testid={`${componentTestId}-email`}>
        {memberDetailsState.email || personalInformationState.email}
      </Typography>
      {!isMatchedMember && (
        <Typography variant="body2" data-testid={`${componentTestId}-mailingAddress`}>
          {personalInformationState.mailingAddress?.displayAddress}
        </Typography>
      )}
    </>
  );
};

const MembershipLevelContent: React.FC = () => {
  const aboutYouState = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const membershipLevelState = useGetSessionState<MembershipLevelValues>(PolicyRoutes.MembershipLevel);

  return (
    <Typography variant="body2" data-testid={`${componentTestId}-membershipLevel`}>
      {getMembershipLevel(aboutYouState.membershipLevel || membershipLevelState.membershipLevel)}
    </Typography>
  );
};

export const MoreAboutYouSummaryItem: React.FC = () => {
  const classes = useStyles;
  const memberDetailsState = useGetSessionState<MemberDetailsValues>(QuoteRoutes.MemberDetails);
  const personalInformationState = useGetSessionState<PersonalInformationValues>(PolicyRoutes.PersonalInformation);
  const navigate = useNavigate();
  const redirectToMemberDetailsPage = () => {
    navigate(QUOTE_MEMBER_DETAILS_PAGE_URL);
  };
  const redirectToAboutYouPage = () => {
    navigate(QUOTE_ABOUT_YOU_PAGE_URL);
  };

  const redirectToPersonalInformationPage = () => {
    navigate(POLICY_PERSONAL_INFORMATION_PAGE_URL);
  };
  const redirectToMembershipLevelPage = () => {
    navigate(POLICY_MEMBERSHIP_LEVEL_PAGE_URL);
  };

  const isMatchedInQuoteFlow = memberDetailsState.areYouAMember === YES;
  const isMultiMatchedInQuoteFlow = isMatchedInQuoteFlow && memberDetailsState.isMultiMatch;
  const isMultiMatchedInPolicyFlow = !isMatchedInQuoteFlow && personalInformationState.captureMembershipLevel;
  const isNotMemberMatched = !isMatchedInQuoteFlow && !isMultiMatchedInQuoteFlow;

  return (
    <Grid container alignItems="center" data-testid="moreAboutYouItem">
      {isNotMemberMatched ? (
        <>
          <Grid item xs={12} data-testid="enabledPersonalInformation">
            <SummaryItem
              title="More about you"
              name={isMatchedInQuoteFlow ? "MemberDetails" : "PersonalInformation"}
              onClick={isMatchedInQuoteFlow ? redirectToMemberDetailsPage : redirectToPersonalInformationPage}
            >
              <PersonalInformationContent isMatchedMember={false} />
            </SummaryItem>
          </Grid>
          <Grid item xs={12} data-testid="dateOfBirthSection">
            <SummaryItem name="AboutYou" onClick={redirectToAboutYouPage}>
              <DateOfBirthContent />
            </SummaryItem>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sx={classes.disabled} data-testid="disabledPersonalInformationSection">
            <Typography variant="h4" sx={classes.summaryTopHeading}>
              More about you
            </Typography>
            <PersonalInformationContent isMatchedMember={true} />
          </Grid>
          <Grid item xs={12} sx={classes.address} data-testid="mailingAddressSection">
            <SummaryItem name="PersonalInformation" onClick={redirectToPersonalInformationPage}>
              <Typography variant="body2" data-testid={`${componentTestId}-mailingAddress`}>
                {personalInformationState.mailingAddress?.displayAddress}
              </Typography>
            </SummaryItem>
          </Grid>
        </>
      )}
      {(isMultiMatchedInQuoteFlow || isMultiMatchedInPolicyFlow) && (
        <Grid item xs={12} sx={classes.membershipLevel} data-testid="membershipLevelSection">
          <SummaryItem
            name={isMultiMatchedInQuoteFlow ? "AboutYou" : "MembershipLevel"}
            onClick={isMultiMatchedInQuoteFlow ? redirectToAboutYouPage : redirectToMembershipLevelPage}
          >
            <MembershipLevelContent />
          </SummaryItem>
        </Grid>
      )}
    </Grid>
  );
};

export default MoreAboutYouSummaryItem;
