import { Box } from "@mui/material";
import { RacwaCardNotification } from "@racwa/react-components";
import { InsurancePhoneNumberLink } from "raci-react-library";
import { CoverType } from "../../hooks/useApiClient/ClientProxy.generated";
import format from "../CurrencyInput/format";

export interface MarketValueOverThresholdNotificationProps {
  "data-testid"?: string | null;
  coverType: CoverType;
  threshold: number;
}

export const MarketValueOverThresholdNotification: React.FC<MarketValueOverThresholdNotificationProps> = ({
  threshold,
  coverType,
  "data-testid": dataTestId,
  ...props
}) => {

  return (
    <Box sx={ {marginTop: "19px"}}>
      <RacwaCardNotification title="We need some more information" severity="error" data-testid={dataTestId} {...props}>
        Please call us on <InsurancePhoneNumberLink id="call us link" /> to insure a {coverType.toLowerCase()} valued
        over {format(threshold)}.
      </RacwaCardNotification>
    </Box>
  );
};

export default MarketValueOverThresholdNotification;
