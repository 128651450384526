import useReferenceData from "../../shared/hooks/useReferenceData";
import RetrieveQuoteForm from "./Form";
import useRetrieveQuote from "./hooks/useRetrieveQuote";

export const RetrieveQuote: React.FC = () => {
  const props = useRetrieveQuote();
  const { suburbs } = useReferenceData();

  return <RetrieveQuoteForm {...props} suburbs={suburbs} />;
};

export default RetrieveQuote;
