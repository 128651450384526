import { fieldTouched, gtm } from "@racwa/analytics";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import format from "../../../../shared/components/CurrencyInput/format";
import ResponsiveSelect from "../../../../shared/components/ResponsiveSelect";
import { CoverType, NumberRange } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import { CoverValues } from "../../types";
import CoverFormInput from "../CoverFormInput";
import ContentCoverTooltip from "../Tooltips/ContentCoverTooltip";
import HelperText from "./HelperText";

const steps = 1000;
const name = "contents";

const getOptions = (min: number, max: number) => {
  const options: number[] = [];
  for (let i = min; i <= max; i += steps) {
    options.push(i);
  }
  return options;
};

export interface ContentsInputProps extends Omit<NumberRange, "value"> {
  coverType: CoverType;
  onChange: (value: CoverValues, forceSave?: boolean) => Promise<void>;
}

export const ContentsInput: React.FC<ContentsInputProps> = ({ coverType, min, max, onChange }) => {
  const { control, getValues } = useFormContext();
  const coverTypeId = coverType.toLowerCase();
  const labelId = `label-${coverTypeId}-${name}`;
  const inputId = `select-${coverTypeId}-${name}`;
  const [options] = useState(getOptions(min || 0, max || 0));

  return options.length > 1 ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref: inputRef, onBlur, onChange: onChangeComponent, ...props } }) => (
        <CoverFormInput
          inputId={inputId}
          labelId={labelId}
          labelXs="Contents cover"
          label="Contents cover"
          tooltip={<ContentCoverTooltip />}
          helperText={<HelperText />}
        >
          <ResponsiveSelect
            {...props}
            id={inputId}
            data-testid={inputId}
            fullWidth
            displayEmpty
            inputProps={{
              "aria-labelledby": labelId,
            }}
            options={options}
            getDisplayValue={(item: number) => format(item)}
            onChange={async (e) => {
              window.formotiv &&
                window.formotiv.inputActivity(window.formotivConfig, name, "responsiveSelect", e.target.value);
              onChangeComponent(e.target.value as number);
              const request = {
                ...getValues(),
                type: coverType,
                contents: e.target.value as number,
              };
              await onChange(request as CoverValues, true);
            }}
            onBlur={() => {
              gtm(fieldTouched("Contents Cover"));
              onBlur();
            }}
            inputRef={inputRef}
          />
        </CoverFormInput>
      )}
    />
  ) : null;
};

export default ContentsInput;
