import { RacwaCardNotification } from "@racwa/react-components";

declare type Severity = "success" | "info" | "warning" | "error";

export interface InformationCardProps {
  severity: string;
  title: string;
  informationHtml: string | JSX.Element;
}

export const InformationCard: React.FC<InformationCardProps> = ({ severity, title, informationHtml }) => {
  return (
    <RacwaCardNotification
      data-testid="informationCard"
      id="informationCard"
      title={title}
      severity={severity as Severity}
    >
      {informationHtml}
    </RacwaCardNotification>
  );
};

export default InformationCard;
