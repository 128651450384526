import { useGetSessionState, useLogger, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHandlePremiumChanges } from "../../../../shared/components/PremiumChangedDialog/useHandlePremiumChanges";
import { YES } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { MembershipTier, PolicyholderType } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import useReferenceData from "../../../../shared/hooks/useReferenceData";
import {
  PolicyRoutes,
  POLICY_JOINT_POLICYHOLDER_PAGE_URL,
  POLICY_PAYMENT_PAGE_URL,
  QuoteRoutes,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../../../../shared/routing/routes.config";
import { Values as AboutYouValues } from "../../../AboutYou/types";
import { Values as JointPolicyholderInformationValues } from "../../../JointPolicyHolderInformation/types";
import { FormProps, Values } from "../../types";

export const usePolicyholderMembershipLevel = (policyholderType: PolicyholderType): FormProps => {
  const navigate = useNavigate();
  const { membershipLevels } = useReferenceData();
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const handlePremiumChanges = useHandlePremiumChanges();
  const [state, setSessionState] = useSessionState<Values>();
  const { addAdditionalPolicyholder } = useGetSessionState<AboutYouValues>(QuoteRoutes.AboutYou);
  const hasAdditionalPolicyholder = addAdditionalPolicyholder === YES;
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();

  const [jointPolicyholderState, setJointPolicyholderState] = useSessionState<JointPolicyholderInformationValues>({
    skipPageTrackingRecalculation: true,
    specificKey: PolicyRoutes.JointPolicyholder,
  });
  const isMainPolicyholder = policyholderType === PolicyholderType.Main;
  const isJointPolicyholder = policyholderType === PolicyholderType.Additional1;
  const jointPolicyholderName = isJointPolicyholder ? jointPolicyholderState.firstName : "";
  const skipJointPolicyholderPage = () => setJointPolicyholderState({ ...jointPolicyholderState, isCompleted: true });

  const [jointMembershipLevelState, setJointMembershipLevelState] = useSessionState({
    skipPageTrackingRecalculation: true,
    specificKey: PolicyRoutes.JointMembershipLevel,
  });
  const skipJointMembershipLevelPage = () =>
    setJointMembershipLevelState({ ...jointMembershipLevelState, isCompleted: true });

  const form = useForm<Values>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: state,
  });

  const redirectToSystemUnavailable = () => {
    navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const onSubmit = async (newState: Values) => {
    try {
      setBackdrop(true);
      const { result } = await apiClient.updatemembershiptier({
        policyholderType: policyholderType,
        tier: newState.membershipLevel as MembershipTier | undefined,
      });
      setSessionState({
        ...newState,
        isCompleted: true,
      });

      if (!hasAdditionalPolicyholder) {
        skipJointPolicyholderPage();
        skipJointMembershipLevelPage();
      }

      const nextPage =
        isMainPolicyholder && hasAdditionalPolicyholder ? POLICY_JOINT_POLICYHOLDER_PAGE_URL : POLICY_PAYMENT_PAGE_URL;
      handlePremiumChanges({ discounts: result.discounts, premiumDetails: { ...result }, nextPage });
    } catch (ex) {
      logException({
        location: "usePolicyholderMembershipLevel.onSubmit",
        message: ex,
        customProperties: customLogProperties,
      });
      redirectToSystemUnavailable();
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    data: {
      membershipLevels,
      jointPolicyholderName,
    },
    onSubmit,
  };
};

export default usePolicyholderMembershipLevel;
