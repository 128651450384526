import { Grid } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

export const HelperText: React.FC = () => (
  <FormHelperText component={Grid} item xs={12}>
    $1000 of contents cover is included for free
  </FormHelperText>
);

export default HelperText;
