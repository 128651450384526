import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { happyBirthdayState } from "../../atoms";
import ImportantInformationCard from "../../shared/components/ImportantInformationCard";
import SelectMembership from "../../shared/components/SelectMembership";
import { YES } from "../../shared/constants";
import useFormotiv from "../../shared/hooks/useFormotiv";
import AdditionalPolicyholderDoB from "./components/AdditionalPolicyholderDoB";
import AddPolicyholder from "./components/AddPolicyholder";
import DutyOfDisclosureForm from "./components/DutyOfDisclosureForm";
import YourDateOfBirth from "./components/YourDateOfBirth";
import { FormProps } from "./types";
import { BirthdayConfetti, isBirthdayToday } from "raci-react-library";
import { CallToActionButton } from "../../shared/components/CallToActionButon";

export const AboutYouForm: React.FC<FormProps> = ({ form, data, onSubmit }) => {
  const isMultiMatch = data?.isMultiMatch;
  const hasMemberNotMatched = data?.hasMemberNotMatched;
  const onSubmitWrapper = useFormotiv(onSubmit);

  const renderSelectMembership = (
    <Grid item xs={12}>
      <SelectMembership options={data.membershipLevels} />
    </Grid>
  );

  const hasAnyAccident = useWatch({ control: form.control, name: "anyAccident" }) === YES;
  const hadLicenceCancelled = useWatch({ control: form.control, name: "hadLicenceCancelled" }) === YES;

  const isButtonDisabled = hasAnyAccident || hadLicenceCancelled;

  const [showBirthdayConfetti, setShowBirthdayConfetti] = useState(false);
  const hasBirthdayConfettiBeenPreviouslyDisplayed = useRecoilValue(happyBirthdayState).hasBeenPreviouslyDisplayed;

  const birthdayConfettiOnBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event?.target?.value && isBirthdayToday(event?.target.value.replaceAll(" ", "")) && !hasBirthdayConfettiBeenPreviouslyDisplayed
      ? setShowBirthdayConfetti(true)
      : setShowBirthdayConfetti(false);
  };

  return (
    <FormProvider {...form}>
      <form action="#" onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate autoComplete="on">
        {showBirthdayConfetti ? <BirthdayConfetti /> : null}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" id="header" data-testid="header">
              Now, a bit about the policyholders
            </Typography>
          </Grid>
          {isMultiMatch && renderSelectMembership}
          {hasMemberNotMatched && (
            <Grid item xs={12}>
              <YourDateOfBirth onBlur={birthdayConfettiOnBlur} />
            </Grid>
          )}

          <Grid item xs={12}>
            <AddPolicyholder />
          </Grid>

          {form.watch("addAdditionalPolicyholder") === YES && (
            <Grid item xs={12}>
              <AdditionalPolicyholderDoB />
            </Grid>
          )}

          <Grid item xs={12} sx={{margin: "15px 0 15px 0"}}>
            <ImportantInformationCard />
          </Grid>
          <DutyOfDisclosureForm />
          <Grid item xs={12}>
            <CallToActionButton
              type="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={isButtonDisabled}
            >
              View quote
            </CallToActionButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AboutYouForm;
