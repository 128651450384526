import format from "../../../../shared/components/CurrencyInput/format";
import { CoverType, NumberOptions } from "../../../../shared/hooks/useApiClient/ClientProxy.generated";
import DiscountItem from "../DiscountItem";

export interface ExcessDiscountItemProps extends NumberOptions {
  coverType: CoverType;
}

export const ExcessDiscountItem: React.FC<ExcessDiscountItemProps> = ({ coverType, value = 0, options }) =>
  !options || options.length < 2 ? (
    <DiscountItem id={`${coverType.toLowerCase()}-excess`} text={`${format(value)} Excess for over 50's`} />
  ) : null;

export default ExcessDiscountItem;
