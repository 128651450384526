import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Grid, GridProps, Typography } from "@mui/material";
import { FinePrint } from "@racwa/react-components";
import React from "react";
import ProductDisclosureStatement from "../ProductDisclosureStatement";
import useStyles from "./styles";

export const CheckMarkIcon = (
  <Grid container item xs={1}>
    <FontAwesomeIcon icon={faCheck} />
  </Grid>
);

export const gridProps: GridProps = {
  container: true,
  justifyContent: "space-between",
  alignItems: "baseline",
};

export interface WhatIsIncludedProps {
  displayAsTooltip?: boolean | false;
}

export const WhatsIsIncludedContent: React.FC<WhatIsIncludedProps> = ({ displayAsTooltip }) => {
  const classes = useStyles;

  return (
    <CardContent sx={displayAsTooltip ? classes.tooltipCard : classes.default}>
      <Grid spacing={2} {...gridProps}>
        <Grid item xs={12}>
          <Typography variant="h4">What's included?</Typography>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>Cover for accidental damage, malicious damage, fire, theft, storm and flood.</FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>Interstate and off-road cover.</FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>
            Cover for personal items (including sports equipment and electronic devices) when inside or attached to your
            caravan or annexe.
          </FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>Local claims team, available seven days a week.</FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>Cover for anyone towing your caravan.</FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>Towing your caravan to an approved repairer or safe location if it isn't safe to use.</FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>Cover up to $3000 for your annexe.</FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint>
            If your caravan is written off and it's less than two years old, and you're the first registered owner,
            we'll replace it with a new caravan.
          </FinePrint>
        </Grid>
        {CheckMarkIcon}
        <Grid item xs={11}>
          <FinePrint variant="body2">And much more!</FinePrint>
        </Grid>
        <Grid item xs={12}>
          <ProductDisclosureStatement preamble="See" />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const WhatIsIncluded: React.FC<WhatIsIncludedProps> = ({ displayAsTooltip }) => {
  return (
    <Card variant="outlined">
      <WhatsIsIncludedContent displayAsTooltip={displayAsTooltip} />
    </Card>
  );
};

export default WhatIsIncluded;
