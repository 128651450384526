import { Typography } from "@mui/material";
import HelpTextTooltip from "../../../../shared/components/HelpTextToolTip";

export const ContentCoverTooltip = () => {
  return (
    <HelpTextTooltip
      id="chooseContentCoverTooltip"
      data-testid="chooseContentCoverTooltip"
      title="Contents cover"
      message={
        <Typography variant="body2">
          We provide $1000 of complimentary contents cover against loss or damage caused by fire, storm, collision,
          theft or malicious damage.
          <br />
          <br />
          Contents includes clothing, personal belongings, furniture, sports equipment and electrical devices when
          inside or attached to your caravan or annexe.
          <br />
          <br />
          For an additional premium, you can increase your cover up to $15,000.
        </Typography>
      }
    />
  );
};

export default ContentCoverTooltip;
